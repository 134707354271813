import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0c3c13de = () => interopDefault(import('../pages/app/index.vue' /* webpackChunkName: "pages/app/index" */))
const _86379a74 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _269b238c = () => interopDefault(import('../pages/clearance/index.vue' /* webpackChunkName: "pages/clearance/index" */))
const _9856de94 = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "pages/contacts" */))
const _f39cc5cc = () => interopDefault(import('../pages/delivery/index.vue' /* webpackChunkName: "pages/delivery/index" */))
const _70d6dfb0 = () => interopDefault(import('../pages/legal-info/index.vue' /* webpackChunkName: "pages/legal-info/index" */))
const _4b7c2216 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7119292d = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _808f6b76 = () => interopDefault(import('../pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _85e9028e = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _5d110e82 = () => interopDefault(import('../pages/pizza-construct.vue' /* webpackChunkName: "pages/pizza-construct" */))
const _7c1653ca = () => interopDefault(import('../pages/postavshikam.vue' /* webpackChunkName: "pages/postavshikam" */))
const _6a8badce = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _26bac23f = () => interopDefault(import('../pages/promo/index.vue' /* webpackChunkName: "pages/promo/index" */))
const _866285ac = () => interopDefault(import('../pages/promos/index.vue' /* webpackChunkName: "pages/promos/index" */))
const _6974d23e = () => interopDefault(import('../pages/review/index.vue' /* webpackChunkName: "pages/review/index" */))
const _4b9ddcaa = () => interopDefault(import('../pages/thankyoupage/index.vue' /* webpackChunkName: "pages/thankyoupage/index" */))
const _96547af4 = () => interopDefault(import('../pages/vacancies.vue' /* webpackChunkName: "pages/vacancies" */))
const _4fac2e19 = () => interopDefault(import('../pages/app/download.vue' /* webpackChunkName: "pages/app/download" */))
const _1f7a72ba = () => interopDefault(import('../pages/clearance/login.vue' /* webpackChunkName: "pages/clearance/login" */))
const _791c9eb8 = () => interopDefault(import('../pages/delivery/map.vue' /* webpackChunkName: "pages/delivery/map" */))
const _66fcd128 = () => interopDefault(import('../pages/delivery/security.vue' /* webpackChunkName: "pages/delivery/security" */))
const _b136053c = () => interopDefault(import('../pages/legal-info/account-deleting.vue' /* webpackChunkName: "pages/legal-info/account-deleting" */))
const _48924e60 = () => interopDefault(import('../pages/legal-info/agreement.vue' /* webpackChunkName: "pages/legal-info/agreement" */))
const _55ff6a4b = () => interopDefault(import('../pages/legal-info/oferta.vue' /* webpackChunkName: "pages/legal-info/oferta" */))
const _9cbbff42 = () => interopDefault(import('../pages/legal-info/personal-politic.vue' /* webpackChunkName: "pages/legal-info/personal-politic" */))
const _82293ade = () => interopDefault(import('../pages/legal-info/privacy-policy-courier.vue' /* webpackChunkName: "pages/legal-info/privacy-policy-courier" */))
const _04e816f9 = () => interopDefault(import('../pages/legal-info/promotions-description.vue' /* webpackChunkName: "pages/legal-info/promotions-description" */))
const _46e0c957 = () => interopDefault(import('../pages/legal-info/terms-of-payment-and-delivery.vue' /* webpackChunkName: "pages/legal-info/terms-of-payment-and-delivery" */))
const _6f6a8cb9 = () => interopDefault(import('../pages/review/thankyou.vue' /* webpackChunkName: "pages/review/thankyou" */))
const _fc570202 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _cf597b74 = () => interopDefault(import('../pages/delivery/points/_slug.vue' /* webpackChunkName: "pages/delivery/points/_slug" */))
const _21741a1e = () => interopDefault(import('../pages/delivery/_region/index.vue' /* webpackChunkName: "pages/delivery/_region/index" */))
const _49a814c0 = () => interopDefault(import('../pages/menu/_name/index.vue' /* webpackChunkName: "pages/menu/_name/index" */))
const _08abdb61 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _7e424867 = () => interopDefault(import('../pages/promo/_id.vue' /* webpackChunkName: "pages/promo/_id" */))
const _2c5e0212 = () => interopDefault(import('../pages/promos/_id.vue' /* webpackChunkName: "pages/promos/_id" */))
const _3b6f0192 = () => interopDefault(import('../pages/thankyoupage/_id.vue' /* webpackChunkName: "pages/thankyoupage/_id" */))
const _4d322829 = () => interopDefault(import('../pages/delivery/_region/_category.vue' /* webpackChunkName: "pages/delivery/_region/_category" */))
const _445350f8 = () => interopDefault(import('../pages/menu/_name/_single/index.vue' /* webpackChunkName: "pages/menu/_name/_single/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app",
    component: _0c3c13de,
    pathToRegexpOptions: {"strict":true},
    name: "app"
  }, {
    path: "/cart",
    component: _86379a74,
    pathToRegexpOptions: {"strict":true},
    name: "cart"
  }, {
    path: "/clearance",
    component: _269b238c,
    pathToRegexpOptions: {"strict":true},
    name: "clearance"
  }, {
    path: "/contacts",
    component: _9856de94,
    pathToRegexpOptions: {"strict":true},
    name: "contacts"
  }, {
    path: "/delivery",
    component: _f39cc5cc,
    pathToRegexpOptions: {"strict":true},
    name: "delivery"
  }, {
    path: "/legal-info",
    component: _70d6dfb0,
    pathToRegexpOptions: {"strict":true},
    name: "legal-info"
  }, {
    path: "/login",
    component: _4b7c2216,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/logout",
    component: _7119292d,
    pathToRegexpOptions: {"strict":true},
    name: "logout"
  }, {
    path: "/menu",
    component: _808f6b76,
    pathToRegexpOptions: {"strict":true},
    name: "menu"
  }, {
    path: "/news",
    component: _85e9028e,
    pathToRegexpOptions: {"strict":true},
    name: "news"
  }, {
    path: "/pizza-construct",
    component: _5d110e82,
    pathToRegexpOptions: {"strict":true},
    name: "pizza-construct"
  }, {
    path: "/postavshikam",
    component: _7c1653ca,
    pathToRegexpOptions: {"strict":true},
    name: "postavshikam"
  }, {
    path: "/profile",
    component: _6a8badce,
    pathToRegexpOptions: {"strict":true},
    name: "profile"
  }, {
    path: "/promo",
    component: _26bac23f,
    pathToRegexpOptions: {"strict":true},
    name: "promo"
  }, {
    path: "/promos",
    component: _866285ac,
    pathToRegexpOptions: {"strict":true},
    name: "promos"
  }, {
    path: "/review",
    component: _6974d23e,
    pathToRegexpOptions: {"strict":true},
    name: "review"
  }, {
    path: "/thankyoupage",
    component: _4b9ddcaa,
    pathToRegexpOptions: {"strict":true},
    name: "thankyoupage"
  }, {
    path: "/vacancies",
    component: _96547af4,
    pathToRegexpOptions: {"strict":true},
    name: "vacancies"
  }, {
    path: "/app/download",
    component: _4fac2e19,
    pathToRegexpOptions: {"strict":true},
    name: "app-download"
  }, {
    path: "/clearance/login",
    component: _1f7a72ba,
    pathToRegexpOptions: {"strict":true},
    name: "clearance-login"
  }, {
    path: "/delivery/map",
    component: _791c9eb8,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-map"
  }, {
    path: "/delivery/security",
    component: _66fcd128,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-security"
  }, {
    path: "/legal-info/account-deleting",
    component: _b136053c,
    pathToRegexpOptions: {"strict":true},
    name: "legal-info-account-deleting"
  }, {
    path: "/legal-info/agreement",
    component: _48924e60,
    pathToRegexpOptions: {"strict":true},
    name: "legal-info-agreement"
  }, {
    path: "/legal-info/oferta",
    component: _55ff6a4b,
    pathToRegexpOptions: {"strict":true},
    name: "legal-info-oferta"
  }, {
    path: "/legal-info/personal-politic",
    component: _9cbbff42,
    pathToRegexpOptions: {"strict":true},
    name: "legal-info-personal-politic"
  }, {
    path: "/legal-info/privacy-policy-courier",
    component: _82293ade,
    pathToRegexpOptions: {"strict":true},
    name: "legal-info-privacy-policy-courier"
  }, {
    path: "/legal-info/promotions-description",
    component: _04e816f9,
    pathToRegexpOptions: {"strict":true},
    name: "legal-info-promotions-description"
  }, {
    path: "/legal-info/terms-of-payment-and-delivery",
    component: _46e0c957,
    pathToRegexpOptions: {"strict":true},
    name: "legal-info-terms-of-payment-and-delivery"
  }, {
    path: "/review/thankyou",
    component: _6f6a8cb9,
    pathToRegexpOptions: {"strict":true},
    name: "review-thankyou"
  }, {
    path: "/",
    component: _fc570202,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/delivery/points/:slug?",
    component: _cf597b74,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-points-slug"
  }, {
    path: "/delivery/:region",
    component: _21741a1e,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-region"
  }, {
    path: "/menu/:name",
    component: _49a814c0,
    pathToRegexpOptions: {"strict":true},
    name: "menu-name"
  }, {
    path: "/news/:id",
    component: _08abdb61,
    pathToRegexpOptions: {"strict":true},
    name: "news-id"
  }, {
    path: "/promo/:id",
    component: _7e424867,
    pathToRegexpOptions: {"strict":true},
    name: "promo-id"
  }, {
    path: "/promos/:id",
    component: _2c5e0212,
    pathToRegexpOptions: {"strict":true},
    name: "promos-id"
  }, {
    path: "/thankyoupage/:id",
    component: _3b6f0192,
    pathToRegexpOptions: {"strict":true},
    name: "thankyoupage-id"
  }, {
    path: "/delivery/:region/:category",
    component: _4d322829,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-region-category"
  }, {
    path: "/menu/:name/:single",
    component: _445350f8,
    pathToRegexpOptions: {"strict":true},
    name: "menu-name-single"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

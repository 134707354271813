<template>
  <div class="header_collapse_menu">
    <div
      v-for="(item, i) in menu"
      :key="`collapsem-${ i }`"
      @click="goTo(item.path)"
      class="header_collapse_menu_item"
    >
      <div
        :class="item.id"
        :style="{backgroundImage: `url(${item.icon})`}"
        class="header_collapse_menu_pic"
      />
      <p>{{ item.name }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      // menu: state => state.config.topMenuCategories,
    }),
    ...mapGetters({
      menu: 'menu/getTopMenu',
    }),
  },
  methods: {
    goTo (path) {
      this.$emit('close')
      this.$router.push(path)
    },
  },
}
</script>

<style lang="less">
.header_collapse_menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  padding-bottom: 15px;

  &_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    width: 50%;

    p {
      font-family: 'Aqum', Arial, sans-serif;
      font-weight: bold;
      font-size: 14px;
      color: black;
      text-align: center;
    }
  }

  &_pic {
    width: 100px;
    height: 100px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>

import queryString from 'query-string'
import { isEmpty } from 'lodash'
import { cookie } from '~/utils/cookie'

const COOKIE_NAME = 'utm_source'
const DAYS_COOKIE_SAVE = 90

export function getUtmSource () {
  const parsedParams = queryString.parse(window.location.search)

  if (!isEmpty(parsedParams) && parsedParams.utm_source) {
    const { utm_source } = parsedParams
    cookie.set(COOKIE_NAME, utm_source, DAYS_COOKIE_SAVE)
    return utm_source
  } else {
    const utm_source = cookie.get(COOKIE_NAME)
    if (utm_source) {
      return utm_source
    }
  }

  return null
}

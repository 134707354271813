<template>
  <div class="house-input-component">
    <p v-if="false">
      v-model mode:
      <button
        :class="{ selected: mode === 'input', 'v-model-event': true }"
        @click="mode = 'input'"
      >
        input
      </button>

      <button
        :class="{ selected: mode === 'select', 'v-model-event': true }"
        @click="mode = 'select'"
      >
        select
      </button>
    </p>

    <vue-suggest
      :destyled="true"
      v-model="model"
      :list="getList"
      :max-suggestions="30"
      :min-length="1"
      :debounce="200"
      :filter-by-query="false"
      :controls="{
        selectionUp: [38, 33],
        selectionDown: [40, 34],
        select: [13, 36],
        showList: [40],
        hideList: [27, 35]
      }"
      class="asdad"
      :mode="mode"
      pattern="\w+"
      :nullable-select="true"
      ref="suggestComponent"
      @select="onSuggestSelect"
      @input="onInput"
      @blur="onBlur"
      placeholder="Search information..."
      value-attribute="value"
      display-attribute="value"
    >
      <!-- <input type="text"> -->

      <div class="query">
        <input v-bind="$attrs" :placeholder="placeholder" :disabled="!streetId" type="text" class="input-text_input">
      </div>

      <!-- <test-input placeholder="Search information..." /> -->

      <template slot="misc-item-above" slot-scope="{ suggestions, query }">
        <div v-if="suggestions.length === 0 && !loading" class="misc-item">
          <span>Ничего не найдено..."</span>
        </div>
      </template>

      <div slot="suggestion-item" slot-scope="scope">
        <div class="text">
          <span v-html="boldenSuggestion(scope)" />
        </div>
      </div>

      <div slot="misc-item-below" slot-scope="{ suggestions }" v-if="loading" class="misc-item">
        <span>Поиск...</span>
      </div>
    </vue-suggest>
  </div>
</template>

<script>
import VueSuggest from 'vue-simple-suggest'

export default {
  components: {
    VueSuggest,
  },
  /* model: {
    prop: 'val',
    event: 'input'
  }, */
  props: {
    streetId: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    val: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },

  },
  data () {
    return {
      selected: null,
      model: null,
      mode: 'input',
      loading: false,
      log: [],
    }
  },
  methods: {
    boldenSuggestion (scope) {
      if (!scope) {
        return scope
      }
      const { suggestion, query } = scope
      const result = this.$refs.suggestComponent.displayProperty(suggestion)
      if (!query) {
        return result
      }
      const texts = query.split(/[\s-_/\\|\.]/gm).filter(t => !!t) || ['']
      return suggestion.value.replace(new RegExp('(.*?)(' + texts.join('|') + ')(.*?)', 'gi'), '$1<b>$2</b>$3')
    },

    getList (inputValue) {
      this.loading = true
      const payload = {
        query: inputValue,
        streetId: this.streetId,
      }
      return this.$store.dispatch('common/getSuggestHouse', payload)
	      .catch(err => console.error('house input - common/getSuggestHouse - error', err))
        .finally(() => {
          this.loading = false
        })
    },
    onSuggestSelect (suggest) {
      this.$emit('selected', suggest)
      this.selected = suggest
    },
    onInput () {
      this.$emit('input', this.model)
    },
    onBlur () {
      this.$emit('blur')
    },
  },
}
</script>

<style lang="less">
.house-input-component {

  b {
	font-weight: bold;
  }

  .vue-simple-suggest {
	&.focus .input-wrapper input {
	  border: 1px solid #e1e1e1;

	}

	.suggestions {
	  position: absolute;
	  left: 0;
	  right: 0;
	  top: 100%;
	  //top: calc(100% + 5px);
	  border: 1px solid #e1e1e1;
	  background-color: #fff;
	  opacity: 1;
	  z-index: 1000;
	  max-height: 220px;
	  overflow-y: auto;
	  box-shadow: 0 3px 14px rgb(128 128 128 / 16%);
	  border-radius: 15px;
	  font-size: 14px;
	}

	.suggestions .suggest-item {
	  cursor: pointer;
	  user-select: none;
	  font-size: 14px;
	  padding-top: 10px;
	  padding-bottom: 10px;
	  border-top: 1px solid #f2f2f2;

	  &:nth-child(2n) {
		//background: #939393;
	  }

	  &:not(:last-child) {
		//border-bottom: 1px solid #f2f2f2;
	  }

	  .area {
		font-weight: bold;
		font-size: 12px;
		margin-bottom: 4px;
	  }
	}

	.suggestions .suggest-item,
	.suggestions .misc-item {
	  padding: 10px 10px;
	}

	.suggestions .suggest-item.hover {
	  background-color: #f2f2f2 !important;
	  // color: #fff !important;
	}

	.suggestions .suggest-item.selected {
	  //background-color: #2832D5;
	  //background-color: #939393;
	  //color: #fff;
	}
  }

}

</style>

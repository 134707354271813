import axios from 'axios'

const state = () => ({
  regionNotFound: false,
  all: [],
  current: null,
  filialDetectError: false,
  regionSelectOpen: false,
})

const getters = {
  isRegionNotFoundError: state => state.regionNotFound,
  getAllRegions: state => state.all,
  getCurRegion: state => state.current,
  getCurRegionName: state => state.current ? state.current.name : 'Выберите город...',
  getCurRegionNamePadeg: state => state.current ? state.current.namePadeg : '...',

  getRegionField: state => (key, defaultValue = null) => {
    return state.current && state.current[key] !== undefined ? state.current[key] : defaultValue
  },

}

const mutations = {
  setRegions (state, data) {
    state.all = data
  },

  setCurrentRegion (state, region) {
    state.current = region
  },

  setReginNotFoundError (state) {
    state.regionNotFound = true
  },

  setRegionSelectOpen (state, value) {
    state.regionSelectOpen = value
  },
}

const actions = {
  fetchAll ({ commit, rootGetters }) {
    commit('setRegions', [])
    return axios.get(rootGetters['api/getRegionsList'])
      .then((res) => {
        commit('setRegions', res.data.items)
        return res.data.items
      })
  },

  fetchRegionInfo ({ commit, rootGetters }, { key = null, isMain = false }) {
    return this.$axios
      .get(rootGetters['api/getRegionItem'], {
        params: {
          key,
          isMain: isMain ? 1 : 0,
        },
      })
      .then((res) => {
        commit('setCurrentRegion', res.data.data)
        return res.data.data
      })
      /* .catch((e) => {
        console.log(e.error)
        return e
      }) */
  },

  fetchRegionById ({ commit, rootGetters }, id) {
    return this.$axios
      .get(rootGetters['api/getRegionById'](id))
      .then((res) => {
        return res.data.data
      })
  },

  showRegionSelect ({ commit }) {
    if (!this.$region.isMultiRegionMode()) {
      return false
    }

    commit('setRegionSelectOpen', true)
  },

  hideRegionSelect ({ commit }) {
    commit('setRegionSelectOpen', false)
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

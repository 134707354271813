<template>
  <div class="header_collapse">
    <div class="header_collapse_inner">
      <div class="header_collapse_body">
        <mobile-region-label />
        <HeaderCollapseMenu @close="close" />
        <div class="header_collapse_login_section d-flex justify-content-between mb-3">
          <client-only>
            <button @click="goToProfileOrLogin">
              <div class="icon">
                <font-awesome-icon :icon="['fas', 'user-alt']" />
              </div>
              <span v-if="isLoggedIn">{{ getUserName }}</span>
              <span v-else>Вход в кабинет</span>
            </button>
            <button v-if="isLoggedIn" @click="logOut">
              <div class="icon">
                <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
              </div>
              <span>Выход</span>
            </button>
          </client-only>
        </div>
        <HeaderCollapseWorkTime />
        <HeaderCollapsePhone />
        <HeaderCollapseCards />
        <HeaderCollapseNavigation />
        <HeaderCollapseSocial />
      </div>
      <div @click="close" class="header_collapse_close" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderCollapseMenu from './HeaderCollapseMenu'
import HeaderCollapsePhone from './HeaderCollapsePhone'
import HeaderCollapseCards from './HeaderCollapseCards'
import HeaderCollapseNavigation from './HeaderCollapseNavigation'
import HeaderCollapseSocial from './HeaderCollapseSocial'
import HeaderTopLogin from './HeaderTopLogin'
import HeaderCollapseWorkTime from './HeaderCollapseWorkTime'
import MobileRegionLabel from '~/components/layout/Header/MobileRegionLabel'

export default {
  components: {
    MobileRegionLabel,
    HeaderTopLogin,
    HeaderCollapseMenu,
    HeaderCollapsePhone,
    HeaderCollapseCards,
    HeaderCollapseNavigation,
    HeaderCollapseSocial,
    HeaderCollapseWorkTime,
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
    }),
    getUserName () {
      if (this.getUser) {
        return this.getUser.name || 'Гость'
      }

      return 'Мой профиль'
    },
    isLoggedIn () {
      return !!this.$store.getters['auth/loggedIn']
    },
  },
  methods: {
    logOut () {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.push('/')
        })
        .catch((err) => {
        })
    },
    goToProfileOrLogin () {
      this.close()
      if (this.$store.getters['auth/loggedIn']) {
        this.$router.push('/profile')
      } else {
        this.$router.push('/login')
      }
    },
    close () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="less">
.header_collapse {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;

  &_login_section {
    padding: 0 15%;
    margin-bottom: 35px !important;
    display: flex !important;;
    justify-content: center !important;

    button {
      background-color: transparent;
      display: flex;
      align-items: center;
      border: none;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }

      span {
        display: block;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        border-bottom: 1px dashed #000;
      }
    }

    .icon {
      height: 32px;
      width: 32px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-size: 10px;
      color: @color-primary;
      border-radius: 50%;
      margin-right: 10px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  &_inner {
    height: 100%;
    display: flex;
    align-items: stretch;
  }

  &_body {
    height: 100%;
    display: flex;
    flex-shrink: 0;
    flex-basis: calc(100% - 62px);
    flex-direction: column;
    align-items: stretch;
    background-color: rgba(255, 255, 255, .98);
    padding-top: 10px;
    padding-bottom: 50px;
    overflow-y: scroll;
  }

  &_close {
    height: 100%;
    flex-grow: 1;
    background-color: transparent;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    &_body {
      & > * {
        flex-shrink: 0;
      }
    }
  }
}

</style>

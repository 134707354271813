<template>
  <div class="delivery-option">
    <div v-if="(!deliveryAddresses.length && !newAdress.length) || showDeliveryForm" class="delivery-option_new">
      <DeliveryForm
        :on-close="handleCloseDeliveryForm"
        :showBack="!!deliveryAddresses.length || !!newAdress.length"
        :set-temp-coords="setTempCoords"
        :add-new-address="addNewAddress"
      />
    </div>
    <div v-else class="delivery-option_list">
      <DeliveryItem
        v-for="(destination, i) of [...newAdress, ...deliveryAddresses]"
        :key="destination.id"
        :destination="destination"
        :isActive="getActiveAddress === i"
        :index="i"
        @changeAddressIndex="changeAddressIndex"
        :setTempCoords="setTempCoords"
      />
      <div class="delivery-option_list_footer">
        <Button
          @click="handleOpenDeliveryForm"
          class="delivery-option_list_footer_btn add"
        >
          Добавить новый адрес
        </Button>
        <Button
          :disabled="getActiveAddress < 0"
          @click="changeAddress(getActiveAddress, 'delivery')"
          class="delivery-option_list_footer_btn select"
        >
          Выбрать
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryItem from '~/components/layout/OrderTypeSelect/DeliveryItem.vue'
import Button from '~/components/UI/Button.vue'
import DeliveryForm from '~/components/layout/OrderTypeSelect/DeliveryForm.vue'

export default {
  components: { DeliveryForm, Button, DeliveryItem },
  props: ['deliveryAddresses', 'changeAddress', 'addressIndex', 'changeAddressIndex', 'setTempCoords', 'addNewAddress', 'newAdress'],
  data () {
    return {
      showDeliveryForm: false,
    }
  },
  computed: {
    getActiveAddress () {
      return this.addressIndex[1] === 'delivery' ? this.addressIndex[0] : -1
    },
  },
  methods: {
    handleOpenDeliveryForm () {
      this.showDeliveryForm = true
      this.setTempCoords([])
    },
    handleCloseDeliveryForm () {
      this.showDeliveryForm = false
      this.setTempCoords(null)
    },
  },
}
</script>

<style lang="less" scoped>
.delivery-option {
  margin-right: 8px;
  margin-top: 15px;
  padding-top: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;

  /* width */

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* button */

  &::-webkit-scrollbar-button {
    display: none;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #dfdfdf;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &_new {
    height: 100%;
  }

  &_list {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_footer {
      margin-top: auto;
      height: 110px;
      background-image: linear-gradient(
        169deg,
        rgba(255, 255, 255, 0) -14.9%,
        #fff 34.58%
      );
      position: sticky;
      bottom: -1px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: 11px;

      &_btn {
        width: 295px;
        height: 46px;
        border-radius: 14px;

        &.add {
          border: 1px solid #e31e24;
          background: #fff;
          color: #e31e24;
        }
      }
    }
  }
}
</style>

<template>
  <div
    :class="{header_hidden: hidden}"
    class="header_top"
  >
    <div class="container">
      <div class="row header_top_row">
        <div class="col-8">
          <HeaderTopNavigation />
        </div>
        <div class="col-4 d-flex align-items-center justify-content-end">
          <div class="header_top_region_select">
            <region-label />
            <confirm-region-popup />
          </div>

          <HeaderTopSocials />
          <HeaderTopLogin />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTopNavigation from './HeaderTopNavigation'
import HeaderTopSocials from './HeaderTopSocials'
import HeaderTopLogin from './HeaderTopLogin'
import RegionLabel from '~/components/page/RegionLabel'
import ConfirmRegionPopup from '~/components/regions/ConfirmRegionPopup'

export default {
  components: { ConfirmRegionPopup, RegionLabel, HeaderTopNavigation, HeaderTopSocials, HeaderTopLogin },
  props: {
    hidden: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style lang="less">
.header_top {
  background-color: #F3F3F3;
  background-image: url('../../../assets/images/white_wave.png');
  background-size: auto 12px;
  background-repeat: repeat-x;
  background-position: bottom left;
  transition: height 0.3s ease;
  height: 31px;

  &.header_hidden {
    height: 0;
    overflow: hidden;
  }

  &_region_select {
    margin-right: 30px;
    position: relative;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  &_row {
    height: 31px;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
  }
}
</style>

<template>
  <div class="header_main">
    <div class="container">
      <div class="row">
        <div class="col d-flex align-items-center justify-content-between position-relative">
          <HeaderMainLogo />
          <HeaderMainSearch />
          <HeaderMainMode />
          <HeaderMainCart />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMainLogo from './HeaderMainLogo'
import HeaderMainSearch from './HeaderMainSearch'
import HeaderMainMode from './HeaderMainMode'
import HeaderMainCart from './HeaderMainCart'

export default {
  components: { HeaderMainLogo, HeaderMainSearch, HeaderMainMode, HeaderMainCart },
}
</script>

<style lang="less">
.header_main {
	padding: 14px 0;
	margin-bottom: 10px;

	@media screen and (max-width: 768px) { display: none; }
}
</style>

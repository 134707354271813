<template>
  <div class="container header_clearance_main">
    <div class="row">
      <div class="col-12 col-lg-3 header_clearance_main_logo">
        <HeaderMainLogo class="d-none d-md-flex" />
      </div>
      <div class="col-12 col-lg-9">
        <div class="header_clearance_main_stepper">
          <Stepper :items="getSteps" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Stepper from '@/components/UI/Stepper'
import HeaderMainLogo from './HeaderMainLogo'

export default {
  components: { HeaderMainLogo, Stepper },
  computed: {
    getSteps () {
      return this.$store.getters.getClearanceSteps
    },
  },
}
</script>

<style lang="less">
.header_clearance_main {
	& > .row {
		align-items: center;
		padding: 50px 0;

		@media screen and (max-width: 992px) { padding: 32px 0 14px; }
		@media screen and (max-width: 768px) { padding: 35px 0; }
	}

	&_logo {
		@media screen and (max-width: 992px) {
			display: flex;
			justify-content: center;
			margin-bottom: 25px;
		}

		@media screen and (max-width: 768px) { margin-bottom: 0; }
	}

	&_stepper {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		@media screen and (max-width: 992px) { justify-content: center; }
	}
}
</style>

import axios from 'axios'

const state = () => ({
  profile: null,
  referralPromoCode: '',
})

const getters = {
  getProfile (state) {
    return state.profile
  },
}

const mutations = {
  setProfile (state, payload) {
    state.profile = payload
  },
}

const actions = {
  fetchProfile ({ commit, rootGetters }) {
    return axios.get(rootGetters['api/clientGetProfile']).then((res) => {
      commit('setProfile', res.data.data)
      return res.data
    }).catch(err => err)
  },
  updateProfile ({ commit, rootGetters }, data) {
    return axios.patch(rootGetters['api/clientPatchProfile'], data).then((res) => {
      commit('setProfile', res.data.data)
      return res.data
    }).catch(err => err)
  },
  addPromoCode ({ rootGetters }, code) {
    return new Promise((resolve, reject) => {
      axios.post(rootGetters['api/clientPostPromoCode'], { promoCode: code }).then((res) => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  sendAppLink ({ rootGetters }, payload = {}) {
    return this.$axios
      .post(rootGetters['api/clientSendAppLink'], payload)
      .catch((err) => {
        this.$toast.error(err?.response?.data?.message, { duration: 3000 })
        return Promise.reject(err)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

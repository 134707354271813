<template>
  <div>
    <transition name="fade">
      <PageLoader v-if="showLoader" />
    </transition>
    <template v-if="showEmptyLayout">
      <nuxt class="empty-frame-page" />
    </template>
    <template v-else>
      <Header />
      <nuxt />
      <Footer />
      <ModalRestrictOrders
        :open="showTemporaryModal"
        @close="showTemporaryModal = false"
      />

      <!--      <new-year-modal/>-->
      <ModalWorkingHours />
      <OrderTypeSelect />
      <NotAvailable />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageLoader from '~/components/UI/PageLoader'
import Header from '~/components/layout/Header/Header'
import Footer from '~/components/layout/Footer/Footer'
import ModalRestrictOrders from '~/components/page/Modal/ModalRestrictOrders'
import ModalWorkingHours from '~/components/page/Modal/ModalWorkingHours'
import NewYearModal from '~/components/page/Modal/InfoModals/NewYearModal'
import OrderTypeSelect from '~/components/layout/OrderTypeSelect/OrderTypeSelect.vue'
import NotAvailable from '~/components/layout/OrderTypeSelect/NotAvailable.vue'

export default {
  components: { NotAvailable, NewYearModal, PageLoader, Header, Footer, ModalRestrictOrders, ModalWorkingHours, OrderTypeSelect },
  data () {
    return {
      showLoader: true,
      showTemporaryModal: true,
    }
  },
  head () {
    return {
      title: this.$store.getters['seo/getMetaTitle'],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$store.getters['seo/getMetaDescription'],
        },
      ],
    }
  },
  computed: {
    showEmptyLayout () {
      try {
        if (this.$route.query.frame) {
          return true
        }
      } catch (e) {
        return false
      }
      return false
    },
  },
  mounted () {
    setTimeout(() => {
      this.showLoader = false
    }, 500)

    this.$store.dispatch('favorites/fetchList')
  },
}
</script>

import _ from 'lodash'
import RegionType from '../models/enums/RegionType'

const state = () => ({
  allRegions: [],
  /* [
    {
      id: 1,
      name: 'Выборгский район',
      slug: 'vyborgskiy-rayon',
      type: RegionType.District,

      metaTitle: 'Доставка еды в Выборгском районе — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Выборгском районе. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Выборгском районе',
      textTop: '<p>СУШИФОКС &ndash; это быстрая доставка еды по Выборгскому району Санкт-Петербурга (метро &laquo;Проспект Просвещения&raquo;, &laquo;Парнас&raquo;, &laquo;Озерки&raquo;, &laquo;Удельная&raquo;). Посмотреть минимальную сумму заказа и время ожидания для вашего адреса вы можете на карте. Каждое блюдо готовится только и исключительно для вас. Собственные фирменные рецепты, высокая скорость доставки, незабываемый вкус сочных роллов и горячей пиццы &ndash; все это СУШИФОКС!</p>',
      textBottom: '<h2>Доставка вкусной еды в Выборгском районе</h2><p>Представьте себе: вечер после рабочего дня или утро выходных. Не хочется ни лишний раз вставать, ни готовить, ни мыть потом посуду. А если еще и придется идти за продуктами в магазин&hellip; Но всегда есть выход &ndash; доставка еды на дом!</p><p>Мы доставляем еду (суши, роллы, пиццу, супы, салаты и десерты) по всему Выборгскому району Санкт-Петербурга. Заходите в пункт самовывоза на проспекте Энгельса или заказывайте доставку, и мы не подведем!</p><p>В нашем меню есть все, что нужно для совместного семейного вечера с детьми, для романтического ужина, для рабочего обеда в окружении коллег и для просмотра сериалов в одиночестве.</p><p>Наши повара в точности следуют технологии приготовления и хранения продуктов. На кухнях строго соблюдаются санитарно-гигиенические нормы, что подтверждено успешными прохождениями проверок надзорных органов.</p>',

      categories: [
        {
          name: 'Суши в Выборгском районе',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Выборгском районе',
          metaTitle: 'Доставка суши и роллов в Выборгском районе — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Выборгском районе. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Нужна доставка суши в Выборгский район Санкт-Петербурга? Повара СУШИФОКС уже приступили к работе! Мы готовим суши, роллы и другие блюда японской кухни как по традиционным рецептам, так и по собственным, разработанным специально для нас Шеф-лисом. Посмотреть, в какую зону доставки входит ваш адрес, можно прямо на сайте, так вы точно узнаете, сколько времени нужно будет подождать курьера и какова минимальная сумма заказа.</p>',
          textBottom: '<h2>Свежие суши в Выборгском районе</h2><p>Суши и роллы так прочно вошли в быт жителей Санкт-Петербурга, что их заказывают уже не только на праздники или в качестве лакомства, а просто так, чтобы порадовать себя и семью в скучный серый вечер или разнообразить офисные будни. Это и вкусно, и полезно, и не требует никаких дополнительных усилий, даже мытья вилок после ужина.</p><p>В нашем меню все самые лучшие и известные роллы и суши &ndash; Филадельфия, Калифорния, классические роллы, запеченные, темпурные, с разнообразными начинками. Есть и фирменные блюда, таких вы не найдете больше ни у кого!</p><p>Чтобы заказать суши с доставкой, нужно выбрать их на сайте и указать адрес, куда приехать курьеру. Также вы можете забрать заказ в точке выдачи в двух шагах от метро &laquo;Проспект Просвещения&raquo;.</p><p>Не отказывайте себе в маленьких удовольствиях, ведь из них и состоит счастье!</p>',
        },
        {
          name: 'Пицца в Выборгском районе',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Выборгском районе',
          metaTitle: 'Доставка пиццы в Выборгском районе — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Выборгском районе. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>СУШИФОКС &ndash; это быстрая доставка вкусной пиццы по всему Выборгскому району Санкт-Петербурга! Мы привезем пиццу домой или в офис, и она будет горячая, сочная, истекающая ароматом и неизменно свежая. Время ожидания и сумму заказа можно посмотреть на сайте на карте или уточнить непосредственно у наших операторов по телефону. Работаем также в Парголово и Буграх.</p>',
          textBottom: '<h2>Пицца с доставкой по Выборгскому</h2><p>Заказать пиццу &ndash; это один из лучших способов провести вкусный вечер с семьей или просто отдохнуть от бесконечной серой суеты, в которой есть только работа и сон. Не надо готовить, не надо никуда бежать, не надо даже потом мыть посуду!</p><p>В нашем меню только лучшие рецепты, от классики до Экзотики. Мы готовим тесто сами и строго соблюдаем условия хранения и обработки продуктов. Все продукты поступают на кухню от проверенных поставщиков, так что мы можем гарантировать, что еда будет не только вкусной, но и полезной. Проверьте, и у вас отпадут всякие сомнения в том, где вкусная пицца в городе!</p><p>Чтобы заказать пиццу, можно воспользоваться сайтом, а можно прийти в пункт самовывоза. Тогда ваш заказ будет приготовлен прямо при вас. Время работы точки выдачи такое же, как и время работы доставки.</p>',
        },
      ],

    },
    {
      id: 1,
      name: 'Невский район',
      slug: 'nevskiy-rayon',
      type: RegionType.District,

      metaTitle: 'Доставка еды в Невском районе — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Невском районе. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Невском районе',
      textTop: '<p>СУШИФОКС &ndash; это бесплатная доставка еды по Невскому району Санкт-Петербурга. Мы работаем как в районах станций &laquo;Улица Дыбенко&raquo; и &laquo;Проспект Большевиков&raquo;, так и в Кудрово. Курьер привезет вам хрустящую ароматную пиццу и супы, свежие салаты, красивые сочные роллы и суши, сладкие десерты. Чтобы посмотреть, в какую зону доставки попадает ваш адрес, воспользуйтесь картой на сайте или уточните этот момент у наших Лисиц по телефону.</p>',
      textBottom: '<h2>Доставка вкусной японской еды в Невском</h2><p>В нашем меню есть все, что нужно для приятного вечера. Мы специализируемся на японском меню (суши, роллы, пряные супы и ВОКи), но также у нас есть и европейские блюда &ndash; например, пиццы и картофельные закуски. Не стоит забывать и о наших фирменных витаминных морсах, которые мы варим сами из свежих ягод.</p><p>Для приготовления еды наши повара используют только свежие продукты от проверенных поставщиков. Мы контролируем все поставки и не допускаем, чтобы на нашей кухне появилось что-то хотя бы залежавшееся, это совершенно недопустимо. К тому же, повара в точности следуют технологии хранения и обработки продуктов, так что в итоге наша еда исключительно свежая и вкусная.</p><p>Сразу после приготовления еда передается курьеру, и он доставляет ее по нужному адресу в невском районе. СУШИФОКС &ndash; это всегда Вкусно&amp;Быстро!</p>',

      categories: [
        {
          name: 'Суши в Невском районе',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Невском районе',
          metaTitle: 'Доставка суши и роллов в Невском районе — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Невском районе. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>СУШИФОКС &ndash; быстрая доставка вкусных роллов и суши в Невском районе Санкт-Петербурга. Зона доставки включает в себя Ладожский вокзал, метро &laquo;Улица Дыбенко&raquo; и &laquo;Проспект Большевиков&raquo;, Охту, Новый Оккервиль и Кудрово. Точную информацию о границах зон доставки, минимальной сумме заказа и времени ожидания смотрите на карте или уточняйте непосредственно у наших Лис-операторов по телефону.</p>',
          textBottom: '<h2>Доставка свежих роллов и суши &ndash; в каждый дом Невского района</h2><p>Чтобы заказать суши в невском районе Санкт-Петербурга, достаточно сделать выбор на сайте и либо продиктовать наименования оператору, либо оформить заказ через корзину как в обычном интернет-магазине. Для каждого блюда указан вес и калорийность. Подача и состав на фотографии соответствуют реальности. Можно заказать с доставкой не только целый ролл, но и половинку. Суши подаются по две штуки.</p><p>В нашем меню суши и роллы на любой вкус. По Невскому району мы доставляем холодные, запеченные и жареные роллы с крабом, угрем, лососем, тунцом, курицей и овощами. Для приготовления мы используем только свежесваренный рис и свежую рыбу и мясо, все продукты хранятся с соблюдением технологии и температурного режима, поэтому полностью безопасны для употребления.</p>',
        },
        {
          name: 'Пицца в Невском районе',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Невском районе',
          metaTitle: 'Доставка пиццы в Невском районе — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Невском районе. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Когда наступает время для пиццы? Всегда! СУШИФОКС доставляет свежую горячую пиццу по всему Невскому району Санкт-Петербурга, от Октябрьской набережной до Кудрово и Заневки. Смотрите, в какую зону доставки попадает ваш адрес, прямо на сайте, или уточняйте у наших операторов по телефону. От зоны доставки зависит время ожидания курьера и минимальная сумма заказа. Возможен предзаказ к определенному дню и часу.</p>',
          textBottom: '<h2>Пицца уже едет по Невскому району</h2><p>Что может быть лучше вкусной ароматной пиццы с хрустящей корочкой? Разве что еще одна. В нашем меню представлены самые разнообразные рецепты: спокойная выдержанная классика, острые с халапенью, экзотические и даже фирменные. Любую из них можно дополнить сырным бортиком, который так вкусно есть до последней крошки.</p><p>Все пиццы приготовлены на традиционном тесте, раскатанном руками по классической технологии. В качестве основы мы используем различные соусы, которые подчеркивают вкус начинки.</p><p>На нашей кухне всегда царит чистота, а продукты поступают от проверенных поставщиков, и мы тщательно следим за тем, чтобы они были свежие и хранились в надлежащих условиях. Пиццы готовятся только для вас и только после того как был подтвержден заказ.</p>',
        },
      ],

    },
    {
      id: 1,
      name: 'Красногвардейский район',
      slug: 'krasnogvardeyskiy-rayon',
      type: RegionType.District,

      metaTitle: 'Доставка еды в Красногвардейском районе — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Красногвардейском районе. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Красногвардейском районе',
      textTop: '<p>Быстрая доставка еды по Красногвардейскому району &ndash; здесь! Вам нужно только позвонить в СУШИФОКС или оставить заявку через сайт, и уже скоро у вас будут свежие роллы, хрустящая пицца, горячие ВОКи, сладкие десерты &ndash; все, что вы выберете. Время доставки и минимальная стоимость заказа зависят от адреса, смотрите вашу зону доставки на сайте.</p>',
      textBottom: '<h2>Доставка свежей еды по Красногвардейскому району</h2><p>СУШИФОКС доставляет еду по всему Красногвардейскому району Санкт-Петербурга: от Октябрьской набережной до КАД и от Пискаревского проспекта до проспекта Косыгина. У нас есть все, что нужно для хорошего ужина &ndash; горячие блюда. Пицца, закуски, роллы и суши, а также десерты.</p><p>Перед тем, как отправить новую рецептуру в производство, мы проводим дегустации и выявляем самое вкусное, самое приятное для глаза и рта сочетание продуктов. Еда готовится профессиональными поварами из свежих продуктов, которые привозят проверенные поставщики. Этот лосось был выловлен в Баренцевом море и сразу же приехал на кухню, а потом и к вам!</p><p>Для удобства меню разделено не только по виду блюда, но и по ингредиентам и типу. Так гораздо проще будет выбрать любимые запеченные роллы, или роллы с лососем, или пиццу без грибов, или просто самые популярные блюда.</p>',

      categories: [
        {
          name: 'Суши в Красногвардейском районе',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Красногвардейском районе',
          metaTitle: 'Доставка суши и роллов в Красногвардейском районе — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Красногвардейском районе. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>СУШИФОКС &ndash; быстрая доставка вкусных суши и роллов по Красногвардейскому районе Санкт-Петербурга от КАД до Невы и от Суздальского проспекта до Пискаревского. Курьер привезет их в специальной термосумке, чтобы сохранить правильную температуру независимо от того, будут это холодные роллы или запеченные. Минимальную сумму заказа и время ожидания уточняйте у лиса-оператора по телефону или смотрите на сайте.</p>',
          textBottom: '<h2>Оперативная доставка суши по Красногвардейскому району</h2><p>Чтобы заказать суши или роллы с доставкой, не нужно даже отдельного повода. Достаточно просто желания именно сегодня, именно сейчас съесть именно вот этот ролл. И еще вон тот. Или тот. А еще лучше взять мини-порции всех трех и насладиться разнообразием вкусов.</p><p>В нашем меню есть блюда с разными дарами моря &ndash; лососем и угрем, тунцом и крабом, а также со свежими овощами и фруктами. Все это оттеняется сливочным сыром или пикантной шапочкой и завернуто в свежесваренный рис.</p><p>Мы используем только свежие продукты от проверенных поставщиков и строго соблюдаем правила их хранения, так что вы получите исключительно полезную еду, которая не только вкусная, но и насыщает мозг важными микроэлементами. Именно поэтому роллы рекомендуют при интенсивных интеллектуальных и физических нагрузках!</p>',
        },
        {
          name: 'Пицца в Красногвардейском районе',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Красногвардейском районе',
          metaTitle: 'Доставка пиццы в Красногвардейском районе — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Красногвардейском районе. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>СУШИФОКС &ndash; это быстрая доставка горячей пиццы в Красногвардейском районе Санкт-Петербурга. Область доставки включает в себя Октябрьскую набережную, Пискаревский и Суздальский проспекты, Ладожский вокзал, Новочеркасскую &ndash; вплоть до КАД. Минимальная сумма заказа и время ожидания зависят от конкретного адреса доставки, смотрите подробную информацию на карте или уточняйте у лисов-операторов по телефону.</p>',
          textBottom: '<h2>Вкусная пицца с доставкой по Красногвардейскому району</h2><p>Горячая, истекающая запахами пицца, которую не нужно готовить дома, которую привезет курьер, разве это не прекрасно само по себе? Неважно, каким будет повод побаловать себя &ndash; праздник, свидание, семейные посиделки, новый сезон сериала или просто банальный голод. Главное, что пицца никогда не станет лишней.</p><p>Чтобы заказать доставку пиццы по Красногвардейскому району, нужно оформить заказ на сайте или через оператора по телефону. Повара приступают к работе после регистрации заказа. Они используют свежие продукты и тесто и работают по выверенной дегустациями рецептуре.</p><p>Все продукты проходят обязательную проверку качества и свежести и хранятся в строгом соответствии с принципами товарного соседства и санитарными правилами. После приготовления пицца проходит проверку качества у су-шефа.</p>',
        },
      ],

    },
    {
      id: 1,
      name: 'Калининский район',
      slug: 'kalininskiy-rayon',
      type: RegionType.District,

      metaTitle: 'Доставка еды в Калининском районе — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Калининском районе. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Калининском районе',
      textTop: '<p>СУШИФОКС &ndash; быстрая доставка вкусной еды по всему Калининскому району. Мы привезем вам пиццу, суши, роллы, ВОКи, супы, салаты, закуски и десерты в любую точку района, хоть в Мурино, хоть на Финляндский вокзал или в район Сосновки. На карте вы можете посмотреть время ожидания и минимальную сумму заказа для вашего адреса. Или уточняйте по телефону у наших Лисов и Лисиц.</p>',
      textBottom: '<h2>Привезем еду в Калининском &ndash; быстро!</h2><p>Что вы хотите съесть этим питерским вечером? Может быть, пиццу &ndash; с корочкой, истекающую сырным ароматом, с колбасками или креветками? А может, роллы &ndash; из свежесваренного риса, с рыбой, с креветкой, с овощами, и может быть даже под сырной шапочкой, подчеркивающей вкус начинки? Или &ndash; пряный азиатский суп? Или &ndash; сытный ВОК с лапшой или рисом? А для сладкоежек есть десерты. И не забывайте про свежесваренные витаминные морсы!</p><p>Мы привезем вам все, что вы только захотите! Доставляем еду в Калининском районе всем, кто голоден, кто желает раскрасить скучный день и превратить его в маленький праздник. Заказать доставку еды можно к ближайшему времени &ndash; или к определенному дню и часу.</p><p>Все блюда в меню прошли тщательный отбор, их вкус и рецептура проверены и выверены. При приготовлении повара в точности соблюдают технологию хранения и обработки продуктов, поэтому она всегда свежая, вкусная и полезная!</p>',

      categories: [
        {
          name: 'Суши в Калининском районе',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Калининском районе',
          metaTitle: 'Доставка суши и роллов в Калининском районе — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Калининском районе. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Доставка суши и роллов СУШИФОКС уже выезжает в Калининский район Санкт-Петербурга! И везет с собой классические Филадельфии, эффектные черные и огненно-острые роллы, а еще много суши с крабом, лососем и креветкой! Мы привозим суши и роллы по любому адресу в районе. Подробности смотрите на карте или уточняйте прямо у наших Лисов и Лисиц по телефону или в официальных соцсетях.</p>',
          textBottom: '<h2>Роллы уже едут в Калининский район</h2><p>Чтобы заказать суши в Калининском районе, не нужно никуда идти и ждать своей очереди в ресторане. Можно просто выбрать все самое любимое по меню на сайте СУШИФОКС, и еду привезут уже в ближайшее время.</p><p>Мы готовим роллы и суши из свежесваренного риса и используем только охлажденную рыбу от проверенных поставщиков &ndash; мы сами наблюдали весь процесс подготовки этой рыбы к продаже, начиная от вылова и заканчивая упаковкой! Мы ежедневно проверяем свежесть овощей, так что они всегда хрустящие и сочные. Мы тщательно следуем рекомендациям по хранению продуктов и технологии их обработки. На кухне соблюдается чистота и требования СанПин.</p><p>В меню есть как привычные блюда, так и фирменные &laquo;фишки&raquo; от Шеф-Лиса. Состав, калорийность и другие детали указаны прямо в меню. Фотографии соответствуют подаче.</p>',
        },
        {
          name: 'Пицца в Калининском районе',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Калининском районе',
          metaTitle: 'Доставка пиццы в Калининском районе — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Калининском районе. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Вкусная, горячая, ароматная пицца уже едет в Калининский район Санкт-Петербурга! СУШИФОКС гарантирует быструю доставку, роскошный вкус еды и море позитива. Мы доставляем пиццу по всему району, не ограничиваясь магистральными Светлановским или Полюстровским проспектами. Точное время ожидания и минимальную сумму заказа для вашего адреса смотрите на карте или уточняйте у наших Лисов и Лисиц.</p>',
          textBottom: '<h2>Горячая пицца в Калининском районе</h2><p>Быстрая доставка вкусной, еще горячей пиццы &ndash; наш приоритет. Мы постоянно совершенствуем меню, доводим подачу блюд до идеала и добавляем новые и новые пиццы. На данный момент в Калининском районе Санкт-Петербурга можно заказать и классику (Маргариту, Фунги поло или Карбонару), и Экзотику, и даже пиццу с соусом Цезарь!</p><p>Также есть вегетарианские блюда с начинкой из овощей. В тесто не добавляются яйца.</p><p>Обратите внимание, что для каждого блюда указаны не только его вес и размер, но и калорийность. Конечно, пиццы редко укладываются в &laquo;коридор калорий&raquo;, но хотя бы иногда, хотя бы немного их может себе позволить каждый!</p><p>При разработке рецептуры и подачи мы проводили тщательные испытания, и в меню попали только те пиццы, за которые нам не стыдно. Каждое блюдо готовится только для вас после регистрации заказа.</p>',
        },
      ],

    },

    {
      id: 1,
      name: 'Мурино',
      slug: 'murino',
      type: RegionType.City,

      metaTitle: 'Доставка еды в Мурино — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Мурино. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Мурино',
      textTop: '<p>СУШИФОКС &ndash; это доставка готовой еды в Мурино и Девяткино (Санкт-Петербург). В нашем меню суши, роллы, пиццы, супы, салаты, закуски, ВОКи, десерты и свежие морсы. Доставка осуществляется также в Лаврики, Новое Девяткино и Бугры. Подробнее смотрите на карте прямо на этой странице. Там же вы можете увидеть, к какой зоне доставки относится желаемый адрес, какая будет минимальная сумма заказа для него и время ожидания.</p>',
      textBottom: '<h2>Вкусная еда на дом в Мурино</h2><p>Заказ еды в Мурино и Девяткино &ndash; это отличный способ хотя бы на время избавиться от опостылевших кулинарных забот и насладиться вкусными суши или пиццей. Не выходя из дома и не думая о том, что еще придется мыть посуду!</p><p>В нашем меню есть все, что нужно для семейного ужина или обеда в офисе. Все блюда готовятся специально для вас и с соблюдением технологии и рецептуры, поэтому исключительно полезны. Мы используем мясо, рыбу и овощи от проверенных поставщиков. На кухне соблюдены санитарно-гигиенические нормы.</p><p>Чтобы заказать доставку еды в Мурино, нужно выбрать ее на сайте. Дальше можно &laquo;купить&raquo; ее, как в обычном интернет-магазине, или продиктовать названия по телефону. Оператор также проконсультирует вас по всем сопутствующим вопросам. Возможен предзаказ к определенному времени.</p>',

      categories: [
        {
          name: 'Суши в Мурино',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Мурино',
          metaTitle: 'Доставка суши и роллов в Мурино — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Мурино. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Заказать суши и роллы с бесплатной доставкой по Мурино и Девяткино &ndash; это просто, ведь вы уже в СУШИФОКС! Здесь вы найдете разнообразные роллы и суши с креветкой, лососем, угрем и крабом, а еще &ndash; традиционные азиатские супы и салаты. И не только! Все меню на одном сайте, все блюда готовятся из свежих продуктов от проверенных поставщиков. Посмотреть, в какую зону доставки входит ваш адрес, можно на карте.</p>',
          textBottom: '<h2>Свежие вкусные роллы в Мурино и Новом Девяткино</h2><p>Суши на заказ с доставкой на дом пользуются в Муриной большой популярностью, и это не удивительно. Еда приедет сразу с приборами и соусами, в коробках, которые можно спокойно выкинуть, а не мыть. От вас не требуется ничего сложнее выбора блюд в меню!</p><p>Впрочем, выбрать бывает нелегко. В нашем меню несколько десятков различных роллов и суши, от классики до фирменных рецептов от Шеф-лиса. Каждый рецепт еще при создании прошел испытания, и мы тщательно проверяли вкус, консистенцию начинки и даже внешний вид блюда, чтобы они стали идеальными.</p><p>Если вы хотите попробовать больше, то заказывайте половинки роллов! Это удобно, ведь тогда можно выбрать все, к чему лежит душа.</p><p>Для всех блюд указаны их состав, вес, калорийность. Фотография соответствует подаче блюда.</p>',
        },
        {
          name: 'Пицца в Мурино',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Мурино',
          metaTitle: 'Доставка пиццы в Мурино — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Мурино. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>СУШИФОКС &ndash; это бесплатная доставка пиццы в Мурино и Девяткино на дом и в офис. Каждый кусочек сочной начинки и хрусткого бортика &ndash; это настоящая радость и для детей, и для взрослых, которые хотят отвлечься от повседневной суеты. Воспользовавшись картой, вы узнаете, через какое время приедет курьер и какова минимальная сумма заказа. Также эту информацию можно получить у наших Лис-операторов по телефону.</p>',
          textBottom: '<h2>Вкусная пицца с доставкой по Мурино</h2><p>Возможность заказать пиццу в Мурино или Девяткино с бесплатной доставкой &ndash; это то, чем грех не воспользоваться. Почему? Кто же откажется от хрустящей, хорошо прожаренной пиццы с сочной начинкой из свежих овощей, мяса, сыра и морепродуктов? И это все не нужно резать, варить, жарить и печь, все уже готово и приедет горячим и истекающим аппетитными запахами.</p><p>В нашем меню есть пиццы на любой вкус: и вегетарианские, и мясные, и с морепродуктами. Пиццу можно дополнить бортиком с сыром чеддер!</p><p>Чтобы купить пиццу в Мурино с доставкой, нужно оформить заказ через сайт или по телефону. Пиццу начинают готовить прямо перед отправкой к вам, так что неважно, выбираете ли вы доставку к ближайшему времени или на определенный день и час.</p>',
        },
      ],

    },
    {
      id: 1,
      name: 'Янино',
      slug: 'yanino',
      type: RegionType.City,

      metaTitle: 'Доставка еды в Янино — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Янино. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Янино',
      textTop: '<p>СУШИФОКС &ndash; это доставка еды в Янино и окрестности. Мы доставляем пиццу, суши, роллы, ВОКи, закуски, супы, салаты, сладости и напитки во все жилые комплексы района. Еда приедет свежая, горячая, в специальной термосумке, которая сохраняет правильную температуру. Доступен предзаказ к определенному дню и часу.</p>',
      textBottom: '<h2>Свежая еда в Янино с доставкой</h2><p>Вечер. Семья уже дома, и меньше всего хочется куда-то выходить, закупаться продуктами, нести их домой, потом готовить и мыть посуду &ndash; этот бесконечный круг однообразного быта, который затягивает хуже любого болота. Гораздо проще, гораздо быстрее &ndash; заказать доставку еды.</p><p>Мы предлагаем блюда, которые не всегда удобно готовить дома. Суши и роллы со свежей рыбкой, пицца с сочным вкусным сырным бортиком, жареные во фритюре закуски из курицы и картофеля, азиатские супы и европейские салаты, и, конечно же, десерты!</p><p>Каждое блюдо было проработано и прошло вступительные испытания на кухне и у фокус-группы. Мы отобрали лучшие рецепты и довели их до совершенства. Добавляем к этому тщательную работу с поставщиками и строгое соблюдение технологии хранения и обработки продуктов &ndash; и получаем вкуснейшую еду, которой можно наслаждаться!</p><p>Заказать доставку еды в Янино 1 можно как по телефону, так и через сайт или мобильное приложение.</p>',

      categories: [
        {
          name: 'Суши в Янино',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Янино',
          metaTitle: 'Доставка суши и роллов в Янино — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Янино. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>СУШИФОКС &ndash; это быстрая доставка суши и роллов в Янино, в том числе в ЖК Ясно Янино, Янила-Драйв, Новое Янино и др. Курьер привезет еду в специальной термосумке, которая сохраняет температуру блюда. Мы соблюдаем в том числе и правильность укладки блюд в сумке, чтобы они не обменивались теплом.</p>',
          textBottom: '<h2>Разнообразие суши и роллов в Янино</h2><p>Чтобы заказать в Янино суши и роллы с доставкой на дом, не нужно далеко ходить. Самое сложное будет &ndash; выбрать, что же сегодня желает душа? Может быть, это будет классика &ndash; Филадельфия, Калифорния, ролл с лососем или авокадо. А может, сегодня хочется остренького с соусом спайси. Или запеченного. Или жареного в темпуре!</p><p>В нашем меню есть все: холодные роллы с крабом, лососем, угрем, овощами. Их запеченные вариации под шапочками из сыра, масаго или острого соуса шрирачи. Темпурные роллы с овощами и рыбой. Сладкие роллы в спринг-тесте &ndash; с минимумом сахара, только сыр и свежие фрукты! Отдельно выделяются холодные черные роллы с миндалем, лососем и крабом &ndash; яркие, оригинальные, но такие же вкусные!</p><p>Многие роллы можно заказать не целиком, а половинкой &ndash; четыре кусочка. Это позволит попробовать больше вкусов за один раз! Не стоит обходить внимание и сеты на любой вкус &ndash; ассорти из суши, из запеченных острых роллов, из самых популярных блюд.</p>',
        },
        {
          name: 'Пицца в Янино',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Янино',
          metaTitle: 'Доставка пиццы в Янино — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Янино. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>СУШИФОКС &ndash; это быстрая доставка пиццы на дом в Янино (ЖК Ясно-Янино, Новое Янино, Янинский каскад, Янила-Драйв, Янинский лес). Проверить адрес можно на карте или уточнив у Лиса-оператора по телефону. Пицца приедет горячей, ароматной, с расплавленным сыром и нежной начинкой на хрустящем тесте. Каждую можно дополнить сырным бортиком, который так вкусно есть до последней крошки.</p>',
          textBottom: '<h2>Вкусная пицца в Янино</h2><p>Чтобы заказать доставку пиццы в Янино 1, нужно выбрать, что именно вы хотите съесть сегодня, а потом продиктовать адрес и блюда оператору или пройти электронную регистрацию заказа прямо на сайте или в мобильном приложении.</p><p>Мы строго придерживаемся рецептуры и выбираем только лучших поставщиков. Это помогает печь вкусные пиццы из самых свежих продуктов. Для каждого сочетания начинки мы подбирали свой соус: бешамель, томатный или жюльен. Соус подчеркивает вкус начинки и делает его гармоничным. В состав соусов также входят только свежие натуральные продукты. Мы внимательно следим за условиями хранения и разделки.</p><p>Для всех блюд в меню указаны калорийность, БЖУ, состав, вес, размер. Мы печем пиццы на традиционном тесте и можем дополнить любую сырным бортиком с гаудой и моцареллой.</p><p>Обратите внимание на наши акции, комбо-наборы и спецпредложения.</p>',
        },
      ],

    },
    {
      id: 1,
      name: 'Кудрово',
      slug: 'kudrovo',
      type: RegionType.City,

      metaTitle: 'Доставка еды в Кудрово — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Кудрово. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Кудрово',
      textTop: '<p>СУШИФОКС &ndash; это оперативная доставка еды в Кудрово на дом и в офис. Спасаем от голода и тленности бытия, радуем вкусной едой всех, кто устал и хочет внести в свою жизнь немного праздника! На карте в этом разделе сайта или по телефону вы сможете проверить, в какую зону доставки попадает ваш адрес и сколько нужно будет подождать курьера. Доступен предзаказ к определенному дню и часу.</p>',
      textBottom: '<h2>Как заказать еду в Кудрово</h2><p>Чтобы сделать заказ еды в Кудрово с доставкой, нужно всего лишь выбрать то, что сегодня желает ваша семья, и вот повара уже встают к плите, а курьер везет свежие роллы, пиццу, супы, салаты и сладости к вам домой!</p><p>Мы предлагаем блюда японского и европейского меню. Каждое из них детально проработано, начиная от выбора продуктов и заканчивая подачей. Мы используем только свежие продукты от проверенных поставщиков и избегаем заморозок.</p><p>На кухне соблюдается чистота и санитарные требования. Повара проходят обучение приготовлению блюд японской и европейской кухни, а также технологии хранения и обработки продуктов. Проводятся контрольные закупки со стороны руководства. Именно поэтому приготовленная еда всегда вкусная, свежая и буквально манит к чревоугодию!</p>',

      categories: [
        {
          name: 'Суши в Кудрово',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Кудрово',
          metaTitle: 'Доставка суши и роллов в Кудрово — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Кудрово. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Заказать суши и роллы в Кудрово с доставкой &ndash; это просто, если позвонить в СУШИФОКС. Доставка работает как непосредственно в Кудрово, так и в окрестностях. Посмотреть, к какой зоне доставки относится ваш адрес, какими будут приблизительное время ожидания и минимальная сумма заказа, можно на карте. также на эти и иные вопросы ответят наши лисы и Лисицы по телефону и в официальных социальных сетях.</p>',
          textBottom: '<h2>Роллы и суши в Кудрово с доставкой от СУШИФОКС</h2><p>Японская кухня уже давно завоевала Санкт-Петербург, а суши и роллы вошли в привычный рацион. Их заказывают не только на праздники или свидания, но и просто так, чтобы побаловать себя и семью или отвлечься от серых невыносимых будней, наполненных суетой.</p><p>В нашем меню суши и роллы с угрем, лососем, тунцом, курицей, овощами, крабом. Есть как привычные классические рецепты (например, Филадельфия или ролл с огурцом), так и фирменные роллы от нашего шеф-повара. Для удобства меню разделено по типу приготовления и по виду начинки.</p><p>Все блюда готовятся непосредственно перед доставкой, так что вы получите свежий, вкусные, еще теплые (если это подразумевается рецептом) роллы и суши. Доступен предзаказ к определенному времени. Оплата возможна через сайт или курьеру в момент получения.</p>',
        },
        {
          name: 'Пицца в Кудрово',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Кудрово',
          metaTitle: 'Доставка пиццы в Кудрово — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Кудрово. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>СУШИФОКС &ndash; это возможность заказать пиццу с бесплатной доставкой в Кудрово и порадовать себя вкусным ужином, не утруждая себя походами в магазин и мытьем посуды. Посмотреть, сколько нужно будет подождать и какова минимальная сумма заказа, можно прямо на сайте. Также вы можете задать все вопросы по доставке, меню и акциям напрямую нашим лисам-операторам по телефону.</p>',
          textBottom: '<h2>Свежая пицца в Кудрово</h2><p>Пицца с доставкой в Кудрово пользуется большой популярностью, ведь это вкусно, сытно и очень просто. Самое сложное &ndash; это выбрать, что именно вы желаете сегодня, в этот день и час. Может, классику с пепперони или сыром? Или что-нибудь с морепродуктами? Или сырную пиццу? Или, может, сегодня слишком холодно и промозгло на улице, чтобы не подогреть себя острым перчиком халапеньо?</p><p>В нашем меню представлены самые разные пиццы, в том числе вегетарианские. Каждый рецепт был тщательно разработан и потом опробован, мы следим и за правильной подачей. К пицце можно заказать сырный бортик и соусы.</p><p>Для приготовления пицц мы используем только свежие продукты от проверенных поставщиков, а повара тщательно следуют технологии хранения и обработки продуктов. На кухнях царит чистота, мы следим за соблюдением всех санитарно-гигиенических норм.</p>',
        },
      ],

    },
    {
      id: 1,
      name: 'Парнас',
      slug: 'parnas',
      type: RegionType.City,

      metaTitle: 'Доставка еды у метро Парнас — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис у метро Парнас. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды у метро Парнас',
      textTop: '<p>СУШИФОКС &ndash; это доставка еды на Парнасе (Санкт-Петербург): ул. Михаила Дудина, ул. Фёдора Абрамова, Суздальский пр. и пр. Энгельса и др. Посмотреть в какую зону доставки входит именно ваш адрес, вы можете на карте. На ней же указаны минимальная сумма заказа и приблизительное время ожидания курьера. Также вы можете задать все вопросы нашим лисам-операторам по телефону.</p>',
      textBottom: '<h2>Доставка еды в Парнасе</h2><p>Возможность вкусно поесть &ndash; это то, что радует большинство людей. А если еще и не придется прикладывать для готовки никаких усилий, то даже самый скучный и напряженный день уже становится светлее и приятнее!</p><p>В нашем меню есть пиццы, роллы, суши, ВОКи, закуски, супы, салаты, сладости и напитки. Все это готовят повара из свежих продуктов, которые привозят на кухню проверенные поставщики, за работой которых мы наблюдали лично. Сразу при приемке продукты проходят контроль качества &ndash; мы осматриваем каждую рыбину и каждое авокадо, чтобы брак даже не попал в холодильники. Потом продукты хранятся и обрабатываются в строгом соответствии с технологией.</p><p>На кухне соблюдаются санитарно-гигиенические нормы, а повара прошли обучение, и поэтому мы доставляем в Парнас только вкусную свежую еду!</p>',

      categories: [
        {
          name: 'Суши у метро Парнас',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы у метро Парнас',
          metaTitle: 'Доставка суши и роллов у метро Парнас — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис у метро Парнас. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Заказать суши и роллы на Парнасе с доставкой на дом &ndash; это очень просто, ведь вы уже открыли меню СУШИФОКС! А в нем: классические и холодные, запеченные, жареные роллы, суши с креветкой, угрем, лососем и крабом, и все это в сочетании с разнообразными сырами, овощами и даже мясом. Мы доставляем еду в окрестности Парнаса вплоть до КАД. Посмотреть, в какую зону доставки входит ваш адрес, вы можете на карте.</p>',
          textBottom: '<h2>Вкусные суши в Парнасе</h2><p>Японская кухня прочно завоевала популярность в Санкт-Петербурге и вошла в повседневную жизнь. Заказать роллы или суши уже не считается чем-то экзотичным и доступным только по праздникам, радовать себя любимыми блюдами можно в любой день, когда того пожелает душа.</p><p>При разработке меню мы опирались на статистику и мнение жителей города, поэтому включили в него всю &laquo;нестареющую классику&raquo; - Филадельфии, Калифорнии, классические роллы. Добавили множество запеченных и темпурных роллов. А к ним еще &ndash; фирменные блюда от Шеф-Лиса.</p><p>Не остались в стороне и суши. В Парнас мы доставляем холодные и запеченные суши с различными начинками. Если вы любите поострее &ndash; то для вас суши с соусом спайси, который только подчеркивает вкус начинки и сырной шапочки.</p><p>Для каждого блюда указаны вес, подача, калорийность и состав.</p>',
        },
        {
          name: 'Пицца у метро Парнас',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы у метро Парнас',
          metaTitle: 'Доставка пиццы у метро Парнас — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис у метро Парнас. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Доставка пиццы в Парнасе &ndash; это СУШИФОКС! Вкусной, свежей, хрустящей, горячей, истекающей ароматным паром пиццы, которую так вкусно есть после напряженного дня, в выходные или в офисе. В зону доставки входят не только непосредственно окрестности метро, но и весь Суздальский проспект и проспект Энгельса, а также Парголово. Подробнее о зонах доставки, сроках ожидания и минимальной сумме заказа спрашивайте у наших Лисов.</p>',
          textBottom: '<h2>Ароматные пиццы с доставкой в Парнас</h2><p>Чтобы заказать пиццу в Парнас с доставкой, нужно только зайти на сайт и выбрать то, что по душе именно сегодня. В меню пиццы с овощами, грибами, мясом и морепродуктами. К каждой можно добавить сырный бортик с сыром чеддер!</p><p>Для всех блюд в меню указаны их вес, размер, состав и калорийность. А о вкусе вы сделаете вывод, только попробовав наши пиццы лично. Для приверженцев классики у нас есть Маргарита, Карбонара, Пепперони, для любителей остренького &ndash; пиццы с халапеньо, для тех, кто предпочитает что-нибудь оригинальное &ndash; Экзотика с ананасами и пицца Цезарь.</p><p>Меню постоянно пополняется, но устоявшуюся рецептуру мы не меняем, так что вы получите всегда ту пиццу, которую ожидаете. Если у вас возникли вопросы по составу пицц или по доставке их на Парнас, обязательно задайте их нашим операторам по телефону или в официальных группах в соцсетях.</p>',
        },
      ],

    },
    {
      id: 1,
      name: 'Песочный',
      slug: 'pesochniy',
      type: RegionType.City,

      metaTitle: 'Доставка еды в Песочном — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Песочном. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Песочном',
      textTop: '<p>СУШИФОКС &ndash; это быстрая доставка еды в поселок Песочный и окрестности. У нас вы можете заказать вкусные суши и роллы, горячую истекающую сыром пиццу, ВОКи с лисом и лапшой, сладкие десерты и пряные закуски. Чтобы узнать время доставки и минимальную сумму заказа для вашего адреса, воспользуйтесь картой или задайте вопрос нашим лисам-операторам по телефону.</p>',
      textBottom: '<h2>Доставка вкусной еды в Песочный</h2><p>Жизнь &ndash; сложная штука. Стрессы, бесконечная работа и такая же нескончаемая круговерть бытовых проблем и дел, проблемы, задачи и поиск решений &ndash; все это отнимает много сил и почти не оставляет их для радостей. Но все же некоторые вещи можно отложить! Зачем бежать в магазин, потом стоять у плиты или духовки, потом мыть посуду &ndash; если можно просто заказать доставку готовой еды, и все сделают повара?</p><p>Что у нас есть? У нас есть все для романтического вечера и для семейных встреч, для посиделок с друзьями и простого счастья интроверта! Если вы любите поострее, то есть роллы со соусом спайси и пицца с жгучими перчиками халапеньо. Если вы любите обволакивающую нежность и деликатность вкуса, то для вас нежные классические роллы с сырными шапочками, паста карбонара, сливочные супы и многое другое. Если вы не мыслите своей жизни без сладостей, но не хотите ступать на путь порока и сахара, то для вас в меню есть фруктовые сладкие роллы!</p>',

      categories: [
        {
          name: 'Суши в Песочном',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Песочном',
          metaTitle: 'Доставка суши и роллов в Песочном — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Песочном. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Нужна доставка суши и роллов в Песочный? Она здесь! В меню есть блюда с лососем, угрем, крабом, тигровой креветкой, оттененными свежесваренным рисом по собственной рецептуре, сливочным сыром, хрустящими овощами. А запеченные &ndash; еще и пикантной шапочкой. Еда приедет в термосумке, упакованная по всем правилам товарного соседства так, чтобы запеченные роллы были горячими, а холодные &ndash; не нагревались.</p>',
          textBottom: '<h2>Холодные и запеченные роллы в Песочном</h2><p>Суши &ndash; это уже не экзотическое блюдо, а вполне обыденная закуска, которая всем нравится и приятно разнообразит привычный рацион. А еще они просто очень вкусные и простые в обращении &ndash; не нужно ни готовить, ни мыть потом посуду.</p><p>Сочетание сытного риса, овощей и морепродуктов делают роллы и суши полезным ужином. Главное не перестараться, а это будет очень сложно, ведь наши суши буквально тают во рту и вызывают зависимость!</p><p>Чтобы упростить подбор блюд, мы предусмотрели разделение меню на подборки по составу, чтобы каждый мог выбрать то, что ему по нраву именно сегодня, будь то нежный лосось или хрусткая тигровая креветка.</p>',
        },
        {
          name: 'Пицца в Песочном',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Песочном',
          metaTitle: 'Доставка пиццы в Песочном — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Песочном. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Вкусная горячая пицца с доставкой в песочный &ndash; разве это плохо? Наоборот, это прекрасно! В офисе или дома, на даче или в гостях &ndash; пицца будет уместна везде. Чтобы заказать доставку, оставьте заявку на сайте или у лиса-оператора по телефону. Минимальная сумма заказа и время ожидания указаны на карте.</p>',
          textBottom: '<h2>Ароматная пицца в Песочном</h2><p>СУШИФОКС доставляет в Песочный пиццу, приготовленную по всем правилам поварского искусства: горячую, на хорошо пропеченном хрустящем тесте, с сочной не пересушенной начинкой и расплавленным пряным сыром. Вкус начинки оттеняют соусы собственного приготовления: жюльен, цезарь, бешамель, томатный. Перед тем как попасть в меню, каждое блюдо проходило тщательную дегустацию.</p><p>В нашем меню есть пиццы на любой вкус &ndash; и традиционные Маргарита или Пепперони, и экзотичные, и фирменные рецепты. Каждую пиццу можно дополнить вкусным сырным бортиком, который так приятно есть до последней крошки. Можно дополнить пиццу и соусом &ndash; особенной популярностью пользуются соусы барбекю, кетчуп, чили.</p><p>Состав пицц указан на сайте. Также в меню указаны калорийность (за всю пиццу), вес, размер. Все наши пиццы пекутся на традиционном тесте, которое идеально удерживает начинку и не перебивает ее вкус.</p>',
        },
      ],

    },
    {
      id: 1,
      name: 'Сертолово',
      slug: 'sertolovo',
      type: RegionType.City,

      metaTitle: 'Доставка еды в Сертолово — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Сертолово. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Сертолово',
      textTop: '<p>Доставка еды СУШИФОКС работает в Сертолово и окрестностях. Мы привозим блюда азиатской и европейской кухонь, и каждое блюдо прошло неоднократную дегустацию, прежде чем начинать его готовить для вас. Минимальная сумма заказа и время доставки зависят от вашего адреса, посмотреть их можно на карте.</p>',
      textBottom: '<h2>Как заказать еду с доставкой в Сертолово?</h2><p>Чтобы заказать доставку еды в Сертолово, нужно только выбрать то, что желает ваша душа именно сегодня и именно сейчас. А это бывает непросто&hellip; в нашем меню есть пиццы, роллы, суши, ВОКи, салаты, супы, сладости и морсы, и каждое блюдо &ndash; вкусное, только что приготовленное именно для вас, каждое хочется попробовать. Повар приступает к работе над заказом только после его подтверждения, так что и рыба, и креветки, и овощи будут свежими!</p><p>Все наши повара имеют профильное образование и прошли специальное обучение приготовлению блюд из меню. За качеством готовой еды внимательно следят контролеры и шеф-повар, а сырье проверяют при каждой поставке. Мы работаем только с надежными поставщиками!</p><p>Новые блюда появляются в меню регулярно. Это могут быть роллы, горячее, десерты &ndash; что угодно. Мы внимательно следим и за пожеланиями наших клиентов. Свои отзывы и пожелания оставляйте на наших страницах в социальных сетях.</p>',

      categories: [
        {
          name: 'Суши в Сертолово',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Сертолово',
          metaTitle: 'Доставка суши и роллов в Сертолово — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Сертолово. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>СУШИФОКС &ndash; это быстрая доставка роллов и суши в Сертолово и окрестности. Каждый ролл приедет в индивидуальной упаковке, которая сохраняет вкусовые качества и правильную температуру блюда. Не забудем мы и про палочки, соусы, салфетки и даже зубочистки! Точное время ожидания и минимальную сумму заказа смотрите на карту или уточняйте у оператора при подтверждении.</p>',
          textBottom: '<h2>Свежие роллы и суши в Сертолово</h2><p>Заказать суши или ролл с доставкой в Сертолово &ndash; чем не вариант замены скучной бесконечной готовки и мытья посуды? Еду приготовят профессиональные повара из свежих продуктов, привезет курьер, посуду можно будет просто выкинуть &ndash; вам останется только самое важное: наслаждаться вкусом и жизнью!</p><p>В состав роллов входят сытный рис, наполненные микроэлементами морепродукты, хрустящие витаминные овощи, которые оттеняются сырами, а они, как известно, все на свете делают лучше. В нашем меню есть и лаконичные по составу классические, пряные запеченные роллы, а также изысканные многогранные сочетания вкусов и фактур у роллов с множеством ингредиентов.</p><p>Состав блюда указан на сайте. Он полностью соответствует тому, что вам привезут, поскольку повара ориентируются ровно на ту же самую рецептуру. Мы внимательно следим за качеством блюд и красотой их внешнего вида. Убедитесь сами!</p>',
        },
        {
          name: 'Пицца в Сертолово',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Сертолово',
          metaTitle: 'Доставка пиццы в Сертолово — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Сертолово. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Пицца с доставкой в Сертолово &ndash; это то, что нужно для ленивого выходного или тихого вечера после работы и учебы. А может, и для посиделок с друзьями? Для обеденного перерыва в офисе? Пицца уместна всегда, особенно когда это любимая пицца. Время ожидания курьера и минимальную сумму заказа смотрите на карте или уточняйте непосредственно у наших лисов-операторов по телефону.</p>',
          textBottom: '<h2>Как заказать пиццу в Сертолово</h2><p>Заказать пиццу с доставкой в Сертолово, казалось бы, несложно &ndash; нужно просто выбрать любимую и оставить заявку на сайте или по телефону. Но что делать, когда в меню постоянно появляются новые блюда, когда шеф-повар создает идеальные сочетания вкусов начинки, теста и соусов, когда внимания заслуживает каждая? Конечно же, пробовать! Пробовать, наслаждаться, заказывать снова!</p><p>В приготовлении пицц мы используем традиционное тесто, которое отлично удерживает начинку и при этом не перебивает ее вкус. Зато его оттеняют соусы нашего собственного приготовления: нежный сливочный бешамель, пряные томатный или цезарь, изысканный жюльен со свежими грибами.</p><p>Точный состав блюд смотрите на сайте. Также указаны калорийность за порцию, вес, размер. После приготовления пицца проходит обязательный контроль качества со стороны су-шефа.</p>',
        },
      ],

    },
    {
      id: 1,
      name: 'Парголово',
      slug: 'pargolovo',
      type: RegionType.City,

      metaTitle: 'Доставка еды в Парголово — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Парголово. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Парголово',
      textTop: '<p>СУШИФОКС &ndash; быстрая доставка вкусной еды в Парголово, а также за КАД. Мы привезем вам суши и пиццу, горячее и десерты, напитки и закуски &ndash; просто сделайте заказ! Все блюда проходят контроль качества и приготовлены профессиональными поварами по проверенной рецептуре из свежайших продуктов.</p>',
      textBottom: '<h2>Свежая еда с доставкой в Парголово</h2><p>Заказ доставки еды &ndash; это уже не роскошь, а привычные реалии жизни, в которой нужно найти место не только работе и заботам, но и удовольствию. Зачем вставать к плите, когда можно просто выбрать что-нибудь на сайте, а потом все это, только что из-под ножа, приедет к вам в термосумке красиво и удобно упакованным с соблюдением технологии перевозки? Пицца будет горячей, салат будет холодным, а не наоборот.</p><p>Чтобы заказать еду в Парголово, нужно для начала определиться с тем, что сегодня вы хотите виеть у себя на столе. Может быть, что-то традиционное? Пиццу, ролл Филедальфия, мисо-суп, ВОК с курицей? А может быть, вам захочется внести остринку в свою жизнь, и тогда вам понравятся острые роллы и пицца с халапеньо? А может, вы хотите сладкого, и тогда для вас приготовят фруктовый ролл, морс и сырники.</p><p>Для каждого блюда в меню указаны состав, калорийность (за порцию), стоимость. Фотография соответствует подаче.</p>',

      categories: [
        {
          name: 'Суши в Парголово',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Парголово',
          metaTitle: 'Доставка суши и роллов в Парголово — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Парголово. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>СУШИФОКС &ndash; это доставка суши и роллов в Парголово и окрестности. Мы привозим холодные и запеченные, сладкие и острые роллы с лососем, креветкой, крабом, угрем и тунцом, используем только свежесваренный рис с заправкой по собственной уникальной рецептуре, который подчеркивает вкус начинки. Повара работают только со свежими продуктами от проверенных поставщиков!</p>',
          textBottom: '<h2>Быстрая доставка суши в Парголово</h2><p>Как заказать доставку в Парголово? Нужно просто сделать выбор и оставить заявку через сайт или по телефону. Ничего сложного, не так ли?</p><p>Вот только как быстро сделать выбор, если в меню есть и классические роллы, и сливочные, и запеченные, и с множеством ингредиентов, и каждый из них так и хочется попробовать? Остается ориентироваться на пожелания своей души именно сегодня, именно в тот момент, когда курсор касается фотографии блюда. А еще можно заказывать половинки порции, и тогда получится попробовать больше!</p><p>Для тех же, кто постоянен в своих предпочтениях, предусмотрена фильтрация по способу приготовления и по начинке. Также вы можете повторять свои заказы и не тратить время на повторный сбор блюд.</p><p>Время ожидания заказа и минимальную его сумму смотрите на карте или уточняйте у наших лисов-операторов по телефону. Фотографии блюд в меню, а также информация о весе и составе соответствуют фактическим.</p>',
        },
        {
          name: 'Пицца в Парголово',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Парголово',
          metaTitle: 'Доставка пиццы в Парголово — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Парголово. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Вкусная, горячая, ароматная пицца с доставкой в Парголово &ndash; разве это не прекрасно само по себе? Особенно когда она приготовлена профессионалами из свежайших продуктов, ее привез курьер, а посуду потом не надо мыть! Точное время ожидания и минимальную сумму смотрите на карте на этой странице &ndash; или вам ее сообщи наш лис-оператор при подтверждении заказа по телефону.</p>',
          textBottom: '<h2>Горячая пицца в Парголово</h2><p>Просто представьте: вам не нужно никуда идти, вам даже не нужно смотреть, что есть в холодильнике, не говоря уж о том, чтобы вставать к плите. Вам даже не нужно мыть посуду&hellip; надо только выбрать пиццу и встретить курьера, и все, больше совершенно ничего! Разве это не прекрасно? Праздник лени, праздник радости жизни среди бесконечной круговерти бесчисленных дел. Время, потраченное на себя и любимых, а не на рутину.</p><p>Чтобы заказать доставку пиццы в Парголово, оставьте заявку на сайте или у оператора по телефону, и вот уже повара встают к печи, и вот уже она упакована в термосумку, и вот она едет к вам: такая манящая, такая вкусная!</p><p>В нашем меню есть пиццы на любой вкус &ndash; с пикантными колбасками пепперони, с грибами, с острыми перчиками халапеньо, с разными видами сыров, с курицей и мясом, с овощами и зеленью. Чтобы было проще искать, мы предусмотрели подборки по ингредиентам.</p>',
        },
      ],

    },
    {
      id: 1,
      name: 'Бугры',
      slug: 'bugry',
      type: RegionType.City,

      metaTitle: 'Доставка еды в Буграх — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Буграх. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Буграх',
      textTop: '',
      textBottom: '',

      categories: [
        {
          name: 'Суши в Буграх',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Буграх',
          metaTitle: 'Доставка суши и роллов в Буграх — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Буграх. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '',
          textBottom: '',
        },
        {
          name: 'Пицца в Буграх',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Буграх',
          metaTitle: 'Доставка пиццы в Буграх — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Буграх. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '',
          textBottom: '',
        },
      ],

    },
    {
      id: 1,
      name: 'Юкки',
      slug: 'yukki',
      type: RegionType.City,

      metaTitle: 'Доставка еды в Юкки — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Юкки. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Юкки',
      textTop: '',
      textBottom: '',

      categories: [
        {
          name: 'Суши в Юкки',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Юкки',
          metaTitle: 'Доставка суши и роллов в Юкки — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Юкки. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '',
          textBottom: '',
        },
        {
          name: 'Пицца в Юкки',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Юкки',
          metaTitle: 'Доставка пиццы в Юкки — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Юкки. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '',
          textBottom: '',
        },
      ],

    },
    {
      id: 1,
      name: 'Новая охта',
      slug: 'novaya-ohta',
      type: RegionType.City,

      metaTitle: 'Доставка еды в Новую охту — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Новую охту. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Новой охте',
      textTop: '',
      textBottom: '',

      categories: [
        {
          name: 'Суши и роллы в Новой охте',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Новой охте',
          metaTitle: 'Доставка суши и роллов в Новую охту — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Новую охту. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '',
          textBottom: '',
        },
        {
          name: 'Пицца в Новой охте',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Новую охту',
          metaTitle: 'Доставка пиццы в Новую охту — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Новую охту. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '',
          textBottom: '',
        },
      ],

    },
    {
      id: 1,
      name: 'Новый Оккервиль',
      slug: 'novyj-okkervil',
      type: RegionType.City,

      metaTitle: 'Доставка еды в Новый Оккервиль — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Новый Оккервиль. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Новом Оккервиле',
      textTop: '',
      textBottom: '',

      categories: [
        {
          name: 'Суши в Новом Оккервиле',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Новом Оккервиле',
          metaTitle: 'Доставка суши и роллов в Новый Оккервиль — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Новый Оккервиль. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '',
          textBottom: '',
        },
        {
          name: 'Пицца в Новом Оккервиле',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Новый Оккервиль',
          metaTitle: 'Доставка пиццы в Новый Оккервиль — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Новый Оккервиль. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '',
          textBottom: '',
        },
      ],

    },
    {
      id: 1,
      name: 'Приморский район',
      slug: 'primorskij-rajon',
      type: RegionType.District,

      metaTitle: 'Доставка еды в Приморском районе — заказать еду на дом и в офис | СУШИСЕТ',
      metaDescription: 'Заказывайте быструю доставку вкусной еды японской и итальянской кухни домой или в офис в Приморском районе. У нас всегда ВКУСНО & БЫСТРО!',

      h1: 'Доставка еды в Приморском районе',
      textTop: '<p>Оперативная доставка еды на дом в Приморском районе Санкт-Петербурга, разнообразные акции и спецпредложения, бонусная программа для постоянных клиентов, жесткий контроль качества блюд и работы курьера &ndash; все это СУШИФОКС. Мы доставляем еду не только в район станций &laquo;Комендантский проспект&raquo;, &laquo;Пионерская&raquo;, &laquo;Беговая&raquo;, &laquo;Озерки&raquo;, но также в Юнтолово, Ольгино, Лахту и Лисий Нос.</p>',
      textBottom: '<h2>Как заказать еду на дом</h2><p>Чтобы заказать доставку еды, нужно определиться с тем, что пожелает душа и тело в этот чудесный день. В нашем меню: горячие ВОКи, супы и закуски, легкие салаты, вкуснейшие роллы и пицца, сладкие десерты с фруктами, свежесваренные морсы собственного приготовления. Все приготовлено из свежих продуктов от проверенных поставщиков, поэтому буквально тает во рту. Не всем нашим клиентам удается сфотографировать заказ до того, как он будет подчистую съеден!</p><p>Чтобы рыба, мясо и овощи сохраняли свой вкус, мы тщательно следим за правильностью соблюдения условий хранения и обработки продуктов. На кухне также соблюдаются санитарно-гигиенические нормы, проводятся регулярные проверки.</p><p>Минимальную сумму заказа и приблизительное время ожидания для вашего адреса вы можете посмотреть на карте прямо на сайте или уточнить у Лиса-оператора по телефону. Доступен функционал предзаказа к определенному часу и дню.</p>',

      categories: [
        {
          name: 'Суши в Приморском районе',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Суши и роллы в Приморском районе',
          metaTitle: 'Доставка суши и роллов в Приморском районе — заказать суши и роллы на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкусных суши и роллов домой или в офис в Приморском районе. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>СУШИФОКС &ndash; это удобная и быстрая доставка суши и роллов в Приморском районе Санкт-Петербурга (проспект Энгельса, Богатырский, Комендантский, Коломенский, улица Оптиков, Долгоозерная, Вербная, Приморское шоссе, а также Лахта, Ольгино, Юнтолово). Посмотреть, в какую зону доставки попадает ваш адрес и какие для этой зоны минимальная сумма заказа и приблизительное время ожидания можно на карте.</p>',
          textBottom: '<h2>Доставка свежих суши в Приморском</h2><p>Приморский район &ndash; один из самых крупных в Санкт-Петербурге. Кухня доставки роллов СУШИФОКС расположена в самом его сердце, поэтому наши курьеры успеют в срок и всегда. К тому же, вы можете забрать свой заказ прямо в пункте самовывоза. Часы работы пункта такие же, как и для доставки.</p><p>Заказать суши и роллы с доставкой можно по телефону и через сайт. При заказе через сайт воспользуйтесь корзиной. Цены, калорийность, вес блюд в меню соответствуют истине и актуальны. Если вы хотите получить консультацию, то позвоните по телефону, и наши Лисицы-операторы ответят на все вопросы о составе, особенностях приготовления роллов, порекомендуют блюда по вашему запросу и оформят заказ.</p><p>Доступна оплата картой и наличными. Действует сервис предзаказа к определенному дню и часу.</p>',
        },
        {
          name: 'Пицца в Приморском районе',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Приморском районе',
          metaTitle: 'Доставка пиццы в Приморском районе — заказать горячую пиццу на дом и в офис | СУШИСЕТ',
          metaDescription: 'Заказывайте быструю доставку вкуснейшей пиццы домой или в офис в Приморском районе. У нас всегда ВКУСНО & БЫСТРО!',

          textTop: '<p>Нужна срочная доставка в Приморский район Санкт-Петербурга? Пицца от СУШИФОКС уже выезжает. Сочная, свежая, горячая, на хрустящем тесте, с расплавленным сыром поверх фирменного соуса, она украсит собой и праздники, и будни. Можно и не ждать курьера, можно получить свою пиццу прямо в пункте самовывоза. Адрес пункта и условия доставки смотрите на сайте или уточняйте непосредственно у Лисиц-операторов по телефону.</p>',
          textBottom: '<h2>Вкусная пицца в Приморском районе</h2><p>Заказать пиццу с доставкой в Приморский район очень просто, нужно только выбрать подходящую (или комбо) и либо позвонить по телефону, или собрать заказа на сайте и в мобильном приложении. Для каждой пиццы указаны ее размер, вес, состав, калорийность (в расчете за все блюдо). Это же касается и комбо-наборов с пиццами.</p><p>Обратите внимание, что пиццу можно украсить сырным бортиком! В него входят сыры моцарелла и гауда, и они не только дополняют начинку, но и имеют прекрасный собственный вкус! Сырный бортик можно добавить к любой пицце.</p><p>Специально для удобства наших клиентов мы сделали подборки пицц по ингредиентам (без грибов, без лука и др.). Если у вас есть иные предпочтения, назовите их Лису-оператору, и он подберет пиццу с нужным составом.</p>',
        },
      ],

    },

  ], */
  allRegionsDist: [
    {
      id: 1,
      name: 'Фрунзенский район',
      slug: 'frunzenskiy-rayon',
      type: RegionType.District,

      metaTitle: '',
      metaDescription: '',

      h1: 'Доставка еды в Фрунзенском районе',
      textTop: '',
      textBottom: '',

      categories: [
        {
          name: 'Суши в Фрунзенском районе',
          slug: 'sushi',

          collection: 'regions-sushi',
          showMoreLink: '/menu/rolls',

          h1: 'Доставка суши в Фрунзенском районе',
          metaTitle: '',
          metaDescription: '',

          textTop: '',
          textBottom: '',
        },
        {
          name: 'Пицца в Фрунзенском районе',
          slug: 'pizza',

          collection: 'regions-pizza',
          showMoreLink: '/menu/pizza',

          h1: 'Доставка пиццы в Фрунзенском районе',
          metaTitle: '',
          metaDescription: '',

          textTop: '',
          textBottom: '',
        },
      ],

    },
  ],

})

const getters = {
  getRegionsByType: state => (type) => {
    return state.allRegions.filter(region => region.type === type)
  },

  getDistricts: (state, getters) => getters.getRegionsByType(RegionType.District),
  getCities: (state, getters) => getters.getRegionsByType(RegionType.City),

  getRegionBySlug: state => (slug) => {
    return _.find(state.allRegions, { slug })
  },

  getRegionalCategoryBySlug: (state, getters) => (regionSlug, categorySlug) => {
    const region = getters.getRegionBySlug(regionSlug)
    if (!region || !region.categories) {
      return null
    }
    const category = _.find(region.categories, { 'slug': categorySlug })
    if (category) {
      category.region = region
      return category
    }
  },
}

const mutations = {}

const actions = {
  getRegionBySlug ({ getters }, slug) {
    return new Promise(function (resolve, reject) {
      const region = getters.getRegionBySlug(slug)
      if (region) {
        resolve(region)
      } else {
        reject(new Error('Region not found'))
      }
    })
  },

  getRegionalCategoryBySlug ({ getters, dispatch }, { regionSlug, categorySlug }) {
    return new Promise(function (resolve, reject) {
      const category = getters.getRegionalCategoryBySlug(regionSlug, categorySlug)
      if (category) {
        category.menuItems = []
        dispatch('menu/fetchCollections', { target: category.collection, type: 'desktop' }, { root: true })
          .then((res) => {
            category.menuItems = res.items[0].menuItems
            resolve(category)
          })
          .catch(() => {
            resolve(category)
          })
      } else {
        reject(new Error('Regional category not found'))
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

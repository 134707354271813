<template>
  <nav class="header_top_navigation">
    <template v-for="(item, i) in navigation">
      <nuxt-link
        v-if="!item.ext"
        :to="item.path"
        :key="i"
        class="header_top_navigation_item"
        tag="a"
        no-prefetch
      >
        {{ item.title }}
        <span v-show="item.new">{{ item.new }}</span>
      </nuxt-link>
      <a
        v-else
        :href="item.path"
        :key="i"
        target="_blank"
        class="header_top_navigation_item"
        no-prefetch
      >
        {{ item.title }}
      </a>
    </template>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      navigation: [
        { title: 'акции', path: '/promos', new: null, ext: false },
        { title: 'доставка и оплата', path: '/delivery', new: null, ext: false },
        { title: 'тайный покупатель', path: 'https://setcontrol.ru', new: null, ext: true },
        { title: 'франшиза', path: 'https://fr.setsushi.ru/', new: null, ext: true },
        { title: 'новости', path: '/news', new: null, ext: false },
        { title: 'Контакты', path: '/contacts', new: null, ext: false },
      ],
    }
  },
  created () {
    if (process.client) {
      this.getPromoCount()
    }
  },
  methods: {
    getPromoCount () {
      const ht_cache = localStorage.getItem('ht_promo_cache')
      const promoNavItem = this.navigation[0]

      /* Если кэш есть */
      if (ht_cache) {
        const ht_cache_parsed = ht_cache ? JSON.parse(ht_cache) : 0

        /* Если лайфтайм еще не истек */
        if (ht_cache_parsed.expired_in > this.$moment().unix()) {
          promoNavItem.new = ht_cache_parsed.length
          console.log('promo count from cache')
          this.$set(this.navigation, 0, promoNavItem)
          return true
        }
      }

      /* Если не получили из кеша, достаем из апи */
      this.$store.dispatch('info/fetchPromotions').then((res) => {
        promoNavItem.new = res.items.length
        this.$set(this.navigation, 0, promoNavItem)

        const expiredIn = this.$moment().add(1, 'days').unix()

        localStorage.setItem('ht_promo_cache', JSON.stringify({
          length: res.items.length,
          expired_in: expiredIn,
        }))
      }).catch(e => e)
    },
  },
}
</script>

<style lang="less">
.header_top_navigation {
	display: flex;
	align-items: center;

	&_item {
		font-family: 'Aqum', 'Roboto', Arial, sans-serif;
		display: flex;
		align-items: center;
		font-size: 10px;
		color: #393939;
		text-decoration: none;

		@media screen and (max-width: 992px) { font-size: 11px; }

		&:not(:last-child) {
			margin-right: 30px;

			@media screen and (max-width: 1200px) { margin-right: 30px; }
			@media screen and (max-width: 992px) { margin-right: 15px; }
		}

		span {
			width: 18px;
			height: 18px;
			flex-shrink: 0;
			background-color: #E31E24;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 10px;
			font-family: 'Roboto';
			color: white;
			border-radius: 50%;
			margin-left: 10px;
		}
	}
}
</style>

import Vue from 'vue'
import VeeValidate from 'vee-validate'

import ru from 'vee-validate/dist/locale/ru'

Vue.use(VeeValidate, {})

VeeValidate.Validator.localize('ru', ru)

export default ({ app }, inject) => {
  app.validator = VeeValidate.Validator
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"street-input-component"},[_c('vue-suggest',{ref:"suggestComponent",staticClass:"asdad",attrs:{"destyled":true,"list":_vm.getList,"max-suggestions":30,"min-length":3,"debounce":200,"filter-by-query":false,"controls":{
      selectionUp: [38, 33],
      selectionDown: [40, 34],
      select: [13, 36],
      showList: [40],
      hideList: [27, 35]
    },"mode":_vm.mode,"nullable-select":true,"pattern":"\\w+","placeholder":"Search information...","value-attribute":"value","display-attribute":"value"},on:{"select":_vm.onSuggestSelect,"input":_vm.onInput},scopedSlots:_vm._u([{key:"misc-item-above",fn:function({ suggestions, query }){return [(suggestions.length > 0 && !_vm.loading)?_c('div',{staticClass:"misc-item items-found"},[_c('span',[_vm._v("Выберите одну из улиц в списке")])]):(!_vm.loading)?_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v("Ничего не найдено по запросу \""+_vm._s(query)+"\"")])]):_vm._e()]}},{key:"suggestion-item",fn:function(scope){return _c('div',{},[_c('div',{staticClass:"suggestion-item__text"},[_c('div',{staticClass:"area"},[_vm._v("\n          "+_vm._s(_vm.getCity(scope))+"\n        ")]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.boldenSuggestion(scope))}})])])}},{key:"misc-item-below",fn:function({ suggestions }){return (_vm.loading)?_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v("Поиск...")])]):_vm._e()}}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('div',{staticClass:"query"},[_c('input',{staticClass:"input-text_input",attrs:{"placeholder":_vm.placeholder,"type":"text"}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
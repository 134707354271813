<template>
  <div class="order-type-map">
    <div v-show="withZone" class="order-type-map">
      <yandex-map id="map-delivery" :coords="center" :zoom="zoom" :controls="['rulerControl', 'zoomControl', 'geolocationControl']">
        <ymap-marker
          :icon="icon"
          :coords="coords?.length ? coords : [0, 0]"
          marker-id="ymap-marker-1"
          marker-type="Placemark"
        />
        <ymap-marker
          v-for="(p) in polygons"
          :marker-id="p.id"
          :marker-stroke="p.strokeOptions"
          :marker-fill="p.fillOptions"
          :coords="p.area"
          :key="p.id"
          :balloon-template="getBallonTemplete(p)"
          marker-type="Polygon"
        />
      </yandex-map>
    </div>
    <div v-show="!withZone" class="order-type-map">
      <yandex-map id="map-self" :coords="center" :zoom="zoom" :controls="['rulerControl', 'zoomControl', 'geolocationControl']">
        <ymap-marker
          :icon="icon"
          :coords="coords?.length ? coords : [0, 0]"
          marker-id="ymap-marker-2"
          marker-type="Placemark"
        />
      </yandex-map>
    </div>
  </div>
</template>

<script>
export default {
  props: ['coords', 'withZone'],
  data () {
    return {
      center: [55.755819, 37.617644],
      zoom: 10,
      icon: {
        layout: 'default#image',
        imageHref: '/deliveryplacemark.png',
        imageSize: [96, 96],
        imageOffset: [-47, -80],
      },
      polygons: [],
    }
  },
  watch: {
    coords (val) {
      if (val?.length) {
        this.center = this.coords
      }
    },
  },
  async fetch () {
    await this.loadMapData()
  },
  methods: {
    loadMapData () {
      this.loading = true
      return this.$store.dispatch('geo/fetchDeliveryMap')
        .then((res) => {
          this.polygons = res.polygons.map((v) => {
            return {
              id: v.id,
              name: v.name,
              // filial: v.filial,
              area: [v.area],
              fillOptions: {
                enabled: true,
                color: v.color || '#' + Math.random().toString(16).slice(3, 9),
                opacity: 0.7,
              },
              strokeOptions: {
                color: v.color || '#' + Math.random().toString(16).slice(3, 9),
                opacity: 0.1,
                width: 1,
              },
            }
          })
          this.center = res.center
          this.zoom = res.zoom || 10
        })
        .finally(() => { this.loading = false })
    },
    getBallonTemplete (p) {
      return '<p>' + p.name + ' (' + p.id + ')</p>'
    },
  },
}
</script>

<style lang="less">
.order-type-map {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;

  .ymap-container {
    width: 100%;
    height: 100%;

    & > * {
      width: 100% !important;

      & > ymaps {
        width: 100% !important;

        & > ymaps {
          width: 100% !important;
        }
      }
    }
  }

  @media (max-width: 620px) {
    display: none;
  }
}

</style>

import { DateTime, Settings, IANAZone, SystemZone } from 'luxon'
import moment from 'moment-timezone'

export const state = () => ({
  isMenu: false,
  lockHeaderFloating: false,
  activeHeaderItem: '',
  clearanceSteps: [
    { name: 'Авторизация', active: false },
    { name: 'Оформление заказа', active: false },
    { name: 'Заказ принят', active: false },
  ],
  loading: false,
})

export const mutations = {
  setLockHeaderFloating (state, payload) {
    state.lockHeaderFloating = payload
  },
  changeMenu (state, name) {
    if (name === 'menu') {
      state.isMenu = true
    } else {
      state.isMenu = false
    }
  },
  changeActiveHeaderItem (state, name) {
    state.activeHeaderItem = name
  },
  setClearanceSteps (state, index) {
    state.clearanceSteps.forEach((v, i) => {
      v.active = false
      if (index === i) {
        v.active = true
      }
    })
  },
  setLoading (state, val) {
    state.loading = val
  },
}

export const getters = {
  getLockHeaderFloating (state) {
    return state.lockHeaderFloating
  },
  getIsMenu (state) {
    return state.isMenu
  },
  getActiveHeaderItem (state) {
    return state.activeHeaderItem
  },
  getClearanceSteps (state) {
    return state.clearanceSteps
  },
  getLoading (state) {
    return state.loading
  },
}

export const actions = {
  async nuxtClientInit ({ commit, dispatch }, context) {
    /** Инициализация объекта user и проверка авторизации */
    await initAuthUser(dispatch, commit, context)
    await initBasket(dispatch, commit, context)

    /* if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'))
      commit('auth/loginSuccess', user)
    } */
    dispatch('setGlobalTimezone')
  },
  nuxtServerInit ({ dispatch, state }, { error, req, $config, $region, route }) {
    setStoreApiBaseUrl(state, $config.baseApiUrl)

    return $region.init(req)
      .then(() => {
        return Promise.all([
          dispatch('seo/fetchPageMeta', {
            pageUrl: route.fullPath,
            regionId: $region.getCurrentRegionId(),
          }),
          dispatch('menu/fetchTopMenuCategories'),
        ])

        // dispatch('setGlobalTimezone')
      })
  },

  setGlobalTimezone ({ getters }) {
    let timeZone = getters['region/getRegionField']('timeZone') || 'Europe/Moscow'

    if (timeZone === 'UTC+3') {
      timeZone = 'Europe/Moscow'
    }
    const regionName = getters['region/getCurRegionName']

    console.log(DateTime.now())
    Settings.defaultZone = timeZone

    console.log(IANAZone.create(timeZone))
    console.log(new SystemZone())

    console.log('=== SET FILIAL TIMEZONE =====>', regionName, timeZone, '>>', DateTime.local().zoneName, DateTime.local(), DateTime.now().toFormat('HH:mm'), '<======')

    const moment = require('moment-timezone')
    moment.tz.setDefault(timeZone)
  },

}

function setStoreApiBaseUrl (state, baseUrl) {
  state.api.api = `${baseUrl}/web`
}

async function initAuthUser (dispatch, commit, context) {
  console.log('initAuthUser', context.$cookies.get('token'))
  if (context.$cookies.get('token')) {
    try {
      const user = await dispatch('auth/me')
      commit('auth/loginSuccess', user)
    } catch (e) {
      console.log(e)
    }
  }
}

async function initBasket (dispatch, commit, { $cookies, $router }) {
  const cookieFilialId = $cookies.get('filial_id')

  try {
    const res = await dispatch('cart/fetchCart')
    const filialId = res?.data?.filial?.id
    const basketId = res?.data?.id

    console.table({ cookieFilialId, filialId, basketId })

    if (filialId && cookieFilialId !== filialId) {
      $cookies.set('filial_id', filialId)
      window.location.reload()
    }
  } catch (e) {
    console.log('error sync basket filial')
    console.log(e)
  }
}

<template>
  <div class="footer_bottom">
    <div class="container">
      <div class="row">
        <div class="col-6 col-md-4">
          <FooterCards />
        </div>
        <div class="col-12 col-md-4">
          <FooterBanks />
        </div>
        <div class="col-6 col-md-4">
          <FooterCopyright />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FooterCards from './FooterCards'
import FooterCopyright from './FooterCopyright'
import FooterBanks from './FooterBanks'

export default {
  components: { FooterCards, FooterCopyright, FooterBanks },
}
</script>

<style lang="less">
.footer_bottom {
	height: 63px;
	display: flex;
	align-items: center;
	background-color: black;

	@media screen and (max-width: 768px) {
		min-height: 68px;
		height: auto;
		padding: 15px 0;

		.row { flex-direction: row-reverse; }
	}

	div[class^="col"] {
		&:nth-child(2) {
			@media screen and (max-width: 768px) {
				order: 1;
				margin-top: 15px;
			}
		}

		&:nth-child(1) {
			@media screen and (max-width: 768px) {
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}
</style>

const state = () => ({
  isDev: process.env.PORT == 3001,
  requisites: '/docs/requisites_sushiset.pdf', // Ссылка на файл с реквизитами

  topMenuCategories: [
    { path: '/menu/rolls', icon: '/header-menu/2/rolls.png', name: 'Роллы', id: 'rolls', active: false },
    { path: '/menu/baked-rolls', icon: '/header-menu/2/baked-rolls.png', name: 'Запеченные', id: 'baked-rolls', active: false },
    { path: '/menu/sets', icon: '/header-menu/2/sets-combo.png', name: 'Сеты', id: 'sets', active: false },
    { path: '/menu/sushi', icon: '/header-menu/2/sushi.png', name: 'Cуши', id: 'sushi', active: false },
    { path: '/menu/snacks', icon: '/header-menu/2/salads.png', name: 'Салаты и закуски', id: 'snacks', active: false },
    // { path: '/menu/poke', icon: '/header-menu/poke.png', name: 'Поке', id: 'poke', active: false },
    { path: '/menu/hot', icon: '/header-menu/2/hot.png', name: 'Горячее', id: 'hot', active: false },
    { path: '/menu/drinks', icon: '/header-menu/2/drinks.png', name: 'Напитки', id: 'drinks', active: false },
    { path: '/menu/deserts', icon: '/header-menu/2/desert.png', name: 'Десерты', id: 'deserts', active: false },
    { path: '/menu/sauce', icon: '/header-menu/2/sauce.png', name: 'Соусы', id: 'sauce', active: false },
  ],
})

const getters = {
  isDev (state) {
    return state.isDev
  },
}

const mutations = {}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

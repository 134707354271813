<template>
  <div class="loader-ovescreen">
    <Loader />
  </div>
</template>

<script>
import Loader from './Loader'

export default {
  components: { Loader },
}
</script>

<style lang="less">
.loader-ovescreen {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, .75);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
}
</style>

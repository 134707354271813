<template>
  <div class="header_mobile">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="header_mobile_inner">
            <button
              @click="toggleCollapsedMenu"
              class="header_mobile_button"
            >
              <template v-if="!isCollapsedMenuOpen">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                >
                  <g
                    id="Сгруппировать_2819"
                    data-name="Сгруппировать 2819"
                    transform="translate(811 31)"
                  >
                    <circle
                      id="Эллипс_209"
                      data-name="Эллипс 209"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(-811 -31)"
                      fill="#151515"
                    />
                    <circle
                      id="Эллипс_210"
                      data-name="Эллипс 210"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(-802 -31)"
                      fill="#151515"
                    />
                    <circle
                      id="Эллипс_211"
                      data-name="Эллипс 211"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(-794 -31)"
                      fill="#151515"
                    />
                    <circle
                      id="Эллипс_212"
                      data-name="Эллипс 212"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(-811 -23)"
                      fill="#151515"
                    />
                    <circle
                      id="Эллипс_206"
                      data-name="Эллипс 206"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(-811 -15)"
                      fill="#151515"
                    />
                    <circle
                      id="Эллипс_213"
                      data-name="Эллипс 213"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(-802 -23)"
                      fill="#151515"
                    />
                    <circle
                      id="Эллипс_207"
                      data-name="Эллипс 207"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(-802 -15)"
                      fill="#151515"
                    />
                    <circle
                      id="Эллипс_214"
                      data-name="Эллипс 214"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(-794 -23)"
                      fill="#151515"
                    />
                    <circle
                      id="Эллипс_208"
                      data-name="Эллипс 208"
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      transform="translate(-794 -15)"
                      fill="#151515"
                    />
                  </g>
                </svg>
              </template>
              <template v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.31"
                  height="16.31"
                  viewBox="0 0 16.31 16.31"
                >
                  <g
                    id="Сгруппировать_3045"
                    data-name="Сгруппировать 3045"
                    transform="translate(1095.914 28.396)"
                  >
                    <line
                      id="Линия_101"
                      data-name="Линия 101"
                      x1="13.482"
                      y1="13.482"
                      transform="translate(-1094.5 -26.982)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-width="2"
                    />
                    <line
                      id="Линия_102"
                      data-name="Линия 102"
                      x1="13.482"
                      y2="13.482"
                      transform="translate(-1094.5 -26.982)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </template>
            </button>
            <HeaderMainLogo />
            <button
              @click="
								$nuxt.$emit('close-mobile-menu')
								$router.push('/cart')
							"
              class="header_mobile_button"
            >
              <span class="cart-length">{{ getCartLength }}</span>
              <font-awesome-icon
                :icon="['fas', 'shopping-cart']"
              />
            </button>
          </div>
        </div>
        <confirm-region-popup />
      </div>
    </div>
    <transition name="slide">
      <HeaderMobileCollapse
        v-show="isCollapsedMenuOpen"
        @close="toggleCollapsedMenu"
      />
    </transition>
  </div>
</template>

<script>
import HeaderMainLogo from './HeaderMainLogo'
import HeaderMobileCollapse from './HeaderMobileCollapse'
import ConfirmRegionPopup from '~/components/regions/ConfirmRegionPopup'

export default {
  components: { ConfirmRegionPopup, HeaderMainLogo, HeaderMobileCollapse },
  data () {
    return {
      isCollapsedMenuOpen: false,
      items: [],
      total: 0,
    }
  },
  computed: {
    getCartLength () {
      return this.$store.getters['cart/getCartLength']
    },
  },
  created () {
    if (process.client) {
      this.$store.dispatch('cart/fetchCart').catch(() => false)
    }
  },
  mounted () {
    this.$nuxt.$on('close-mobile-menu', () => {
      this.isCollapsedMenuOpen = false
    })
  },
  methods: {
    toggleCollapsedMenu () {
      this.isCollapsedMenuOpen = !this.isCollapsedMenuOpen
    },
  },
}
</script>

<style lang="less">
.header_mobile {
  --hmheight: 100px;

  height: var(--hmheight);
  width: 100%;
  display: none;
  align-items: center;
  position: relative;

  @media screen and (max-width: 768px) {
    display: flex;
  }

  .header_collapse {
    height: calc(100vh - var(--hmheight));
  }

  &_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 28px;
    padding-bottom: 23px;
  }

  &_button {
    width: 49px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    font-size: 18px;
    color: black;
    border: none;
    border-radius: 15px;
    position: relative;

    .cart-length {
      position: absolute;
      width: 22px;
      height: 22px;
      font-size: 13.5px;
      vertical-align: middle;
      color: #fff;
      line-height: 1.6;
      font-weight: 700;
      text-align: center;
      border-radius: 50%;
      top: -5px;
      right: 0;
      background: @color-primary;
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: left 0.5s;
}

.slide-enter,
.slide-leave-to {
  left: -100%;
}
</style>

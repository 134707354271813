const state = () => ({
  metaTitle: '',
  metaDescription: '',
  pageTitle: '',
  text: '',

  metaTitleDefault: '',
  metaDescriptionDefault: '',
  pageTitleDefault: '',
})

const getters = {
  getMetaTitle: state => state.metaTitle || state.metaTitleDefault,
  getMetaDescription: state => state.metaDescription || state.metaDescriptionDefault,
  getPageTitle: state => state.pageTitle || state.pageTitleDefault,
  getText: state => state.text,
}

const mutations = {
  setMetaFields (state, data) {
    state.metaTitle = data.metaTitle ?? ''
    state.metaDescription = data.metaDescription ?? ''
    state.pageTitle = data.pageTitle ?? ''
    state.text = data.text ?? ''
  },

  setMetaFieldsDefaults (state, data) {
    state.metaTitleDefault = data.metaTitle ?? ''
    state.metaDescriptionDefault = data.metaDescription ?? ''
    state.pageTitleDefault = data.pageTitle ?? ''
  },

  clearMetaTitle () {
    state.pageTitle = ''
  },

}

const actions = {
  fetchPageMeta ({ commit, rootGetters, dispatch }, { pageUrl, regionId }) {
    commit('clearMetaTitle')
    return this.$axios.get(rootGetters['api/getPageMeta'], {
      params: {
        pageUrl,
        regionId,
      },
    })
      .then((res) => {
        commit('setMetaFields', res.data.data)
        return res.data.data
      })
      .catch(() => commit('setMetaFields', []))
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <div class="footer_banks">
    <img src="/banks/mir.png" alt="" class="footer_banks_icon">
    <img src="/banks/verifiedvisalight.png" alt="" class="footer_banks_icon">
    <img src="/banks/mastercard.png" alt="" class="footer_banks_icon">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
.footer_banks {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&_icon {
		height: 20px;
		width: auto;

		&:not(:last-child) { margin-right: 15px; }
	}
}
</style>

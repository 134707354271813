export class Cookie {
  get (name) {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`),
    )
    return matches ? decodeURIComponent(matches[1]) : undefined
  }

  set (name, value, days, path, domain) {
    const dir = path || '/'
    let expires
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = `; expires=${date.toUTCString()}`
    } else {
      expires = ''
    }
    document.cookie = `${name}=${value}${expires}; path=${dir}` + ((domain) ? '; domain=' + domain : '')

    return null
  }

  delete (name) {
    this.set(name, '', -1)
  }
}

export const cookie = new Cookie()

const state = () => ({

})

const getters = {}

const mutations = {}

const actions = {
  phoneClick () {
    console.log('--EVENT--> phoneClick')
    if (window.ym) {
      console.log('--EVENT send to ym--> phoneClick')
      window.ym(85385488, 'reachGoal', 'PHONE')
    }
  },

  newOrderCreated (context, data) {
    console.log('--EVENT--> newOrderCreated')
    console.log(data)

    window.dataLayer = window.dataLayer || []

    if (data.id && data.basket) {
      const basketItems = data?.basket?.items || []
      const cartProducts = basketItems.map(item => ({
        'id': item.menuItem.id,
        'name': item.menuItem.name,
        'price': item.price,
        'quantity': 1,
      }))

      window.dataLayer.push({
        'event': 'transaction',
        'ecommerce': {
          'currencyCode': 'RUB',
          'purchase': {
            'actionField': {
              'id': data.id,
              'coupon': data?.basket?.promoCode?.promoCode?.code || '',
              'revenue': data.totalPrice,
              'goal_id': 283503714, //  цель ORDER
            },
            'products': cartProducts,
          },
        },
      })

      console.log(window.dataLayer)
    }

    if (window && window.ym) {
      console.log('--EVENT send to ym--> newOrderCreated')
      window.ym(85385488, 'reachGoal', 'ORDER', {
        order_price: data.totalPrice,
        currency: 'RUB',
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import { getUtmSource } from '~/utils/getUtmSource'

const DEFAULT_NUMBER = '+7 (495) 150 55 22'
const MARKETING_NUMBERS = {
  vk: null,
  instagram: null,
  yandex: null,
  google: null,
}

export function getPhoneByUtm () {
  if (process.client) {
    const utmSource = getUtmSource()

    if (utmSource && MARKETING_NUMBERS[utmSource]) {
      return MARKETING_NUMBERS[utmSource]
    }
  }

  return DEFAULT_NUMBER
}

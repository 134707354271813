<template>
  <div
    :class="{ active: isActive, last: destination.isLast }"
    @click="$emit('changeAddressIndex', [index, 'delivery'])"
    class="delivery-item"
  >
    <div class="delivery-item_main">
      <div class="delivery-item_icon">
        <font-awesome-icon
          :icon="['fas', getAddressIcon(destination.icoName)]"
        />
      </div>
      <div>
        <p class="delivery-item_address">
          {{ destination.shortAddress }}
        </p>
        <div v-if="isActive" class="delivery-item_btn active">
          Выбрано
        </div>
        <div v-else class="delivery-item_btn">
          Выбрать
        </div>
      </div>
    </div>
    <div v-if="destination.isLast" :class="['delivery-item_label', { active: isActive }]">
      Прошлый адрес доставки
    </div>
  </div>
</template>

<script>
export default {
  props: {
    destination: {
      type: Object,
      default: () => ({}),
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {

    }
  },
  methods: {
    // async changeAddress () {
    //   this.isLoading = true
    //   this.$store.commit('clearance/setIsLoading', true)
    //   this.$emit('setActiveDestinationId', this.destination.id)
    //
    //   this.$store
    //     .dispatch('cart/changeCourier', {
    //       clientAddressId: this.destination.id,
    //     })
    //     .then((res) => {
    //       this.$emit('setSufficientForDelivery', res.data.freeDelivery)
    //
    //       this.$emit('address-changed', {
    //         address: `${res.data.clientAddress.street}, ${res.data.clientAddress.house}, ${res.data.clientAddress.apartmentNumber}`,
    //         deliveryCost: res.data.deliveryPrice,
    //       })
    //     })
    //     .catch((err) => {
    //       this.$toast.error(err?.response?.data?.message, {
    //         duration: 3000,
    //       })
    //       this.$emit('setActiveDestinationId', null)
    //     })
    //     .finally(() => {
    //       this.$store.commit('clearance/setIsLoading', false)
    //       this.isLoading = false
    //     })
    // },
    getAddressIcon (iconName) {
      return this.$store.getters['address/getAddressIconByName'](iconName)
    },
  },
}
</script>

<style lang="less">
.delivery-item {
  width: 100%;
  min-height: 95px;
  display: flex;
  flex-shrink: 0;
  padding: 17px 12px 16px;
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  position: relative;
  cursor: pointer;

  &.active {
    border: 1px solid #e31e24;

    &.last {
      background-color: rgba(227, 30, 36, 0.1);
    }
  }

  &_label {
    padding: 0 5px;
    position: absolute;
    top: 0;
    right: 20px;
    transform: translateY(-50%);
    border-radius: 5px;
    border: 1px solid #dfdfdf;
    background-color: #dfdfdf;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #8d8d8d;

    &.active {
      border: 1px solid #e31e24;
      background-color: #e31e24;
      color: #fff;
    }
  }

  &_main {
    display: flex;
    gap: 12px;
  }

  &_icon {
    margin-top: 2px;
    width: 16px;
    height: 13px;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;
      font-size: 14px;
      color: @color-primary;
    }
  }

  &_address {
    margin-bottom: 14px;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: #000;
  }

  &_btn {
    padding: 8px 10px;
    width: 87px;
    background: #e9e9e9;
    border-radius: 9px;
    font-size: 11.12px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    color: #8d8d8d;

    &.active {
      background-color: #ffc9ca;
      color: #e31e24;
    }
  }
}
</style>

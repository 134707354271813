<template>
  <div class="modal-restrict-orders-component">
    <!--		<client-only>
		<Modal :open="open && !isLoggedIn" @close="close" initialWidth="850px">
			<div class="temporary-modal-content">
				<img src="@/assets/logo.svg" alt="fox">
				<span>По техническим причинам <br> прием заказов через сайт временно недоступен.<br>
          Оформить заказ Вы можете по телефону <a :href="'tel:' + phoneNumber">{{ phoneNumber }}</a>
          <br> Приносим свои извинения.</span>
			</div>
		</Modal>
		</client-only>-->
  </div>
</template>

<script>
import Modal from '~/components/page/Modal/Modal'
import { getPhoneByUtm } from '~/utils/getPhoneByUtm'

export default {
  name: 'ModalRestrictOrders',
  components: {
    Modal,
  },
  props: {
    	open: Boolean,
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters['auth/loggedIn']
    },

	  phoneNumber () {
			  return getPhoneByUtm() || '+7 (495) 150-55-22'
    },
  },
  methods: {
    	close () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="less" scoped>
	.modal-restrict-orders-component {
		text-align: center;
		//margin: 40px 5px 25px 5px;
		display: flex;
		flex-direction: column;
		align-items: center;
		img {
			margin-bottom: 25px;
			max-width: 250px;
		}
		span {
			font-size: 24px;
			line-height: 32px;
			display: block;
		}
	}
</style>

import moment from 'moment'
import { isObject } from 'lodash'
import { DateTime } from 'luxon'
import { isInInterval } from '~/utils/date-time-helper'

const state = () => ({
  workTimeBegin: '10:00',
  workTimeEnd: '23:00',

  startShowNoWorkingModal: {
    1: '23:05',
    2: '23:05',
    3: '23:05',
    4: '23:05',
    5: '23:05',
    6: '23:05',
    7: '23:05',
  },
  startShowMorningModal: '06:00',
  stopShowNoWorkingModal: '09:50',

  startShowWorkingHoursMessageInCart: '00:00',
  stopShowWorkingHoursMessageInCart: '09:50',

  workingModalIsShowed: false,
  newYearModalIsShowed: false,

})

const getters = {
  getVarByDayOfWeek: state => (name) => {
    const dayOfWeek = moment().isoWeekday()
    return isObject(state[name]) ? state[name][dayOfWeek] : state[name]
  },

  showNoWorkingModal: (state, getters) => {
    if (process.server || state.workingModalIsShowed) {
      return false
    }

    const currentTime = DateTime.now()
    const beginTime = DateTime.fromISO(getters.getVarByDayOfWeek('startShowNoWorkingModal'))
    const endTime = DateTime.fromISO(getters.getVarByDayOfWeek('stopShowNoWorkingModal'))

    /* console.log('---- showNoWorkingModal ---')
    console.log(currentTime.toString())
    console.log(beginTime.toString(), endTime.toString())
    console.log(isInInterval(currentTime, beginTime, endTime))
    console.log('----') */

    return isInInterval(currentTime, beginTime, endTime)
  },

  showMorningModal: (state, getters) => {
    if (process.server || state.workingModalIsShowed) {
      return false
    }

    const currentTime = DateTime.now()
    const beginTime = DateTime.fromISO(getters.getVarByDayOfWeek('startShowMorningModal'))
    const endTime = DateTime.fromISO(getters.getVarByDayOfWeek('stopShowNoWorkingModal'))

    return isInInterval(currentTime, beginTime, endTime)
  },

  showNewYearModal: (state) => {
    return !state.newYearModalIsShowed
  },

  showWorkingHoursMessageInCart: (state, getters) => {
    const currentTime = DateTime.now()

    const beginTime = DateTime.fromISO(getters.getVarByDayOfWeek('startShowWorkingHoursMessageInCart'))
    const endTime = DateTime.fromISO(getters.getVarByDayOfWeek('stopShowWorkingHoursMessageInCart'))

    return isInInterval(currentTime, beginTime, endTime)
  },

}

const mutations = {
  hideWorkingHoursModal (state) {
    state.workingModalIsShowed = true
  },
  hideNewYearModal (state) {
    state.newYearModalIsShowed = true
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import axios from 'axios'
import { nanoid } from 'nanoid'
import { getUtmSource } from '~/utils/getUtmSource'
import { getValidationToken } from '~/utils/sendAuthCodeValidation'

function onErrorHandler (error, store, redirect) {
  const originalRequest = error.config

  if (error.response.status === 422) {
    return Promise.reject(error)
  }

  if (
    error.response.status === 401 &&
      originalRequest.url.match(/\/login$/g) !== null
  ) {
    redirect('login')
    return Promise.reject(error)
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true

    /**
       * Пришлось так изъебнуться, так как в method: "patch" не передается url
       * Првоеряем не является ли url refresh'ем
       */
    if (
      error.response.config.method === 'patch' ||
        error.response.config.method === 'put' ||
        error.response.config.method === 'delete' ||
        (error.response.config.url &&
          error.response.config.url.match(/\/refresh/g) ===
          null &&
          error.response.config.url.match(/\/logout/g) === null)
    ) {
      return store.dispatch('auth/refresh')
        .then((res) => {
          return axios(originalRequest)
        })
        .catch(() => {
          store.dispatch('auth/logout')
        })
    }
  }

  return Promise.reject(error)
}

export default function ({ store, redirect, $axios, $region, $cookies }) {
  $axios.onRequest((config) => {
    const utmSource = getUtmSource()

    const localStorageToken = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).token
      : null
    const token = $cookies.get('token') || localStorageToken

    const localStorageUUID = localStorage.getItem('uuid')
    let UUID = $cookies.get('uuid')

    if (!UUID) {
      if (localStorageUUID) {
        UUID = JSON.parse(localStorageUUID)
      } else {
        UUID = nanoid()
      }
      $cookies.set('uuid', UUID)
    }

    const regionId = $region.getCurrentRegionId()
    const filialId = $cookies.get('filial_id')

    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }

    config.params = {
      ...config.params,
      client_device_type: 'web',
      uuid: UUID,
      utm_source: utmSource,
      cur_region_id: regionId,
      cur_filial_id: filialId,
    }

    if (config.url === store.getters['api/authSendCode']) {
      config.headers.token = getValidationToken(config)
    }
  })

  $axios.onError((error) => {
    return onErrorHandler(error, store, redirect)
  })

  axios.interceptors.request.use(
    (config) => {
      const utmSource = getUtmSource()

      const localStorageToken = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user')).token
        : null
      const token = $cookies.get('token') || localStorageToken

      const localStorageUUID = localStorage.getItem('uuid')
      let UUID = $cookies.get('uuid')

      if (!UUID) {
        if (localStorageUUID) {
          UUID = JSON.parse(localStorageUUID)
        } else {
          UUID = nanoid()
        }
        $cookies.set('uuid', UUID)
      }

      const regionId = $region.getCurrentRegionId()
      const filialId = $cookies.get('filial_id')

      if (token) {
        config.headers.Authorization = 'Bearer ' + token
      }

      config.params = {
        ...config.params,
        client_device_type: 'web',
        uuid: UUID,
        utm_source: utmSource,
        cur_region_id: regionId,
        cur_filial_id: filialId,
      }

      if (config.url === store.getters['api/authSendCode']) {
        config.headers.token = getValidationToken(config)
      }

      return config
    },
    (error) => {
      Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    function (error) {
      return onErrorHandler(error, store, redirect)
    },
  )
}

import axios from 'axios'

export default class AuthService {
  constructor (rootGetters) {
    this.rootGetters = rootGetters
  }
  login (user) {
    return axios.post(this.rootGetters['api/authLogin'], {
      phone: user.phone,
      password: user.password,
    })
      .then((response) => {
        if (response.data.token) {
          axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
          // const EXPIRES_TIME = parseInt(new Date().getTime() / 1000) + 3600
          // localStorage.setItem('user', JSON.stringify(response.data))
          // localStorage.setItem('expires_in', EXPIRES_TIME)
        }

        return response.data
      })
  }

  logout () {
    return axios.post(this.rootGetters['api/authLogout']).then((response) => {
      axios.defaults.headers.common.Authorization = ''
      localStorage.removeItem('user')

      return response.data
    })
  }
}

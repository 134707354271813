export const actions = {
  fetchDeliveryMap ({ rootGetters }) {
    return this.$axios
      .get(rootGetters['api/getDeliveryMap'], {
        params: {
          regionId: this.$region.getCurrentRegionId(),
        },
      })
      .then(res => res.data.data)
  },
}


const state = () => ({
  iosAppLink: 'https://apps.apple.com/ua/app/%D1%81%D1%83%D1%88%D0%B8-%D1%81%D0%B5%D1%82/id1468185600?l=ru',
  androidAppLink: 'https://play.google.com/store/apps/details?id=group.mb.sushiset&pcampaignid=web_share',
})

const getters = {

}

const mutations = {

}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import Vue from 'vue'
import IconWeight from '@/components/UI/icons/IconWeight'
import Ruble from '@/components/UI/Ruble'
import LoaderOverScreen from '@/components/UI/LoaderOverScreen'

const components = {
  IconWeight,
  Ruble,
  LoaderOverScreen,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})

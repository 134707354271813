import axios from 'axios'
import { cookie } from '~/utils/cookie'

const state = () => ({
  cart: {
    totalPrice: 0,
    upSellingItems: [],
  },
  items: [],
  summary: 0,
  promoCodes: [],
  lastFilialId: null,
  isFirstGoods: true,
  tempItem: null,
  errorDelivery: false,
})

const mutations = {
  setCart (state, payload) {
    state.cart = payload
  },
  setCartItems (state, payload) {
    state.items = payload
  },
  setCartSummary (state, payload) {
    state.summary = payload
  },
  setPromoCodes (state, payload) {
    state.promoCodes = payload
  },
  setLastFilialId (state, payload) {
    state.lastFilialId = payload
  },
  setIsFirstGoods (state, payload) {
    state.isFirstGoods = payload
  },
  setTempItem (state, payload) {
    state.tempItem = payload
  },
  setErrorDelivery (state, payload) {
    state.errorDelivery = payload
  },
}

const getters = {
  getCart: state => state.cart,
  getPromoCodes: state => state.promoCodes,
  getCartItems: state => state.items,
  getCartLength: state =>
    state.items.length
      ? state.items.reduce((a, v) => a + v.quantity, 0)
      : 0,
  getCartSummary: state => state.summary,
  getIsFirstGoods: state => state.isFirstGoods,
  getTempItem: state => state.tempItem,
  getLastFilialId: state => state.lastFilialId,
  getErrorDelivery: state => state.errorDelivery,
}

const actions = {
  /**
   * Посчитать и закомитить в стор данные корзины
   * @param {*} param0
   */
  fetchCart ({ commit, rootGetters }) {
    return new Promise(function (resolve, reject) {
      axios
        .get(rootGetters['api/cartGetCart'])
        .then((res) => {
          commit('setCart', { ...res.data.data })
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          const filialId = cookie.get('filial_id')
          const isFirstGoods = !(res.data.data?.filial?.id &&
            String(res.data.data?.filial?.id) === filialId &&
            res.data.data.deliveryType)
          commit('setIsFirstGoods', isFirstGoods)
          if (res.data.data?.filial?.id) {
            commit('setLastFilialId', res.data.data?.filial?.id)
          }
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  fetchPromoCodes ({ commit, rootGetters }) {
    return new Promise(function (resolve, reject) {
      axios
        .get(rootGetters['api/cartGetPromoCodes'])
        .then((res) => {
          commit('setPromoCodes', { ...res.data.items })
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * Добавить товар в корзину
   * @param {*} param0
   * @param {
   *  menuItemId: number || boolean (false),
   *  type: string = [usual, construct],
   *  subMenuItemId: integer,
   * 	modificationId: number || string,
   *  ?comment: string
   * } data
   */
  addToCart ({ commit, rootGetters }, data) {
    return this.$axios
      .post(rootGetters['api/cartAddToCart'], data)
      .then((res) => {
        commit('setCart', res.data.data)
        commit('setCartItems', res.data.data.items || [])
        commit('setCartSummary', res.data.data.totalPrice)
        commit('setTempItem', null)
        commit('setIsFirstGoods', false)
        return res.data
      })
  },
  /**
   * Обновить данные корзины
   * @param {*} param0
   * @param {
   * 	comment: string,
   * 	persons: number,
   * 	promocode: string,
   * 	address: string
   * } data
   */
  updateCart ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios
        .patch(rootGetters['api/cartUpdateCart'], data)
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * Изменить адрес и время доставки
   * @param {*} param0
   * @param {
   * 	clientAddressId: string || number,
   * 	dateDelivery: string, // formatted: 10.12.2020
   * 	timeDelivery: string // formatted: 15:00 - 17:00
   * } data
   */
  changeCourier ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios
        .patch(rootGetters['api/cartChangeCourier'], data)
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * Изменить адрес самовывоза
   * @param {*} param0
   * @param {
   * 	filialId: string || number
   * } data
   */
  changePickup ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios
        .patch(rootGetters['api/cartChangePickup'], data)
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * Изменить тип оплаты и сумму размена
   * @param {*} param0
   * @param {
   * 	paymentType: string,
   * 	moneyDelivery: string || number
   * } data
   */
  changePayment ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios
        .patch(rootGetters['api/cartChangePayment'], data)
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * Изменить или добавить промокод
   * @param {*} param0
   * @param {
   * 	promoCode: string
   * } data
   */
  addPromoCode ({ commit, rootGetters }, code) {
    return new Promise(function (resolve, reject) {
      axios
        .patch(rootGetters['api/cartAddPromoCode'], { promoCode: code })
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  addPromoCodeId ({ commit, rootGetters }, promoCodeId) {
    return new Promise(function (resolve, reject) {
      axios
        .patch(rootGetters['api/cartAddPromoCode'], { promoCodeId })
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * Обновить товар в корзине. Для увеличения количества товара и изменения модификаторов.
   * @param {*} param0
   * @param {
   * 	modificationId: number || string,
   * 	quantity: number || string
   * } data
   * @param { number } id
   */
  updateCartItem ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios
        .patch(rootGetters['api/cartUpdateCartItem'](data.id), data.data)
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * Отказаться от звонка администратора
   * @param {*} param0
   * @param {
   * 	noCall: boolean
   * } data
   */
  updateCallNotification ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios
        .patch(rootGetters['api/cartUpdateCallNotification'], data)
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * Оформить заказ
   * @param {*} param0
   */
  createOrder ({ commit, rootGetters }) {
    return new Promise(function (resolve, reject) {
      axios
        .patch(rootGetters['api/cartCreateOrder'], {
          utmSource: cookie.get('utm_source'),
        })
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * Синхронизация корзин
   * @param {*} param0
   */
  syncCart ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios
        .patch(rootGetters['api/cartSync'], data)
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * Удалить товар с корзины
   * @param {*} param0
   * @param { object } data
   */
  deleteItem ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios
        .delete(rootGetters['api/cartDelete'](data.hash))
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * Изменить количество товаров в корзине
   * @param {*} param0
   * @param { object } data
   */
  changeQuantity ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios
        .post(rootGetters['api/cartChangeQuantity'](data), {
          type: data.type,
        })
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * Изменить модификатор у группы
   * @param {*} param0
   * @param { object } data
   */
  changeModification ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios
        .post(rootGetters['api/changeModification'](data), {
          modificationId: data.modificationId,
        })
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  /**
   * Полностью очистить корзину
   * @param {*} param0
   */
  deleteAll ({ commit, rootGetters }) {
    return new Promise(function (resolve, reject) {
      axios
        .delete(rootGetters['api/cartDeleteAll'])
        .then((res) => {
          commit('setCart', res.data.data)
          commit('setCartItems', res.data.data.items || [])
          commit('setCartSummary', res.data.data.totalPrice)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}

const state = () => ({
  currentProduct: {
    uuid: 'e6bb121b-3c03-45c9-818c-37af20b88eea',
    type: 'rolls',
    title: 'Темпура ролл с лососем',
    description: 'Нежнейший лосось, сливочный сыр, хрустящий огурчик, Нежнейший лосось, сливочный сыр, хрустящий огурчик,Нежнейший лосось, сливочный сыр, хрустящий огурчик',
    consist: 'Лосось, Сливочный сыр, рис, лист нори, икра масаго, авокадо',
    photo: 'бонито ролл.png',
    attrs: {
      weight: 657,
      protein: 10,
      fats: 18,
      carbohydrates: 6,
      calorie: 167,
      hasPromote: true,
      hasPersonalPromote: false,
      isHit: true,
      isNew: false,
      isSpicy: false,
      isVedgie: true,
    },
    price: {
      full: 400,
      current: 300,
    },
    liked: true,
    canBeHalf: true,
  },
})

const getters = {
  getCurrentProduct: state => state.currentProduct,
}

export default {
  namespaced: true,
  state,
  getters,
}

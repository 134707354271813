<template>
  <div class="header_collapse_phone">
    <region-delivery-label />
    <a
      :href="'tel:' + $region.getPhone()"
      @click="$store.dispatch('events/phoneClick')"
      class="header_collapse_phone_target"
    >
      <div class="header_collapse_phone_target_ico">
        <font-awesome-icon :icon="['fas', 'phone-alt']" />
      </div>
      <strong>{{ $region.getPhone() }}</strong>
    </a>
  </div>
</template>

<script>
import { getPhoneByUtm } from '~/utils/getPhoneByUtm'
import RegionDeliveryLabel from '~/components/page/RegionDeliveryLabel'

export default {
  components: { RegionDeliveryLabel },
  data () {
    return {
      phoneNumber: '+7 (495) 150-55-22',
    }
  },
  mounted () {
    this.phoneNumber = getPhoneByUtm()
  },
}
</script>

<style lang="less">
.header_collapse_phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 46px;

  p {
    font-family: 'Roboto Slab', Arial, sans-serif;
    font-weight: 500;
    font-size: 14px;

    strong {
      color: #E31E24;
    }
  }

  &_target {
    max-width: 100%;
    width: 260px;
    display: flex;
    align-items: center;
    background-color: #F9F9F9;
    margin-top: 7px;
    border-radius: 15px;
    padding: 8px 17px;
    color: black;
    text-decoration: none;

    strong {
      font-family: 'Roboto Slab', Arial, sans-serif;
      font-weight: bold;
      font-size: 18px;
    }

    &_ico {
      width: 49px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 15px;
      margin-right: 18px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, .09);
      font-size: 20px;
      color: @color-primary;
    }
  }
}
</style>

<template>
  <div class="footer_main">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="footer_main_logo">
            <FooterLogo />
          </div>
          <!-- <MobileAppsGroup class="d-none d-md-flex">
						<MobileApp platform="apple" />
						<MobileApp platform="gplay" />
					</MobileAppsGroup> -->
        </div>
        <div class="col-12 col-md-3">
          <FooterNavigation :navItems="forClients" title="Покупателям" />
        </div>
        <div class="col-12 col-md-3">
          <FooterNavigation :navItems="info" title="Информация" />
        </div>
        <div class="col-12 col-md-3">
          <div class="footer_main_info">
            <div class="footer_main_contacts">
              <FooterContacts />
            </div>
            <div class="footer_main_socials">
              <FooterSocials />
            </div>
            <TermsOfUseLink class="d-none d-md-flex" />
          </div>
        </div>
        <div class="col-12 d-flex d-md-none d-flex flex-column align-items-center">
          <!--					<MobileAppsGroup style="margin-bottom: 15px">
						<MobileApp platform="apple" />
						<MobileApp platform="gplay" />
					</MobileAppsGroup>-->
          <TermsOfUseLink />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FooterLogo from './FooterLogo'
import FooterNavigation from './FooterNavigation'
import FooterContacts from './FooterContacts'
import FooterSocials from './FooterSocials'

import MobileAppsGroup from './../../UI/MobileAppsGroup'
import MobileApp from './../../UI/MobileApp'
import TermsOfUseLink from './../../UI/TermsOfUseLink'

export default {
  components: { FooterLogo, MobileAppsGroup, MobileApp, FooterNavigation, FooterContacts, FooterSocials, TermsOfUseLink },
  data () {
    return {
      forClients: [
        { name: 'Полное меню', path: '/menu' },
        { name: 'Акции', path: '/promos' },
        { name: 'Доставка и оплата', path: '/delivery' },
      ],
      info: [
        { name: 'Новости', path: '/news' },
        { name: 'Вакансии', path: '/vacancies' },
        { name: 'Поставщикам', path: '/postavshikam' },
        { name: 'Франшиза', path: 'https://fr.setsushi.ru/', external: true, class: 'd-md-none' },
      ],
    }
  },
}
</script>

<style lang="less">
.footer_main {
  display: flex;
  padding-top: 28px;
  padding-bottom: 28px;

  @media screen and (max-width: 768px) {
    padding-bottom: 28px;
  }

  &_logo {
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 36px;
    }
  }

  &_contacts {
    margin-bottom: 42px;

    @media screen and (max-width: 768px) {
      margin-bottom: 35px;
    }
  }

  &_socials {
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      margin-bottom: 44px;
    }
  }

  &_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    @media screen and (max-width: 768px) {
      align-items: center;
      text-align: center;
    }
  }

  .col-12 {
    @media screen and (max-width: 768px) {
      &:nth-child(1) {
        order: -1;
      }

      &:nth-child(4) {
        order: -1;
      }
    }
  }
}
</style>

const middleware = {}

middleware['authenticated_clearance'] = require('../middleware/authenticated_clearance.js')
middleware['authenticated_clearance'] = middleware['authenticated_clearance'].default || middleware['authenticated_clearance']

middleware['region_detect'] = require('../middleware/region_detect.js')
middleware['region_detect'] = middleware['region_detect'].default || middleware['region_detect']

middleware['set_meta_tags'] = require('../middleware/set_meta_tags.js')
middleware['set_meta_tags'] = middleware['set_meta_tags'].default || middleware['set_meta_tags']

export default middleware

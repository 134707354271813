<template>
  <footer class="footer">
    <FooterMain />
    <FooterBottom />
    <region-select />
  </footer>
</template>

<script>
import FooterMain from './FooterMain'
import FooterBottom from './FooterBottom'
import RegionSelect from '~/components/regions/RegionSelect'

export default {
  components: { RegionSelect, FooterMain, FooterBottom },
}
</script>

<style lang="less">
.footer {
	width: 100%;
	background-color: #191919;
}
</style>

<template>
  <div class="header_top_login">
    <client-only>
      <button @click="goToProfileOrLogin" class="header_top_login_button">
        <div class="header_top_login_ico">
          <font-awesome-icon :icon="['fas', 'user-alt']" />
        </div>
        <span v-if="isLoggedIn">{{ getUserName }}</span>
        <span v-else>Вход в кабинет</span>
      </button>
      <button
        v-if="isLoggedIn"
        @click="logOut"
        class="header_top_login_button"
      >
        <div class="header_top_login_ico">
          <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
        </div>
        <span>Выход</span>
      </button>
    </client-only>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      loggedIn: 'auth/loggedIn',
    }),
    getUserName () {
      if (this.getUser) {
        return this.getUser.name || 'Гость'
      }

      return 'Мой профиль'
    },
    isLoggedIn () {
      return !!this.$store.getters['auth/loggedIn']
    },
  },
  created () {
    if (this.loggedIn) {
      this.$store.dispatch('client/fetchProfile').then((data) => {
        this.profile = data
      })
    }
  },
  methods: {
    logOut () {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push('/')
          this.$store.dispatch('cart/fetchCart')
            .catch(err => console.error('header top login - cart/fetchCart - error', err))
        })
        .catch((err) => {
        })
    },
    goToProfileOrLogin () {
      if (this.$store.getters['auth/loggedIn']) {
        this.$router.push('/profile')
      } else {
        this.$router.push('/login')
      }
    },
  },
}
</script>

<style lang="less">
.header_top_login {
  display: flex;
  align-items: center;

  &_button {
    background-color: transparent;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 10px;
    }

    span {
      display: block;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      border-bottom: 1px dashed #000;
    }
  }

  &_ico {
    width: 17px;
    height: 17px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 10px;
    color: #E31E24;
    border-radius: 50%;
    margin-right: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
}
</style>

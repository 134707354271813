<template>
  <client-only>
    <Modal :open="isOpen" @close="isOpen = false" borderless>
      <div class="order-type">
        <div :class="['order-type_container', { loading: isLoading }]">
          <div @click="isOpen = false" class="order-type_close">
            <font-awesome-icon :icon="['fas', 'times']" />
          </div>
          <loader
            v-if="isLoading"
            :fixed="false"
            :absolute="true"
            class="order-type_loader"
          />
          <div class="order-type_main">
            <div class="order-type_head">
              <div class="order-type_title">
                {{
                  currentDeliveryTab === 'delivery'
                    ? 'Добавьте адрес'
                    : 'Самовывоз или доставка'
                }}
              </div>
              <div class="order-type_select">
                <div
                  :class="{ active: currentDeliveryTab === 'delivery' }"
                  @click="currentDeliveryTab = 'delivery'"
                  class="order-type_select_tab"
                >
                  Доставка
                </div>
                <div
                  :class="{ active: currentDeliveryTab === 'self' }"
                  @click="currentDeliveryTab = 'self'"
                  class="order-type_select_tab"
                >
                  Самовывоз
                </div>
              </div>
            </div>
            <DeliveryOption
              v-show="currentDeliveryTab === 'delivery'"
              :deliveryAddresses="sortDeliveryAddresses"
              :newAdress="newAddress"
              :changeAddress="changeAddress"
              :addressIndex="addressIndex"
              :changeAddressIndex="changeAddressIndex"
              :setTempCoords="setTempCoords"
              :add-new-address="addNewAddress"
            />
            <SelfOption
              v-show="currentDeliveryTab === 'self'"
              :pickupAddresses="sortPickupAddresses"
              :changeAddress="changeAddress"
              :addressIndex="addressIndex"
              :changeAddressIndex="changeAddressIndex"
            />
          </div>
          <div class="order-type_map">
            <OrderTypeMap :coords="getCoordsAddress" :withZone="currentDeliveryTab === 'delivery'" />
          </div>
        </div>
      </div>
    </Modal>
  </client-only>
</template>

<script>
import global from '@/mixins/global'
import Modal from '~/components/page/Modal/Modal.vue'
import Loader from '~/components/UI/PageLoader.vue'
import DeliveryOption from '~/components/layout/OrderTypeSelect/DeliveryOption.vue'
import SelfOption from '~/components/layout/OrderTypeSelect/SelfOption.vue'
import OrderTypeMap from '~/components/layout/OrderTypeSelect/OrderTypeMap.vue'

export default {
  name: 'OrderTypeSelect',
  components: {
    OrderTypeMap,
    SelfOption,
    DeliveryOption,
    Loader,
    Modal,
  },
  mixins: [global],
  data () {
    return {
      isOpen: false,
      currentDeliveryTab: 'delivery',
      lastDeliveryType: '',
      pickupAddresses: [],
      deliveryAddresses: [],
      openModal: false,
      addressIndex: [-1, -1],
      isLoading: false,
      tempCoords: null,
      newAddress: [],
    }
  },
  computed: {
    getCart () {
      return this.$store.getters['cart/getCart']
    },
    getTempItem () {
      return this.$store.getters['cart/getTempItem']
    },
    getCartDeliveryType () {
      return this.getCart?.deliveryType
    },
    getCartClientAddress () {
      return this.getCart?.clientAddress
    },
    getCartClientAddressId () {
      return this.getCartClientAddress?.id
    },
    getCartFilialId () {
      return this.getCart?.filial?.id
    },
    getCurrentDeliveryType () {
      let delivery = 'delivery'
      if (this.getCartDeliveryType) {
        delivery = this.getCartDeliveryType
      } else if (this.lastDeliveryType) {
        delivery = this.lastDeliveryType
      }
      return delivery
    },
    sortDeliveryAddresses () {
      const selectAddress = []
      const prevAddress = []
      const simpleAddress = []
      if (!this.deliveryAddresses?.length && this.getCartClientAddress) {
        return [...selectAddress, this.getCartClientAddress]
      }

      this.deliveryAddresses.forEach((address) => {
        if (address.id === this.getCartClientAddressId) {
          selectAddress.push(address)
        } else if (address.isLast) {
          prevAddress.push(address)
        } else {
          simpleAddress.push(address)
        }
      })

      return [...selectAddress, ...prevAddress, ...simpleAddress]
    },
    sortPickupAddresses () {
      const selectAddress = []
      const prevAddress = []
      const simpleAddress = []

      this.pickupAddresses.forEach((address) => {
        if (address.id === this.getCartFilialId) {
          selectAddress.push(address)
        } else if (address.isLast) {
          prevAddress.push(address)
        } else {
          simpleAddress.push(address)
        }
      })

      return [...selectAddress, ...prevAddress, ...simpleAddress]
    },
    getCoordsAddress () {
      if (this.tempCoords && this.currentDeliveryTab === 'delivery') {
        return this.tempCoords
      }
      if (this.addressIndex[1] !== this.currentDeliveryTab) {
        return null
      }
      if (this.currentDeliveryTab === 'delivery') {
        const address = [...this.newAddress, ...this.sortDeliveryAddresses][this.addressIndex[0]]
        return address?.latGeo && address?.letGeo ? [address.latGeo, address.letGeo] : null
      } else if (this.currentDeliveryTab === 'self') {
        const address = this.sortPickupAddresses[this.addressIndex[0]]
        return address?.lat && address?.let ? [address.lat, address.let] : null
      } else {
        return null
      }
    },
  },
  watch: {
    getCurrentDeliveryType (newVal) {
      this.currentDeliveryTab = newVal
    },
    getCartDeliveryType () {
      this.setAddressIndex()
    },
    getCartClientAddressId () {
      this.setAddressIndex()
    },
    getCartFilialId () {
      this.setAddressIndex()
    },
    sortDeliveryAddresses () {
      this.setAddressIndex()
    },
    sortPickupAddresses () {
      this.setAddressIndex()
    },
    lastDeliveryType () {
      this.setAddressIndex()
    },
    getTempItem (newVal) {
      if (newVal) {
        this.isOpen = true
      }
    },
  },
  created () {
    if (process.client) {
      this.loadDeliveryOptions()
    }
  },
  mounted () {
    /**
     * Обновляем данные доставки
     */
    this.$nuxt.$on('cl-update-delivery', () => {
      if (this.currentDeliveryTab === 'delivery') {
        this.$store
          .dispatch('cart/changeCourier', {
            clientAddressId: this.addressIndex[0],
          })
          .catch((err) => {
            this.$toast.error(err?.response?.data?.message, {
              duration: 3000,
            })
          })
      } else {
        // Если самовывоз
        const index = this.addressIndex[0]
        const ADDRESS = this.pickupAddresses.find((v, i) => i === index)
        const ADDRESS_ID = ADDRESS ? ADDRESS.id : 1

        this.$store
          .dispatch('cart/changePickup', {
            filialId: ADDRESS_ID,
          })
          .catch((err) => {
            this.$toast.error(err?.response?.data?.message, {
              duration: 3000,
            })
          })
      }
    })
  },

  methods: {
    async loadDeliveryOptions () {
      try {
        const deliveryOptions = await this.$store.dispatch(
          'info/fetchDeliveryOptions',
        )
        this.lastDeliveryType = deliveryOptions.lastDeliveryType
        this.pickupAddresses = deliveryOptions.pickupPoints
        this.deliveryAddresses = deliveryOptions.addresses
      } catch (e) {}
    },
    async changeAddress (index, type) {
      if (type === 'delivery') {
        const ADDRESS = [...this.newAddress, ...this.sortDeliveryAddresses][index]
        const ADDRESS_ID = ADDRESS ? ADDRESS.id : null
        if (ADDRESS_ID) {
          this.isLoading = true
          this.$store
            .dispatch('cart/changeCourier', {
              clientAddressId: ADDRESS_ID,
            })
            .then(() => {
              this.changeFilialId()
              this.sendProduct(this.getTempItem)
              this.isOpen = false
            })
            .catch((err) => {
              this.$toast.error(err?.response?.data?.message, {
                duration: 3000,
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      } else if (type === 'self') {
        const ADDRESS = this.sortPickupAddresses[index]
        const ADDRESS_ID = ADDRESS ? ADDRESS.id : null
        if (ADDRESS_ID) {
          this.isLoading = true
          this.$store
            .dispatch('cart/changePickup', {
              filialId: ADDRESS_ID,
            })
            .then(() => {
              this.changeFilialId()
              this.sendProduct(this.getTempItem)
              this.isOpen = false
            })
            .catch((err) => {
              this.$toast.error(err?.response?.data?.message, {
                duration: 3000,
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      }
    },
    changeAddressIndex (index) {
      this.addressIndex = index
    },
    setAddressIndex () {
      if (this.getCartDeliveryType === 'delivery') {
        if (this.newAddress.length) {
          this.addressIndex = [0, this.getCartDeliveryType]
        } else {
          const index = this.sortDeliveryAddresses.findIndex(item => item.id === this.getCartClientAddressId)
          this.addressIndex = [index, this.getCartDeliveryType]
        }
      } else if (this.getCartDeliveryType === 'self') {
        const index = this.sortPickupAddresses.findIndex(item => item.id === this.getCartFilialId)
        this.addressIndex = [index, this.getCartDeliveryType]
      } else if ((this.lastDeliveryType === 'delivery' && this.sortDeliveryAddresses[0]?.isLast) || (this.lastDeliveryType === 'self' && this.sortPickupAddresses[0]?.isLast)) {
        this.addressIndex = [0, this.lastDeliveryType]
      } else {
        this.addressIndex = [-1, -1]
      }
    },
    setTempCoords (coords) {
      this.tempCoords = coords
    },
    addNewAddress (address) {
      this.newAddress = [address, ...this.newAddress]
      this.addressIndex = [0, 'delivery']
    },
  },
}
</script>

<style lang="less" scoped>
.order-type {
  padding: 10px 30px;
  width: 100%;
  max-width: 960px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &_loader {
    z-index: 10;
    border-radius: 20px;
  }

  &_container {
    width: 100%;
    height: 100%;
    max-height: 600px;
    display: flex;
    font-size: 13px;
    background-color: #ffffff;
    border-radius: 20px;
    position: relative;
    z-index: 1;

    &.loading {
      min-height: 300px;
    }
  }

  &_main {
    padding: 47px 0 30px 21px;
    width: 400px;
    height: 100%;
    max-height: 100%;
    display: grid;
    grid-template-rows: 110px calc(100% - 110px);
    position: relative;
    background-color: #ffffff;
    border-radius: 20px;
  }

  &_head {
    padding-right: 26px;
    width: 100%;
  }

  &_title {
    margin-bottom: 35px;
    font-family: 'Aqum', Arial, sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: black;
    line-height: 24px;
    letter-spacing: 0.5px;
    white-space: nowrap;
  }

  &_select {
    width: 100%;
    height: 50px;
    display: flex;
    background-color: #f9f9f9;
    border-radius: 10px;

    &_tab {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 10px;
      cursor: pointer;
      font-family: 'Roboto Slab', Arial, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;

      &.active {
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
        color: #e31e24;
      }
    }
  }

  &_map {
    height: 100%;
    flex-grow: 1;
  }

  &_close {
    width: 49px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F3F3F3;
    font-size: 15px;
    border-radius: 15px;
    transition: all .1s;
    position: absolute;
    top: 28px;
    right: 28px;
    cursor: pointer;
    z-index: 100;

    &:hover {
      transform: scale(1.06);
    }
  }
}

@media (max-width: 1024px) {
  .order-type {
    padding: 10px;

    &_title {
      font-size: 15px;
    }

    &_main {
      width: 310px;
    }

    &_select {
      &_tab {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 900px) {
  .order-type {
    padding: 10px;

    &_title {
      font-size: 15px;
    }

    &_select {
      &_tab {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 620px) {
  .order-type {
    justify-content: center;

    &_container {
      width: auto;
    }

    &_close {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      top: 12px;
      right: 16px;
      background-color: #EBEDF0;

      svg path {
        fill: #9CA5AF;
      }
    }
  }
}
</style>

<template>
  <div class="terms-links">
    <nuxt-link to="/legal-info" class="terms">
      Юридическая информация
    </nuxt-link>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
.terms-links {
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding-top: 15px;
    text-align: center
  }
}

.terms {
  display: block;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  color: #888;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 7px;

    @media screen and (max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px
  }
}
</style>

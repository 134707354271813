<template>
  <div class="modal_outer">
    <transition :duration="100" name="mfade">
      <div v-if="open" class="modal">
        <div @click.stop="$emit('close')" class="modal_event" />
        <slot v-if="borderless" @click="$emit('close')" />
        <div v-else class="modal_wrap">
          <div :style="getInitialWidth" class="modal_body">
            <slot />
            <div @click="$emit('close')" class="modal_body_close">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default () {
        return false
      },
    },
    initialWidth: {
      type: String,
      default: null,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getInitialWidth () {
      return (this.initialWidth) ? { width: this.initialWidth } : ''
    },
  },
  watch: {
    open (newValue) {
      if (newValue) {
        document.querySelector('body').style.overflow = 'hidden'
      } else {
        document.querySelector('body').style.overflow = 'visible'
      }
    },
  },
  mounted () {
    this.$nuxt.$on('close-modal', () => {
      this.$emit('close')
      document.querySelector('body').style.overflow = 'visible'
    })
  },
  beforeDestroy () {
    document.querySelector('body').style.overflow = 'visible'
  },
}
</script>

<style lang="less">
.modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, .7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  overflow-y: scroll;

  &_event {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &_wrap {
    max-height: 100%;
    width: 100%;
    //width: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    @media screen and (min-width: 576px) {
      max-width: 540px;
    }
    @media screen and (min-width: 768px) {
      max-width: 720px;
    }
    @media screen and (min-width: 992px) {
      max-width: 960px;
    }
    @media screen and (min-width: 1200px) {
      max-width: 1140px;
    }
    @media screen and (min-width: 1400px) {
      max-width: 1320px;
    }
  }

  &_body {
    max-height: calc(100vh - 20px);
    width: 100%;
    background-color: white;
    padding: 30px 35px;
    border-radius: 30px;
    box-shadow: 0 16px 29px rgba(0, 0, 0, .09);
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px;

    /* width */

    &::-webkit-scrollbar {
      width: 8px;
    }

    /* button */

    &::-webkit-scrollbar-button {
      display: none;
    }

    /* Handle */

    &::-webkit-scrollbar-thumb {
      background: #E31E24;
      border-radius: 5px;
    }

    /* Track */

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &_close {
      width: 49px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F3F3F3;
      font-size: 15px;
      border-radius: 15px;
      transition: all .1s;
      position: absolute;
      top: 28px;
      right: 28px;
      cursor: pointer;
      z-index: 100;

      &:hover {
        transform: scale(1.06);
      }
    }
  }
}

.mfade-enter-active, .mfade-leave-active {
  transition: opacity .1s;
}

.mfade-enter, .mfade-leave-to {
  opacity: 0;
}
</style>

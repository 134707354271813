var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"house-input-component"},[(false)?_c('p',[_vm._v("\n    v-model mode:\n    "),_c('button',{class:{ selected: _vm.mode === 'input', 'v-model-event': true },on:{"click":function($event){_vm.mode = 'input'}}},[_vm._v("\n      input\n    ")]),_vm._v(" "),_c('button',{class:{ selected: _vm.mode === 'select', 'v-model-event': true },on:{"click":function($event){_vm.mode = 'select'}}},[_vm._v("\n      select\n    ")])]):_vm._e(),_vm._v(" "),_c('vue-suggest',{ref:"suggestComponent",staticClass:"asdad",attrs:{"destyled":true,"list":_vm.getList,"max-suggestions":30,"min-length":1,"debounce":200,"filter-by-query":false,"controls":{
      selectionUp: [38, 33],
      selectionDown: [40, 34],
      select: [13, 36],
      showList: [40],
      hideList: [27, 35]
    },"mode":_vm.mode,"pattern":"\\w+","nullable-select":true,"placeholder":"Search information...","value-attribute":"value","display-attribute":"value"},on:{"select":_vm.onSuggestSelect,"input":_vm.onInput,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"misc-item-above",fn:function({ suggestions, query }){return [(suggestions.length === 0 && !_vm.loading)?_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v("Ничего не найдено...\"")])]):_vm._e()]}},{key:"suggestion-item",fn:function(scope){return _c('div',{},[_c('div',{staticClass:"text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.boldenSuggestion(scope))}})])])}},{key:"misc-item-below",fn:function({ suggestions }){return (_vm.loading)?_c('div',{staticClass:"misc-item"},[_c('span',[_vm._v("Поиск...")])]):_vm._e()}}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('div',{staticClass:"query"},[_c('input',_vm._b({staticClass:"input-text_input",attrs:{"placeholder":_vm.placeholder,"disabled":!_vm.streetId,"type":"text"}},'input',_vm.$attrs,false))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <nuxt-link class="footer_logo" tag="a" to="/">
    <img src="/logo/logo-sushiset-white.svg" alt="">
  </nuxt-link>
</template>

<script>
export default {

}
</script>

<style lang="less">
.footer_logo {
	display: inline-flex;
	cursor: pointer;

	img {
		width: 190px;
		height: auto;

		@media screen and (max-width: 992px) { width: 145px; }
		@media screen and (max-width: 768px) { width: 190px; }
	}
}
</style>

<template>
  <div
    v-bind="$attrs"
    :class="{ 'self-item_active': isActive, 'prev': item.isLast }"
    @click="$emit('changeAddressIndex', [index, 'self'])"
    class="self-item"
  >
    <div class="self-item__icon">
      <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
    </div>
    <div class="self-item__content">
      <div class="self-item__field self-item__address">
        <span v-if="false" class="self-item__city">
          {{ item.city }},&nbsp;
        </span>
        {{ item.address }}
      </div>
      <div
        v-if="item.startTime && item.endTime"
        class="self-item__field self-item__time"
      >
        с {{ item.startTime }} до {{ item.endTime }}
      </div>
      <div v-if="item.metro" class="self-item__field self-item__metro">
        {{ item.metro }}
      </div>
    </div>
    <div v-if="item.isLast" :class="['delivery-item_label', { active: isActive }]">
      Прошлый самовывоз
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelfItem',
  props: {
    item: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="less" scoped>
.self-item {
  width: 100%;
  min-height: 78px;
  display: flex;
  flex-shrink: 0;
  padding: 17px 12px 3px;
  border-radius: 20px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  position: relative;
  cursor: pointer;
  transition: border 0.1s ease;

  &_active {
    border: 1px solid #e31e24;

    &.prev {
      border: 1px solid #e31e24;
      background: rgba(227, 30, 36, 0.1);
    }
  }

  &__icon {
    height: fit-content;
    color: #e31e24;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__field {
    //text-align: right;
    font-weight: 300;
    font-family: 'Roboto Slab', Arial, sans-serif;
    font-size: 12px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__address {
    font-weight: 500;
    line-height: 1.2;
    font-size: 12px;
  }

  &__metro {
    color: #919191;
  }

  &__city {
    color: #7c7c7c;
    font-weight: normal;
  }

  &__select-btn {
    width: auto;
    background: #e31e24;
    color: #fff;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    padding: 0 15px;
    position: relative;
    font-weight: 300;
    font-size: 10px;
    height: 28px;
    margin-top: 3px;
  }
}
</style>

<template>
  <div class="header_main_mode">
    <div class="header_main_mode_pic">
      <img src="/icons/icon-work-time.svg">
    </div>
    <div class="header_main_mode_group">
      <div class="header_main_mode_item">
        <small>режим работы</small>
        <strong>{{ $region.get('workTimeString') }}</strong>
      </div>
      <div class="header_main_mode_item">
        <small>доставка от</small>
        <strong>30 мин.</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.header_main_mode {
  display: flex;
  align-items: center;

  &_pic {
    display: flex;
    flex-shrink: 0;
    margin-right: 10px;

    & > svg {
      flex-shrink: 0;
    }
  }

  &_group {
    display: flex;
    align-items: center;
  }

  &_item {
    flex-shrink: 0;
    font-family: 'Roboto Slab', 'Roboto', Arial, sans-serif;
    text-align: center;

    @media screen and (max-width: 1200px) {
      &:last-child {
        display: none;
      }
    }

    small {
      display: block;
      font-weight: 600;
      font-size: 10px;
      color: #C2C2C2;
    }

    strong {
      display: block;
      font-weight: bold;
      font-size: 15px;
      color: black;

      &.holiday-time {
        padding-top: 1px;
        font-size: 10px;
      }
    }

    &:not(:last-child) {
      margin-right: 35px;
      position: relative;

      @media screen and (max-width: 1200px) {
        margin-right: 0;
      }

      &:after {
        content: '';
        width: 7px;
        height: 24px;
        background-image: url('../../../assets/images/slash.svg');
        background-size: contain;
        background-position: center;
        position: absolute;
        top: 50%;
        right: -22px;
        transform: translateY(-50%);

        @media screen and (max-width: 1200px) {
          content: unset;
        }
      }
    }
  }
}
</style>

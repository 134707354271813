<template>
  <div>
    <transition name="fade">
      <PageLoader v-if="showLoader" />
    </transition>
    <nuxt class="empty-frame-page" />
  </div>
</template>

<script>
import PageLoader from '~/components/UI/PageLoader'

export default {
  components: { PageLoader },
  head () {
    return {
      title: this.$store.state.seo.metaTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.seo.metaDescription,
        },
      ],
    }
  },
  data () {
    return {
      showLoader: true,
      showTemporaryModal: true,
    }
  },
  mounted () {
    setTimeout(() => {
      this.showLoader = false
    }, 500)
  },
}
</script>

<style lang="less">

.empty-frame-page {
  min-height: 100vh;
}
</style>

<template>
  <div class="mobile-apps_item">
    <a :href="getUrl" v-if="allowDirectDownloadMobileApp || alwaysSowLinks" target="_blank">
      <img v-if="platform === 'apple'" src="@/assets/images/appstore.svg" alt="">
      <img v-else src="@/assets/images/gplay.svg" alt="">
    </a>

    <div v-else>
      <img v-if="platform === 'apple'" src="@/assets/images/appstore.svg" alt="">
      <img v-else src="@/assets/images/gplay.svg" alt="">
    </div>
  </div>
</template>

<script>
import UserDeviceDetect from '~/mixins/user-device-detect'

export default {
  mixins: [UserDeviceDetect],
  props: {
    platform: {
      type: String,
      default: 'apple',
    },
    alwaysSowLinks: {
      type: Boolean,
      default: false,
    },
  },
  computed: {

    getUrl () {
      return (this.platform === 'apple')
        ? this.$store.state.mobileApp.iosAppLink
        : this.$store.state.mobileApp.androidAppLink
    },
  },
}
</script>

<style lang="less">
.mobile-apps_item {
  display: block;

  img {
    width: auto;
    height: 39px;

    @media screen and (max-width: 1200px) {
      height: 35px;
    }
  }
}
</style>

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/loggedIn',
    }),
    allowDirectDownloadMobileApp () {
      return this.isLoggedIn && this.deviceHasSupportedPlatform
    },

    deviceHasSupportedPlatform () {
      return this.$device.isAndroid || this.$device.isIos
    },
  }
}

<template>
  <div class="input-text">
    <input
      v-mask="mask"
      :value="val"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="emitVModel($event.target.value)"
      @blur="$emit('blur')"
      type="text"
      class="input-text_input"
    >
  </div>
</template>

<script>
export default {
  model: {
    prop: 'val',
    event: 'input',
  },
  props: {
    val: {
      type: String,
      default: '',
    },
    placeholder: String,
    mask: {
      type: String,
      default: '',
    },
    disabled: false
  },
  methods: {
    emitVModel (val) {
      this.$emit('input', val)
    },
  },
}
</script>

<style lang="less">
.input-text {
	width: 100%;
	flex-grow: 1;

	&_input	{
		height: 60px;
		width: 100%;
		background-color: #f2f2f2;
		font-family: 'Roboto', Arial, sans-serif;
		font-weight: 500;
		font-size: 16px;
		color: #B5B5B5;
		padding: 0 40px;
		border: none;
		border-radius: 15px;
		box-sizing: border-box;

		&:disabled {
			opacity: .75;
			cursor: not-allowed;
		}
	}

	&.error input {
		border: 1px solid #f44336;
	}
}
</style>

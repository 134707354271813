import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

const loading = require('@/assets/image-preloader.svg')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading,
  attempt: 1,
})

export default function getTopMenuIcon (category) {
  const slug = category.slug || null

  if (category.topMenuIco) {
    return category.topMenuIco
  }

  switch (slug) {
    case 'rolls': return '/header-menu/2/rolls.png'
    case 'baked-rolls': return '/header-menu/2/baked-rolls.png'
    case 'sets': return '/header-menu/2/sets-combo.png'
    case 'sushi': return '/header-menu/2/sushi.png'
    case 'snacks': return '/header-menu/2/salads.png'
    case 'poke': return '/header-menu/poke.png'
    case 'hot': return '/header-menu/2/hot.png'
    case 'drinks': return '/header-menu/2/drinks.png'
    case 'deserts': return '/header-menu/2/desert.png'
    case 'sauce': return '/header-menu/2/sauce.png'

    default: return '/header-menu/2/rolls.png'
  }
}

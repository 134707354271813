<template>
  <div class="header_collapse_work_time">
    <HeaderMainMode />
  </div>
</template>

<script>
import HeaderMainMode from '~/components/layout/Header/HeaderMainMode'

export default {
  components: {
    HeaderMainMode,
  },
}
</script>

<style lang="less">
.header_collapse_work_time {
	display: flex;
	justify-content: center;
	margin-bottom: 45px;

	.header_main_mode_item{
		margin: 0 15px;
		 &:last-child {
			 display: block;
		}

	}
}
</style>

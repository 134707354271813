class Cookies {
  constructor (context) {
    this.context = context

    this.setOptsDefaults = {
      path: '/',
      maxAge: 60 * 60 * 24 * 30 * 12,
      domain: context.$config.appHost ? `.${context.$config.appHost}` : '.setsushi.fakt-crm.com',
    }
  }

  get (name, opts) {
    return this.context.$cookiz.get(name, opts)
  }

  set (name, value, opts) {
    const setOpts = Object.assign({}, this.setOptsDefaults, opts)
    return this.context.$cookiz.set(name, value, setOpts)
  }

  remove (name, opts) {
    const setOpts = Object.assign({}, this.setOptsDefaults, opts)
    return this.context.$cookiz.remove(name, setOpts)
  }
}

export default (ctx, inject) => {
  inject('cookies', new Cookies(ctx))
}

const state = () => ({
  status: null,
  vacancyFormErrors: {
    role: null,
    name: null,
    phone: null,
    email: null,
    birthDay: null,
    address: null,
    pcLevel: false,
    dateBegin: null,
    comment: null,
  },
  contactsFormErrors: {
    name: null,
    phone: null,
    comment: null,
  },
  cooperationFormErrors: {
    organisationName: null,
    name: null,
    phone: null,
    inn: null,
    text: null,
    doc: null,
  },
})

const getters = {
  getStatus: state => JSON.parse(JSON.stringify(state.status)),
  getVacancyFormErrors: state => JSON.parse(JSON.stringify(state.vacancyFormErrors)),
  getContactsFormErrors: state => JSON.parse(JSON.stringify(state.contactsFormErrors)),
  getCooperationFormErrors: state => JSON.parse(JSON.stringify(state.cooperationFormErrors)),
}

const mutations = {
  setStatus (state, status) {
    state.status = status
  },
  saveVacancyFormErrors (state, payload) {
    state.vacancyFormErrors = Object.assign(state.vacancyFormErrors, payload)
  },
  clearVacancyFormErrors (state) {
    state.vacancyFormErrors = {
      role: null,
      name: null,
      phone: null,
      email: null,
      birthDay: null,
      address: null,
      pcLevel: false,
      dateBegin: null,
      comment: null,
    }
  },
  saveContactsFormErrors (state, payload) {
    state.contactsFormErrors = Object.assign(state.contactsFormErrors, payload)
  },
  clearContactsFormErrors (state) {
    state.contactsFormErrors = {
      name: null,
      phone: null,
      comment: null,
    }
  },
  saveCooperationFormErrors (state, payload) {
    state.contactsFormErrors = Object.assign(state.cooperationFormErrors, payload)
  },
  clearCooperationFormErrors (state) {
    state.cooperationFormErrors = {
      organisationName: null,
      name: null,
      phone: null,
      inn: null,
      text: null,
      doc: null,
    }
  },
}

const actions = {
  async sendVacancyForm ({ commit }, payload) {
    await commit('setStatus', 'fetch')
    await commit('clearVacancyFormErrors')
    try {
      await this.$axios.post('/mail/vacancy', payload)
      await commit('setStatus', 'vacancy-form-successfully-fetched')
    } catch (e) {
      if (e.response.data.code === 422) {
        await commit('saveVacancyFormErrors', e.response.data.data)
        await commit('setStatus', 'vacancy-form-not-fetched')
      }
      console.warn('error', e.response)
    }
  },
  async sendContactsForm ({ commit }, payload) {
    await commit('setStatus', 'fetch')
    await commit('clearContactsFormErrors')
    try {
      await this.$axios.post('/mail/feedback', payload)
      await commit('setStatus', 'contact-form-successfully-fetched')
    } catch (e) {
      if (e.response.data.code === 422) {
        await commit('saveContactsFormErrors', e.response.data.data)
        await commit('setStatus', 'contact-form-not-fetched')
      }
      console.warn('error', e.response)
    }
  },
  async sendCooperationForm ({ commit }, payload) {
    await commit('setStatus', 'fetch')
    await commit('clearCooperationFormErrors')
    try {
      await this.$axios.post('/mail/cooperation', payload)
      await commit('setStatus', 'cooperation-form-successfully-fetched')
    } catch (e) {
      if (e.response.data.code === 422) {
        await commit('saveCooperationFormErrors', e.response.data.data)
        await commit('setStatus', 'cooperation-form-not-fetched')
      }
      console.warn('error', e.response)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

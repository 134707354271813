import axios from 'axios'

const state = () => ({
  history: [],
  active: [],
})

const mutations = {
  setHistory (state, payload) { state.history = payload },
  setActive (state, payload) { state.active = payload },
}

const actions = {
  fetchOrderById ({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      axios.get(rootGetters['api/getOrderById'](id)).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  fetchHistory ({ commit, rootGetters }, id) {
    return this.$axios
      .get(rootGetters['api/getHistoryOrders'])
      .then((res) => {
        commit('setHistory', res.data)
        return res.data
      })
  },
  fetchActive ({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      axios.get(rootGetters['api/getActiveOrders']).then((res) => {
        commit('setActive', res.data)
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getThankPageDetails ({ commit, rootGetters }, id) {
    return new Promise((resolve, reject) => {
      axios.get(rootGetters['api/getThankPageDetails'](id)).then((res) => {
        commit('setActive', res.data)
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}

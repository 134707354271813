<template>
  <div :class="{ fixed, absolute }" class="loader-component">
    <div />
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
    absolute: Boolean,
  },
}
</script>

<style lang="less" scoped>
	.loader-component {
		position: static;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 100000;

		div {
			width: 50px;
			height: 50px;
			margin: 2rem;
			border-radius: 50%;
			border: 0.3rem solid #E31E24;
			border-top-color: rgba(#E31E24, 0.2);
			animation: 1s spin infinite ease;

			@keyframes spin {
				from {
					transform: rotate(0);
				}
				to {
					transform: rotate(360deg);
				}
			}
		}

		&.absolute,
		&.fixed {
			background-color: rgba(255, 255, 255, 1);
		}

		&.absolute {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		&.fixed {
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
</style>

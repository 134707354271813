import axios from 'axios'

const state = () => ({
  addresses: [],
  marks: [
    { active: true, name: 'home', icon: 'home' },
    { active: false, name: 'favorite', icon: 'heart' },
    { active: false, name: 'friends', icon: 'user-friends' },
    { active: false, name: 'office', icon: 'briefcase' },
    { active: false, name: 'list', icon: 'list-alt' },
  ],
})

const getters = {
  getAddresses (state) {
    return state.addresses
  },

  getAddressIconByName: state => (name) => {
    const iconItem = state.marks.find(a => a.name === name || a.icon === name)
    return iconItem ? iconItem.icon : state.marks[0].icon
  },
}

const mutations = {
  setAddresses (state, payload) { state.addresses = payload },
  pushNewAddress (state, payload) { state.addresses.push(payload) },
  updateAddress (state, payload) {
    state.addresses = state.addresses.map((v) => {
      if (v.id === payload.id) {
        return payload
      }

      return v
    })
  },
  filterAddress (state, payload) {
    state.addresses = state.addresses.filter(v => v.id !== payload)
  },
}

const actions = {
  /**
	 * Получить все адреса
	 * @param {*} param0
	 */
  fetchAddresses ({ commit, rootGetters }) {
    return axios.get(rootGetters['api/addressGetAddresses']).then((res) => {
      commit('setAddresses', res.data.items)
      return res.data
    }).catch(err => err)
  },
  /**
	 * Создать адрес
	 * @param {*} param0
	 * @param {
	 * 	city: string,
	 * 	house: number || string,
	 * 	entry: string,
	 * 	apartmentNumber: number || string,
	 * 	name: string
	 * } data
	 */
  createAddress ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios.post(rootGetters['api/addressCreateAddress'], data).then((res) => {
        commit('pushNewAddress', res.data.data)
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  /**
	 * Обновить адрес
	 * @param {*} param0
	 * @param {*} data
	 */
  updateAddress ({ commit, rootGetters }, data) {
    return new Promise(function (resolve, reject) {
      axios.patch(rootGetters['api/addressEditAddress'](data.id), data.data).then((res) => {
        commit('updateAddress', res.data.data)
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  /**
	 * Удалить адрес
	 * @param {*} param0
	 * @param {number} id
	 */
  deleteAddress ({ commit, rootGetters }, id) {
    return new Promise(function (resolve, reject) {
      axios.delete(rootGetters['api/addressDeleteAddress'](id)).then((res) => {
        // console.log(res)
        commit('filterAddress', id)
        resolve(res.data)
      }).catch((err) => {
        console.log(err)
        reject(err)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

<template>
  <div class="new-year-modal-component">
    <client-only>
      <div>
        <modal :open="showNewYearModal" @close="close" initial-width="1100px">
          <div class="modal-content row">
            <div class="col-md-6">
              <div class="description">
                <div class="m-title">
                  Уважаемые клиенты!
                </div>
                <div class="sub-title">
                  Обращаем Ваше внимание, что сейчас наблюдаются технические проблемы с доставкой SMS сообщений абонентам Мегафон и YOTA.
                  <br><br>

                  Клиентам СУШИФОКС, являющимся абонентами данных провайдеров связи, в случае проблем с оформлением заказа, просим звонить в наш колл-центр
                  <a
                    :href="'tel:' + $region.getPhone()"
                    @click="$store.dispatch('events/phoneClick')"
                  >
                    {{ $region.getPhone() }}
                  </a>
                </div>
                <!--                <div class="working-hours">31 декабря до 20:00 <br>
                  01 января с 15:00
                </div>-->
                <div>
                  <Button @click="close" class="button-close">
                    Спасибо, буду знать!
                  </Button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="image">
                <img src="/modals/fox-new-year-popup.jpg" alt="">
              </div>
            </div>
          </div>
        </modal>
      </div>
    </client-only>
  </div>
</template>

<script>
import Button from '@/components/UI/Button'
import { mapGetters } from 'vuex'
import Modal from '~/components/page/Modal/Modal'
import { getPhoneByUtm } from '~/utils/getPhoneByUtm'

export default {
  name: 'NewYearModal',
  comments: {
    Modal,
    Button,
  },
  components: { Modal, Button },
  data () {
    return {
      phoneNumber: '+7 (495) 150-55-22',
    }
  },
  computed: {
    ...mapGetters({
      showNewYearModal: 'workTime/showNewYearModal',
    }),
  },
  created () {
    // if (process.client) {
    this.phoneNumber = getPhoneByUtm()
    // }
  },
  methods: {
    close () {
      this.$store.commit('workTime/hideNewYearModal')
    },
  },
}
</script>

<style lang="less" scoped>
.new-year-modal-component {
  ::v-deep {
    .modal_body {
      padding: 0;
    }
  }

  .description {
    min-height: 100%;
    padding: 40px 60px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .m-title {
      font-size: 30px;
      font-family: 'Roboto Slab', Arial, sans-serif;
      font-weight: 500;
      line-height: 1;
      margin-bottom: 25px;
      padding-top: 20px;
    }

    .sub-title {
      font-size: 16px;
      line-height: 1.2;
      margin-bottom: 15px;
    }

    .working-hours {
      font-family: 'Roboto Slab', Arial, sans-serif;
      font-weight: 500;
      font-size: 25px;
      line-height: 1.3;
      margin-bottom: 60px;
    }

  }

  .image {

    img {
      max-width: 100%;
      display: block;
      padding: 0;
      margin: 0;
    }

  }
  .button-close {
    display: inline-block;
  }

  @media screen and (max-width: 768px) {

    .row {
      flex-direction: column-reverse;
    }

    .description {
      min-height: 0;
      padding: 15px;
      text-align: center;
      display: block;

      .m-title {
        padding-top: 10px;
        font-size: 28px;
      }

      .working-hours {
        font-size: 20px;
        margin-bottom: 25px;
      }
    }

    .button-close {
      width: 100%;
    }
  }

  ::v-deep {
    .modal_wrap {
      width: calc(100% - 30px);
    }
  }
}

</style>

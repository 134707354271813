<template>
  <form ref="search" @submit.prevent="_handleSearch" class="header_main_search">
    <div class="header_main_search_inner">
      <input
        v-model="search"
        :placeholder="getPlaceholder"
        @blur="closeOptions"
        @input="submitSeacrhByInput"
        type="text"
        class="header_main_search_input"
      >
      <button class="header_main_search_submit" type="submit">
        <font-awesome-icon :icon="['fas', 'search']" />
      </button>
    </div>
    <div v-if="open" class="header_main_search_list">
      <perfect-scrollbar :settings="scrollbar">
        <div
          v-for="(v, i) in options"
          :key="`serachoption-${ i }`"
          @click="$router.push({ name: 'menu-name-single', params: {
            id: v.id,
            name: v.categories[0].slug,
            single: v.slug
          }})"
          class="header_main_search_option"
        >
          <div class="header_main_search_option_image">
            <img v-if="v.image" :src="v.image" alt="">
          </div>
          <div class="header_main_search_option_name">
            <span v-if="v.categories">{{ v.categories[0].name }}</span>
            <p>{{ v.name }}</p>
          </div>
          <div class="header_main_search_option_price">
            <p>{{ v.price }}<Ruble /></p>
          </div>
        </div>
      </perfect-scrollbar>
      <div v-if="!options.length" class="header_main_search_empty">
        <svg
          id="Capa_1"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 567.419 567.419"
          style="enable-background:new 0 0 567.419 567.419;"
          xml:space="preserve"
        ><g>	<g>		<g id="group-3svg">			<path id="path-1_21_" d="M409.93,209.297c0,18.056-14.627,32.692-32.683,32.692c-18.047,0-32.683-14.636-32.683-32.692				s14.636-32.683,32.683-32.683C395.303,176.614,409.93,191.241,409.93,209.297z" />			<path id="path-2_21_" d="M222.011,209.297c0,18.056-14.636,32.692-32.683,32.692s-32.683-14.636-32.683-32.692				s14.636-32.683,32.683-32.683S222.011,191.241,222.011,209.297z" />			<path id="path-3_21_" d="M172.538,453.621c-7.434,0-13.468-6.025-13.468-13.468c0-58.138,46.762-105.439,104.245-105.439h45.397				c57.483,0,104.245,47.04,104.245,104.855c0,7.443-6.025,13.477-13.468,13.477c-7.435,0-13.459-6.034-13.459-13.477				c0-42.955-34.685-77.91-77.317-77.91h-45.397c-42.632,0-77.308,35.206-77.308,78.493				C186.006,447.596,179.972,453.621,172.538,453.621z" />			<path id="path-4_21_" d="M283.714,567.419c-156.403,0-283.642-127.275-283.642-283.714C0.072,127.275,127.311,0,283.714,0				c156.394,0,283.633,127.275,283.633,283.705C567.347,440.143,440.108,567.419,283.714,567.419z M283.714,26.946				c-141.552,0-256.705,115.181-256.705,256.759c0,141.588,115.154,256.768,256.705,256.768				c141.543,0,256.696-115.181,256.696-256.768C540.41,142.126,425.257,26.946,283.714,26.946z" />		</g>	</g></g><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /></svg>
        <p>Нет результатов</p>
      </div>
    </div>
  </form>
</template>

<script>
import Ruble from '@/components/UI/Ruble'

export default {
  components: {
    Ruble,
  },
  data () {
    return {
      placeholders: [ 'филадельфия' /* 'Пеперони', 'Ролл с тунцом', 'Калифорния' */ ],
      options: [],
      search: '',
      open: false,
      scrollbar: {
        wheelPropagation: false,
      },
    }
  },
  computed: {
    getPlaceholder () {
      return `Например: ${this.placeholders[this.randomInteger(0, this.placeholders.length - 1)]}`
    },
  },
  watch: {
    search (newVal) {
      if (newVal) { this.open = true } else { this.open = false }
    },
  },
  methods: {
    randomInteger (min, max) {
      const rand = min - 0.5 + Math.random() * (max - min + 1)
      return Math.round(rand)
    },
    _handleSearch () {
      if (this.search) {
        this.$store.dispatch('menu/search', this.search).then(({ items }) => {
          this.options = items
        }).catch((err) => {
          this.options = []
          console.error('header main search - menu/search - error', err)
        })
      } else {
        this.options = []
      }
    },
    submitSeacrhByInput () {
      if (this.search.length >= 3) {
        this._handleSearch()
      }
    },
    closeOptions () {
      setTimeout(() => {
        this.open = false
      }, 200)
    },
  },
}
</script>

<style lang="less">
.header_main_search {
	&_inner {
		width: 275px;
		position: relative;

		@media screen and (max-width: 1200px) { width: 215px; }
		@media screen and (max-width: 992px) { width: 40px; }
	}

	&_input {
		width: 100%;
		height: 40px;
		background-color: #F2F2F2;
		padding-left: 20px;
		padding-right: 50px;
		border-radius: 40px;
		font-family: 'Roboto', Arial, sans-serif;
		font-size: 12px;
		color: #B5B5B5;
		border: none;
		box-sizing: border-box;

		@media screen and (max-width: 992px) {
			padding-left: 0;
			padding-right: 0;
		}

		&::placeholder {
			font-family: 'Roboto', Arial, sans-serif;
			font-size: 12px;
			color: #B5B5B5;
		}
	}

	&_submit {
		width: 40px;
		height: 40px;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: black;
		border: none;
		border-radius: 50%;
		box-shadow: 0 3px 6px rgba(0, 0, 0, .09);
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}

	&_list {
		width: 77%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		background-color: white;
		padding: 40px 0 23px 0;
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 20px;
		overflow: hidden;
		position: absolute;
		top: 100%;
		right: 0;

		@media screen and (max-width: 1400px) { width: 78%; }
		@media screen and (max-width: 1200px) { width: 75%; }

		.ps {
			width: 100%;
			max-height: 300px;
		}
	}

	&_option {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px 20px;
		cursor: pointer;

		&:hover { background: #F8F8F8; }

		&_image {
			width: 90px;
			height: 65px;
			flex-grow: 0;
			flex-shrink: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center;
			}
		}

		&_name {
			flex-grow: 1;
			padding: 0 20px 0 40px;

			span {
				font-family: 'Roboto', Arial, sans-serif;
				font-weight: 300;
				font-size: 14px;
				color: #C9C9C9;
				line-height: 20px;
			}

			p {
				font-family: 'Roboto', Arial, sans-serif;
				font-weight: 500;
				font-size: 20px;
				color: black;
			}
		}

		&_price {
			p {
				font-family: 'Roboto', Arial, sans-serif;
				font-weight: 500;
				font-size: 20px;
				color: black;
			}
		}
	}

	&_empty {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px 0;

		svg {
			width: 70px;
			height: 70px;
			margin-bottom: 20px;

			g { fill: #ddd; }
		}

		p {
			font-family: 'Roboto', Arial, sans-serif;
			font-weight: normal;
			font-size: 18px;
			color: #ddd;
			text-align: center;
			line-height: 1.2;
		}
	}
}
</style>

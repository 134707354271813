<template>
  <button
    :class="{'button__block': block}"
    :disabled="isDisabledState"
    @click="$emit('click')"
    class="button"
  >
    <loader :fixed="false" v-if="loading" />
    <slot v-else />
  </button>
</template>

<script>
import Loader from '~/components/UI/PageLoader'

export default {
  components: { Loader },
  props: {
    disabled: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisabledState () {
      return this.disabled || this.loading
    },
  },
}
</script>

<style lang="less">
.button {
  max-width: 100%;
  min-width: 140px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E31E24;
  background: linear-gradient(180deg, #fc5964 0%, #e30615 100%);
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  color: white;
  text-decoration: none;
  padding: 10px 25px;
  border: none;
  border-radius: 15px;
  cursor: pointer;

  svg {
    font-size: 16px;
    margin-right: 7px;
  }

  &_border {
    border: 7px solid #F6F6F6;
  }

  &_outline {
    background: transparent;
    border: 1px solid #E31E24;
    color: #E31E24;
  }

  &_icon {
    text-align: left;

    svg {
      font-size: 20px;
      color: white;
      margin-right: 25px;
    }
  }

  /** Размеры */

  &_mid {
    height: 50px;
  }

  &_small {
    height: 40px;
    border-radius: 10px;
  }

  &_nano {
    height: 36px;
    border-radius: 10px;
  }

  /** Цвета */

  &_light {
    background: #F2F2F2;
    color: black;

    &.button_outline {
      background: transparent;
      border: 1px solid #F2F2F2;
    }
  }

  /** Состояния */

  &:disabled {
    opacity: .75;
    cursor: not-allowed;
  }

  .loader-component {
    div {
      height: 25px;
      width: 25px;
      border: 0.1rem solid #e14e51;
      border-top-color: rgba(#ff9100, 0.2);
    }
  }

  &__block {
    display: block;
    width: 100%;
  }

}
</style>

<template>
  <div class="footer_navigation">
    <div v-if="title" @click="listVisible = !listVisible" class="footer_navigation_title">
      <p>{{ title }}</p>
      <template v-if="windowWidth < 768">
        <font-awesome-icon :icon="['fas', 'sort-up']" v-if="listVisible" />
        <font-awesome-icon :icon="['fas', 'sort-down']" v-else />
      </template>
    </div>
    <transition name="fade">
      <div v-show="isListVisible" class="footer_navigation_list">
        <template v-for="(val, i) in navItems">
          <a
            v-if="val.external"
            :key="`footnav-${ i }`"
            :href="val.path"
            :class="[ { soon: val.coming }, val.class ]"
            target="blank"
            rel="nofollow"
          >
            {{ val.name }}
          </a>
          <nuxt-link
            v-else
            :key="`footnav-${ i }`"
            :to="val.path"
            :class="[ { soon: val.coming }, val.class ]"
            :target="getTargetAttr(val)"
            :rel="getRelAttr(val)"
            no-prefetch
            tag="a"
          >
            {{ val.name }}
          </nuxt-link>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    navItems: {
      type: Array,
      default () {
        return []
      },
    },
  },
  data () {
    return {
      windowWidth: 1200,
      listVisible: false,
    }
  },
  computed: {
    isListVisible () {
      return !!(((this.windowWidth <= 768 && this.listVisible) || this.windowWidth > 768))
    },
  },
  mounted () {
    if (process.client) {
      this.refreshWindowWidth()
      window.addEventListener('resize', this.refreshWindowWidth)
    }
  },
  beforeDestroy () {
    if (process.client) {
      window.removeEventListener('resize', this.refreshWindowWidth)
    }
  },
  methods: {
    refreshWindowWidth () {
      this.windowWidth = document.documentElement.clientWidth
    },
    getTargetAttr (link) {
      return link.external ? '_blank' : false
    },
    getRelAttr (link) {
      return link.external ? 'nofollow' : false
    },
  },
}
</script>

<style lang="less">
.footer_navigation {
  color: white;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_title {
    margin-bottom: 24px;

    @media screen and (max-width: 768px) {
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);

        &[data-icon="sort-down"] {
          top: calc(50% - 1px);
        }

        &[data-icon="sort-up"] {
          top: calc(50% + 5px);
        }
      }
    }

    p {
      font-family: 'Aqum', Arial, sans-serif;
      font-weight: 500;
      font-size: 16px;

      @media screen and (max-width: 768px) {
        text-align: center;
        border-bottom: 1px dashed white;
      }
    }
  }

  &_list {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    @media screen and (max-width: 768px) {
      align-items: center;
      margin-bottom: 45px;
    }

    a {
      display: inline-block;
      font-family: 'Roboto', Arial, sans-serif;
      font-size: 16px;
      color: white;
      text-decoration: none;

      @media screen and (max-width: 768px) {
        text-align: center;
      }

      &:not(:last-child) {
        margin-bottom: 14px;
      }

      &.soon {
        position: relative;

        &:after {
          content: 'скоро';
          display: inline-block;
          font-family: 'Roboto', Arial, sans-serif;
          font-size: 12px;
          color: #E31E24;
          position: absolute;
          top: -4px;
          right: -38px;
        }
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>

import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

    import  { faVk as fortawesomefreebrandssvgicons_faVk } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faVk)

    import  { faInstagram as fortawesomefreebrandssvgicons_faInstagram } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faInstagram)

    import  { faFacebook as fortawesomefreebrandssvgicons_faFacebook } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faFacebook)

    import  { faOdnoklassniki as fortawesomefreebrandssvgicons_faOdnoklassniki } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faOdnoklassniki)

    import  { faTwitter as fortawesomefreebrandssvgicons_faTwitter } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faTwitter)

    import  { faTelegram as fortawesomefreebrandssvgicons_faTelegram } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faTelegram)

    import  { faUserAlt as fortawesomefreesolidsvgicons_faUserAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faUserAlt)

    import  { faPhoneAlt as fortawesomefreesolidsvgicons_faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPhoneAlt)

    import  { faSearch as fortawesomefreesolidsvgicons_faSearch } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSearch)

    import  { faShoppingCart as fortawesomefreesolidsvgicons_faShoppingCart } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faShoppingCart)

    import  { faSortUp as fortawesomefreesolidsvgicons_faSortUp } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSortUp)

    import  { faSortDown as fortawesomefreesolidsvgicons_faSortDown } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSortDown)

    import  { faWeightHanging as fortawesomefreesolidsvgicons_faWeightHanging } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faWeightHanging)

    import  { faHeart as fortawesomefreesolidsvgicons_faHeart } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faHeart)

    import  { faRubleSign as fortawesomefreesolidsvgicons_faRubleSign } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faRubleSign)

    import  { faTimes as fortawesomefreesolidsvgicons_faTimes } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTimes)

    import  { faExclamation as fortawesomefreesolidsvgicons_faExclamation } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faExclamation)

    import  { faPlus as fortawesomefreesolidsvgicons_faPlus } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPlus)

    import  { faMinus as fortawesomefreesolidsvgicons_faMinus } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMinus)

    import  { faCheck as fortawesomefreesolidsvgicons_faCheck } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCheck)

    import  { faClock as fortawesomefreesolidsvgicons_faClock } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faClock)

    import  { faCopy as fortawesomefreesolidsvgicons_faCopy } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCopy)

    import  { faIdBadge as fortawesomefreesolidsvgicons_faIdBadge } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faIdBadge)

    import  { faStar as fortawesomefreesolidsvgicons_faStar } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faStar)

    import  { faHistory as fortawesomefreesolidsvgicons_faHistory } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faHistory)

    import  { faHome as fortawesomefreesolidsvgicons_faHome } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faHome)

    import  { faUserFriends as fortawesomefreesolidsvgicons_faUserFriends } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faUserFriends)

    import  { faBriefcase as fortawesomefreesolidsvgicons_faBriefcase } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faBriefcase)

    import  { faListAlt as fortawesomefreesolidsvgicons_faListAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faListAlt)

    import  { faCalendarAlt as fortawesomefreesolidsvgicons_faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCalendarAlt)

    import  { faMoneyBillWave as fortawesomefreesolidsvgicons_faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMoneyBillWave)

    import  { faMapMarker as fortawesomefreesolidsvgicons_faMapMarker } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMapMarker)

    import  { faMapMarkerAlt as fortawesomefreesolidsvgicons_faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMapMarkerAlt)

    import  { faDownload as fortawesomefreesolidsvgicons_faDownload } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faDownload)

    import  { faEdit as fortawesomefreesolidsvgicons_faEdit } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faEdit)

    import  { faSignOutAlt as fortawesomefreesolidsvgicons_faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSignOutAlt)

    import  { faShoppingBasket as fortawesomefreesolidsvgicons_faShoppingBasket } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faShoppingBasket)

    import  { faTruck as fortawesomefreesolidsvgicons_faTruck } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTruck)

    import  { faChevronRight as fortawesomefreesolidsvgicons_faChevronRight } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChevronRight)

    import  { faTrash as fortawesomefreesolidsvgicons_faTrash } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTrash)

    import  { faClock as fortawesomefreeregularsvgicons_faClock } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faClock)

    import  { faEdit as fortawesomefreeregularsvgicons_faEdit } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faEdit)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

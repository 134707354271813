import axios from 'axios'
import getTopMenuIcon from '~/utils/getTopMenuIcon'

const state = () => ({
  single: null,
  collections: [],
  categories: [],
  topMenuCategories: [],
  category: null,
  activeMenuId: '',
})

const mutations = {
  setSingle (state, payload) {
    state.single = payload
  },
  setCollections (state, payload) {
    state.collections = payload
  },
  setCategories (state, payload) {
    state.categories = payload
  },
  setTopMenuCategories (state, payload) {
    state.topMenuCategories = payload
  },
  setCategory (state, payload) {
    state.category = payload
  },
  setActiveMenuId (state, payload) {
    state.activeMenuId = payload
  },
}

const actions = {
  setActiveMenu ({ commit }, id) {
    commit('setActiveMenuId', id)
  },

  /**
   * Поиск блюд
   * @param {*} param0
   * @param {*} id
   */
  search ({ rootGetters }, text) {
    return new Promise((resolve, reject) => {
      axios.get(rootGetters['api/menuSearch'], {
        params: {
          search: 'name',
          text,
          relations: 'categories',
        },
      }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  /**
   * Получить детальную информацию о товаре
   * @param {*} param0
   * @param {number} id
   */
  fetchSingle ({ commit, rootGetters }, id) {
    return axios.get(rootGetters['api/menuSingle']).then((res) => {
      commit('setSingle', res.data)
      return res.data
    }).catch((err) => {
      return err.data
    })
  },
  /**
   * Получить коллекцию
   * @param {*} param0
   * @param {
   * 	type: string,
   * 	target: string
   * } data
   */
  fetchCollections ({ commit, rootGetters }, { target, type, onlyList }) {
    return this.$axios
      .get(rootGetters['api/menuSingleCollection'](target, type, onlyList))
      .then((res) => {
        commit('setCollections', res.data)
        return res.data
      })
      .catch(err => err.data)
  },

  /**
   * Поулчить категорю
   * @param {*} param0
   * @param {number} id
   */
  fetchCategory ({ commit, rootGetters }, id) {
    return axios.get(rootGetters['api/menuCategory']).then((res) => {
      commit('setCategory', res.data)
      return res.data
    }).catch((err) => {
      return err.data
    })
  },
  /**
   * Получить категории
   * @param {*} param0
   */
  fetchCategories ({ commit, rootGetters }) {
    return axios.get(rootGetters['api/menuCategories']).then((res) => {
      commit('setCategories', res.data)
      return res.data
    }).catch((err) => {
      return err.data
    })
  },

  fetchTopMenuCategories ({ commit, rootGetters }) {
    return this.$axios.get(rootGetters['api/topMenuCategories'])
      .then((res) => {
        commit('setTopMenuCategories', res.data.items)
        return res.data.items
      })
  },
}

const getters = {
  getActiveMenu (state) {
    return state.activeMenuId
  },

  getTopMenu (state) {
    // { path: '/menu/rolls', icon: '/header-menu/2/rolls.png', name: 'Роллы', id: 'rolls', active: false },
    return state.topMenuCategories.map(c => ({
      path: `/menu/${c.slug}`,
      icon: getTopMenuIcon(c),
      name: c.name,
      id: c.slug,
      active: false,
    }))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

<template>
  <div
    :class="{ 'header_cats--short': isFloatActive }"
    :style="{ overflow: (isFloatActive) ? 'unset' : 'hidden' }"
    class="header_cats"
  >
    <div class="container">
      <div class="row header_cats_row">
        <div v-for="(item, i) in menu" :key="`menu-${ i }`" v-if="isMenuPage" class="col">
          <div
            :class="{ 'active': item.id === getActiveId }"
            @click="goTo(item.path, item.id)"
            class="header_cats_item"
          >
            <div
              :style="{backgroundImage: `url(${item.icon})`}"
              :class="item.id"
              class="header_cats_item_pic"
            />
            <p>{{ item.name }}</p>
          </div>
        </div>
        <div v-for="(item, i) in menu" :key="`menu-${ i }`" v-if="!isMenuPage" class="col">
          <div
            :class="{ 'active': item.id === getActiveId && isMenuPage }"
            class="header_cats_item"
          >
            <nuxt-link :to="item.path">
              <div
                :class="item.id"
                :style="{backgroundImage: `url(${item.icon})`}"
                class="header_cats_item_pic"
              />
              <p>{{ item.name }}</p>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    float: {
      type: Boolean,
      default: () => false,
    },
    isFloatActive: {
      type: Boolean,
      default: () => false,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      menu: 'menu/getTopMenu',
    }),
    isMenuPage () {
      return this.$route.name === 'menu-name'
    },
    getActiveId () {
      return this.$store.getters['menu/getActiveMenu']
    },
  },
  methods: {
    goTo (path, id) {
      if (this.$route.path.includes(id)) {
        const el = document.getElementById(id)
        if (el) {
          const top = (el.getBoundingClientRect().top + pageYOffset) - 180

          window.scrollTo({
            top,
            behavior: 'smooth',
          })
        }
      } else {
        this.$router.push(path)
      }
    },
  },
}
</script>

<style lang="less">
.header_cats {
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    display: none;
  }

  &_row {
    & > .col {
      display: flex;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    a {
      text-decoration: none;
    }

    &_pic {
      width: 100px;
      height: 100px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transition: height .25s ease;

      @media screen and (max-width: 1200px) {
        width: 90px;
        height: 90px;
      }

      @media screen and (max-width: 992px) {
        width: 70px;
        height: 70px;
      }

      /* @media
      only screen and (-webkit-min-device-pixel-ratio: 2),
      only screen and (   min--moz-device-pixel-ratio: 2),
      only screen and (     -o-min-device-pixel-ratio: 2/1),
      only screen and (        min-device-pixel-ratio: 2),
      only screen and (                min-resolution: 192dpi),
      only screen and (                min-resolution: 2dppx) {
        &.rolls { background-image: url(../../../assets/images/cat/roll-x2-2.png); }
        &.sushi { background-image: url(../../../assets/images/cat/sushi-x2.png); }
        &.sets { background-image: url(../../../assets/images/cat/set-x2-2.png); }
        &.pizza { background-image: url(../../../assets/images/cat/pizza-x2.png); }
        &.combo { background-image: url(../../../assets/images/cat/combo-x2.png); }
        &.salad { background-image: url(../../../assets/images/cat/salad-x2.png); }
        &.soup { background-image: url(../../../assets/images/cat/soup-x2.png); }
        &.snacks { background-image: url(../../../assets/images/cat/snacks-x2.png); }
        &.drinks { background-image: url(../../../assets/images/cat/mors-x2.png); }
        &.sauce { background-image: url(../../../assets/images/cat/sauces-x2.png); }
        &.sets-combo { background-image: url(../../../assets/images/cat/set-combo-3@2x.png); }
        &.hot { background-image: url(../../../assets/images/cat/hot-2@2x.png); }
      } */
    }

    p {
      font-family: 'Aqum', Arial, sans-serif;
      font-weight: bold;
      font-size: 10px;
      white-space: nowrap;
      color: black;
      text-align: center;
      padding-bottom: 12px;
      transition: color .3s;

      @media screen and (max-width: 1200px) {
        font-size: 16px;
      }
      @media screen and (max-width: 992px) {
        font-size: 14px;
      }
    }

    &:hover {
      background-color: white;
      border-radius: 14px;
      box-shadow: 0 18px 20px -10px rgba(0, 0, 0, .11);

      p {
        color: #E31E24;
      }
    }
  }

  &--short {
    margin-bottom: 0;

    .header_cats_item {
      &_pic {
        height: 0;
      }

      p {
        padding-bottom: 20px;
      }

      &:hover, &.active {
        background-color: unset;
        border-radius: 0;
        box-shadow: none;

        p {
          color: #E31E24;
          position: relative;

          &:after {
            content: '';
            width: 100%;
            height: 4px;
            background-color: #E31E24;
            border-radius: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}
</style>

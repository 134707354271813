<template>
  <div :style="{ overflow: (isFloatActive) ? 'unset' : 'hidden' }" class="header_float_wrap">
    <transition name="hfloat">
      <div v-if="isFloatActive" class="header_float">
        <div class="container">
          <div class="row header_float_navigation">
            <div
              v-for="(val, i) in nav"
              :key="`floathnav-${ i }`"
              class="col"
            >
              <nuxt-link
                :class="{ 'active': val.active }"
                :to="val.path"
                tag="div"
                class="header_float_navigation_item"
              >
                <span>{{ val.name }}</span>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {
      nav: [
        { path: '/menu/rolls', name: 'Роллы', id: 'rolls', active: false },
        { path: '/menu/sushi', name: 'Cуши', id: 'sushi', active: false },
        { path: '/menu/set', name: 'Сеты', id: 'set', active: false },
        { path: '/menu/pizza', name: 'Пицца', id: 'pizza', active: false },
        { path: '/menu/combo', name: 'Комбо', id: 'combo', active: false },
        { path: '/menu/salad', name: 'Салаты', id: 'salad', active: false },
        { path: '/menu/soup', name: 'Супы', id: 'soup', active: false },
        { path: '/menu/snack', name: 'Закуски', id: 'snacks', active: false },
        { path: '/menu/drink', name: 'Напитки', id: 'drink', active: false },
        { path: '/menu/sauce', name: 'Соусы', id: 'sauces', active: false },
      ],
      screenHeight: 0,
    }
  },
  computed: {
    isFloatActive () {
      const headerHeight = (process.client) ? document.getElementsByClassName('header')[0].clientHeight : 0
      return this.screenHeight > headerHeight
    },
    getMenuElementsArray () {
      const elements = (process.client) ? document.getElementsByClassName('js-floatheader-observer') : []
      const elementsArray = []

      if (process.client) {
        for (const iterator of elements) {
          elementsArray.push({
            id: iterator.getAttribute('id'),
            scrollTop: iterator.getBoundingClientRect().top + pageYOffset,
          })
        }
      }

      return elementsArray
    },
  },
  mounted () {
    if (process.client) {
      window.addEventListener('scroll', this.observeFloatHeaderNavigation)
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.observeFloatHeaderNavigation)
  },
  methods: {
    observeFloatHeaderNavigation () {
      const posTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
      this.screenHeight = posTop

      this.getMenuElementsArray.forEach((iterator) => {
        if (posTop >= iterator.scrollTop) {
          this.nav.forEach((val) => {
            val.active = false
            if (iterator.id == val.id) { val.active = true }
          })
        }
      })
    },
  },
}
</script>

<style lang="less">
.header_float {
	width: 100%;
	height: 54px;
	display: flex;
	align-items: flex-end;
	background-color: white;
	box-shadow: 0 3px 14px rgba(128, 128, 128, 0.16);

	@media screen and (max-width: 992px) { height: 45px; }
	@media screen and (max-width: 768px) { display: none; }

	&_wrap {
		width: 100%;
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		overflow: hidden;
		z-index: 20;
	}

	&_navigation {
		.col {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&_item {
			display: inline-flex;
			cursor: pointer;

			span {
				display: block;
				font-family: 'Roboto Slab', Arial, sans-serif;
				font-weight: bold;
				font-size: 18px;
				text-align: center;
				padding-bottom: 20px;
				position: relative;

				@media screen and (max-width: 1200px) { font-size: 16px; }
				@media screen and (max-width: 992px) {
					font-size: 14px;
					padding-bottom: 16px;
				}

				&:after {
					content: '';
					height: 0;
					width: 100%;
					background-color: #3600FF;
					position: absolute;
					bottom: 0;
					left: 0;
					transition: height .3s;
				}
			}

			&:hover span, &.active span {
				color: #3600FF;

				&:after {
					height: 5px;
				}
			}
		}
	}
}

.hfloat-enter-active, .hfloat-leave-active {
	transition: opacity .5s;
}
.hfloat-enter, .hfloat-leave-to {
	opacity: 0;
}
</style>

import axios from 'axios'

const state = () => ({})

const getters = {}

const actions = {
  /**
   * Получить подсказки для адресов
   * @param {*} param0
   * @param {string} address
   */
  getSuggestAddress ({ rootGetters }, address) {
    return new Promise((resolve, reject) => {
      axios.get(rootGetters['api/getSuggestAddress'], {
        params: { address, field: 'street' },
      }).then((res) => {
        resolve(res.data.suggests)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  /**
   * Получить подсказки улиц
   * @param {*} param0
   * @param {string} query
   */
  getSuggestStreet ({ rootGetters }, query) {
    return axios.get(rootGetters['api/getSuggestStreet'], {
      params: { query },
    }).then(res => res.data.items)
  },

  /**
   * Получить подсказки домов на улице
   * @param {*} param0
   * @param {string} address
   */
  getSuggestHouse ({ rootGetters }, { query, streetId }) {
    return axios.get(rootGetters['api/getSuggestHouse'], {
      params: { query, streetId },
    }).then(res => res.data.items)
  },

  checkAddressIsDeliveryByString ({ rootGetters }, payload) {
    const params = {}
    if (payload.lat && payload.let) {
      params.lat = payload.lat
      params.let = payload.let
    } else {
      params.address = payload.address
    }
    return new Promise((resolve, reject) => {
      axios.get(rootGetters['api/checkAddressIsDeliveryByString'], {
        params,
      }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import { render, staticRenderFns } from "./HeaderMainCart.vue?vue&type=template&id=2bf8a6cc&"
import script from "./HeaderMainCart.vue?vue&type=script&lang=js&"
export * from "./HeaderMainCart.vue?vue&type=script&lang=js&"
import style0 from "./HeaderMainCart.vue?vue&type=style&index=0&id=2bf8a6cc&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="delivery-form">
    <div class="delivery-form_main">
      <div class="delivery-form_input address">
        <span>Укажите адрес доставки</span>
        <street-input
          @selected="onSelectStreet"
          v-model="streetFull"
          placeholder="Введите адрес"
        />
      </div>
      <div class="delivery-form_input">
        <span>Дом, корпус</span>
        <house-input
          :disabled="isHouseInputDisabled"
          :streetId="streetId"
          v-model="house"
          @selected="onSelectHouse"
          @blur="checkAddress"
          placeholder="Дом, корпус"
        />
      </div>
      <div class="delivery-form_input">
        <span>Кв./офис</span>
        <InputText v-model="apartment" :disabled="!acceptCheckAddress" />
      </div>
      <div class="delivery-form_input">
        <span>Подъезд</span>
        <InputText v-model="hall" :disabled="!acceptCheckAddress" />
      </div>
      <div class="delivery-form_input">
        <span>Этаж</span>
        <InputText v-model="floor" :disabled="!acceptCheckAddress" />
      </div>
      <div class="delivery-form_error">
        {{ errorCheck }}
      </div>
    </div>
    <div class="delivery-form_footer">
      <Button
        v-if="showBack"
        :loading="isLoading"
        @click="onClose"
        class="button_small delivery-form_footer_btn back"
      >
        Вернуться назад
      </Button>
      <Button
        @click="submitAddress"
        :disabled="isSubmitDisabled || !acceptCheckAddress || !isApply"
        :loading="isLoading"
        class="button_small delivery-form_footer_btn submit"
      >
        Добавить
      </Button>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/UI/InputText'
import Button from '@/components/UI/Button'
import _ from 'lodash'
import StreetInput from '~/components/page/Modal/StreetInput'
import HouseInput from '~/components/page/Modal/HouseInput'

export default {
  components: {
    InputText,
    Button,
    StreetInput,
    HouseInput,
  },
  props: ['onClose', 'showBack', 'setTempCoords', 'addNewAddress'],
  data () {
    return {
      street: '',
      streetFull: '',
      house: '',
      streetObj: {},
      houseObj: {},
      address: '',
      apartment: '',
      hall: '',
      stage: '',
      floor: '',
      name: '',
      mark: 'home',
      city: '',
      isLoading: false,
      isApply: false,
      errorCheck: '',
    }
  },
  computed: {
    isHouseInputDisabled () {
      return _.isEmpty(this.streetObj)
    },
    streetId () {
      return this.streetObj.id
    },
    hasGeoData () {
      return this.houseObj.hasGeo
    },
    acceptCheckAddress () {
      return !this.isHouseInputDisabled && this.house
    },
    fullStreetString () {
      return this.streetObj.fullAddress || ''
    },
    fullAddress () {
      return this.fullStreetString + ', ' + this.house
    },
    isSubmitDisabled () {
      return this.isHouseInputDisabled && !this.house
    },
  },
  watch: {
    house () {
      if (this.houseObj && this.houseObj.value !== this.house) {
        this.clearHouseObj()
      }
    },
    streetFull (newValue) {
      if (this.streetObj && this.streetObj.value !== newValue) {
        this.clearStreetObj()
        this.clearHouseObj()
        this.house = null
      }
    },
  },
  created () {
    this.resetData()
  },
  methods: {
    resetData () {
      this.clearStreetObj()
      this.clearHouseObj()
      this.apartment = ''
      this.hall = ''
      this.stage = ''
      this.floor = ''
      this.name = ''
      this.mark = 'home'
    },
    onSelectStreet (obj) {
      this.streetObj = obj
      this.street = obj.name
    },
    onSelectHouse (obj) {
      this.clearHouseObj()
      this.houseObj = obj
    },
    clearHouseObj () {
      this.houseObj = {}
      this.isApply = false
      this.errorCheck = ''
    },
    clearStreetObj () {
      this.streetObj = {}
      this.street = null
      this.streetFull = null
      this.house = null
      this.isApply = false
      this.errorCheck = ''
    },
    checkAddress () {
      const payload = {}
      if (this.hasGeoData) {
        payload.lat = this.houseObj.latitude
        payload.let = this.houseObj.longitude
      } else {
        payload.address = this.fullAddress
      }

      this.$store
        .dispatch('common/checkAddressIsDeliveryByString', payload)
        .then((res) => {
          this.isApply = true
          this.checkedDeliveryData = res.data
          if (res.data?.let && res.data?.lat) {
            this.setTempCoords([res.data.lat, res.data?.let])
          }
        })
        .catch((err) => {
          this.isApply = false
          this.checkedDeliveryData = {}
          this.errorCheck = err?.response?.data?.message || 'Ошибка проверки адреса'
        })
        .finally(() => {
          this.modalOpen = true
        })
    },
    getCity () {
      return this.streetObj.city || null
    },
    getLatitude () {
      return this.hasGeoData ? this.houseObj.latitude : null
    },
    getLongitude () {
      return this.hasGeoData ? this.houseObj.longitude : null
    },
    submitAddress () {
      this.isLoading = true

      const DATA = {
        city: this.getCity(),
        street: this.street,
        house: this.house,
        entry: this.hall,
        stage: this.stage,
        floor: this.floor,
        apartmentNumber: this.apartment,
        name: this.name,
        icoName: 'home',
        houseFullAddress: this.fullAddress,
        latGeo: this.getLatitude(),
        letGeo: this.getLongitude(),
      }

      this.$store
        .dispatch('address/createAddress', DATA)
        .then((data) => {
          // this.$emit('change-new-address', data.data.id)
          this.addNewAddress(data.data)
          this.onClose()
        })
        .catch((err) => {
          let errorMessage = ''
          if (err.response.data && err.response.data.data) {
            for (const i in err.response.data.data) {
              errorMessage += err.response.data.data[i] + ' '
            }
          } else {
            errorMessage = err.response.data.message
          }
          // this.$toast.error(errorMessage, { duration: 3000 })
          this.error = errorMessage
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="less">
.delivery-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px 10px;
  }

  &_input {
    width: 100%;
    position: relative;

    span {
      margin-bottom: 7px;
      display: block;
      font-family: 'Roboto', Arial, sans-serif;
      font-weight: 400;
      font-size: 12px;
      word-break: keep-all;
      white-space: nowrap;
      line-height: 18px;
    }

    input {
      padding-left: 15px;
      padding-right: 15px;
      height: 50px;
      border-radius: 10px;
      background: #f9f9f9;
      font-weight: normal;
      font-size: 14px;
      color: black;
      border: 1px solid transparent;
    }

    &.address {
      grid-column-start: 1;
      grid-column-end: span 2;
    }
  }

  &_form {
    padding-bottom: 100px;

    .input-field-block {
      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
  }

  &_error {
    grid-column-start: 1;
    grid-column-end: span 3;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 10px;
    color: #E31E24;
  }

  &_alert {
    margin-bottom: 60px;

    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;

    &_btn {
      width: 295px;
      height: 46px;
      border-radius: 14px;
      border: 1px solid #E31E24;

      &.back {
        background: #FFF;
        color: #E31E24;
      }

      &.submit {
      }
    }
  }
}

@media (max-width: 1024px) {
  .delivery-form {
    &_main {
      grid-template-columns: repeat(2, 1fr);
    }

    &_input {
      &.address {
        grid-column-start: 1;
        grid-column-end: span 2;
      }
    }

    &_error {
      grid-column-start: 1;
      grid-column-end: span 2;
      margin-bottom: 20px;
    }
  }
}
</style>

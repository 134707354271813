import axios from 'axios'
import AuthService from '../services/auth.service'

const state = () => ({
  initialState: {
    status: {
      loggedIn: false,
    },
    user: null,
  },
  refreshPromise: null,
})

const getters = {
  loggedIn (state) {
    return state.initialState.status.loggedIn
  },
  user (state) {
    return state.initialState.user
  },
  getRefreshPromise (state) {
    return state.refreshPromise
  },
  setRefreshPromise (state, promise) {
    state.refreshPromise = promise
  },
}

const mutations = {
  loginSuccess (state, user) {
    state.initialState.status.loggedIn = true
    state.initialState.user = user.data
  },
  loginFailure (state) {
    state.initialState.status.loggedIn = false
    state.initialState.user = null
  },
  logout (state) {
    state.initialState.status.loggedIn = false
    state.initialState.user = null
  },
  setRefreshPromise (state, promise) {
    state.refreshPromise = promise
  },
}

const actions = {
  sendCode ({ commit, rootGetters }, phone) {
    const formattedPhone = phone ? phone.match(/[0-9]/g).join('') : null
    commit('setLoading', true, { root: true })

    return this.$axios
      .$post(rootGetters['api/authSendCode'], {
        phone: formattedPhone,
      })
      .finally(() => {
        commit('setLoading', false, { root: true })
      })
  },
  login ({ commit, rootGetters }, user) {
    const AS = new AuthService(rootGetters)

    return AS.login(user).then(
      (user) => {
        const EXPIRES_TIME = parseInt(new Date().getTime() / 1000) + 3600
        this.$cookies.set('token', user.token)
        this.$cookies.set('expires_in', EXPIRES_TIME)
        commit('loginSuccess', user)
        return Promise.resolve(user)
      },
      (error) => {
        commit('loginFailure')
        return Promise.reject(error)
      },
    )
  },
  logout ({ commit, rootGetters }) {
    commit('cart/setCart', null, { root: true })
    commit('cart/setCartItems', [], { root: true })
    commit('cart/setCartSummary', 0, { root: true })
    commit('client/setProfile', null, { root: true })
    commit('logout')
    this.$cookies.set('token', '')
    this.$cookies.remove('token')
    localStorage.removeItem('user')
  },

  me ({ commit, rootGetters }) {
    return this.$axios.$post(rootGetters['api/authMe'])
  },

  refresh ({ commit, getters, rootGetters, dispatch }) {
    if (getters.getRefreshPromise) {
      // console.log('exist RefreshPromise')
      return getters.getRefreshPromise
    }

    // console.log('new RefreshPromise')

    const refreshPromise = axios
      .post(rootGetters['api/authRefresh'])
      .then((res) => {
        commit('setRefreshPromise', null)

        const user = JSON.parse(
          localStorage.getItem('user'),
        )
        user.token = res.data.token

        axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`
        this.$cookies.set('tocken')
        localStorage.setItem('token', res.data.token)
        localStorage.setItem(
          'user',
          JSON.stringify(user),
        )
        return res
      })
      .finally(() => {
        dispatch('cart/fetchCart', null, { root: true })
          .catch(err => console.error('axios plugin - cart/fetchCart - error', err))
      })
    commit('setRefreshPromise', refreshPromise)

    return refreshPromise
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

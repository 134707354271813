<template>
  <p :class="['region-delivery-label', {'multi-regions': multiRegionMode}]">
    Доставка <strong @click="showRegionSelect">{{ curRegionNamePadeg }}</strong>
  </p>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'RegionDeliveryLabel',
  computed: {
    ...mapGetters({
      curRegionNamePadeg: 'region/getCurRegionNamePadeg',
    }),
    multiRegionMode () {
      return this.$region.isMultiRegionMode()
    },
  },
  methods: {
    ...mapActions({
      showRegionSelect: 'region/showRegionSelect',
    }),
  },

}
</script>

<style lang="less" scoped>
.region-delivery-label {

  margin-bottom: 5px;

  &.multi-regions {

    strong {
      cursor: pointer;
      display: inline-block;
      padding-bottom: 1px;
      border-bottom: 1px dotted;
      transition: all 0.3s ease;
      font-size: 12px;

      &:hover {
        color: #da2f50;
        border-bottom-color: transparent;
      }
    }
  }
}
</style>

<template>
  <client-only>
    <div class="region-select">
      <Modal
        :open="showModal"
        @close="hideRegionSelect()"
        initial-width="850px"
      >
        <div :class="['region-select_modal', {loading: loading}]">
          <loader
            v-if="loading"
            :fixed="false"
            :absolute="true"
            class="region-select_loader"
          />
          <template v-else>
            <div class="region-select_modal_title">
              Выберите город
            </div>

            <div class="region-select_modal_regions row">
              <div
                v-for="region in allRegions"
                :key="region.id"
                class="region-select_modal_city col-md-3"
              >
                <span @click="changeRegion(region)">{{ region.name }}</span>
              </div>
            </div>
          </template>
        </div>
      </Modal>
    </div>
  </client-only>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Modal from '~/components/page/Modal/Modal'
import Loader from '~/components/UI/PageLoader'

export default {
  name: 'RegionSelect',
  components: { Loader, Modal },
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.region.regionSelectOpen,
      allRegions: state => state.region.all,
    }),
  },
  watch: {
    showModal (newValue) {
      if (newValue) {
        this.loadRegionsList()
      }
    },
  },
  methods: {
    ...mapActions({
      hideRegionSelect: 'region/hideRegionSelect',
    }),
    changeRegion (region) {
      this.loading = true
      this.$region.changeCurrentRegion(region)
        .then(() => {
          this.loading = false
          this.hideRegionSelect()
        })
    },
    loadRegionsList () {
      this.loading = true
      this.$store.dispatch('region/fetchAll')
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="less" scoped>
.region-select {

  &_loader {
    z-index: 10;
  }

  &_modal {
    font-size: 13px;

    &.loading {
      min-height: 300px;
    }

    &_title {
      font-family: 'Aqum', Arial, sans-serif;
      font-weight: 500;
      font-size: 24px;
      color: black;
      margin: 12px 0 30px 0;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

    &_regions {
      @media (max-width: 768px) {
        margin-bottom: 70px;
      }
    }

    &_city {
      margin-bottom: 15px;

      span {
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          color: #E31E24;
        }
      }
    }
  }

}
</style>

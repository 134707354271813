<template>
  <div class="header_collapse_social">
    <a
      :href="val.path"
      v-for="(val, i) in socials"
      :key="`soccl-${ i }`"
      :style="{ 'background-color': val.color }"
      target="_blank"
      class="header_collapse_social_item"
      rel="nofollow"
    >
      <img v-if="val.icon === 'telegram'" src="/icons/telegram.png" alt="">
      <font-awesome-icon :icon="['fab', val.icon]" v-else />
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    socials () {
      return this.$region.getSocials()
    },
  },
}
</script>

<style lang="less">
.header_collapse_social {
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 0 20px;

  &_item {
    width: 37px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 16px;
    color: white;
    overflow: hidden;

    &:not(:last-child) {
      margin-right: 20px;
    }

    img {
      height: 37px;
    }
  }
}
</style>

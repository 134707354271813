import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_nuxtvkretargetingplugin56ba9d40_72e03111 from 'nuxt_plugin_nuxtvkretargetingplugin56ba9d40_72e03111' // Source: ./nuxt-vk-retargeting.plugin.56ba9d40.js (mode: 'client')
import nuxt_plugin_moment_d33cfe8e from 'nuxt_plugin_moment_d33cfe8e' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_toast_0a5f756e from 'nuxt_plugin_toast_0a5f756e' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_veevalidate_a48ecea8 from 'nuxt_plugin_veevalidate_a48ecea8' // Source: ./vee-validate.js (mode: 'all')
import nuxt_plugin_templatespluginbe7d6cb8_a5df2d48 from 'nuxt_plugin_templatespluginbe7d6cb8_a5df2d48' // Source: ./templates.plugin.be7d6cb8.js (mode: 'all')
import nuxt_plugin_axios_3e2f28bf from 'nuxt_plugin_axios_3e2f28bf' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_2e956a67 from 'nuxt_plugin_cookieuniversalnuxt_2e956a67' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_deviceplugin_042d914a from 'nuxt_plugin_deviceplugin_042d914a' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_cookies_1f907659 from 'nuxt_plugin_cookies_1f907659' // Source: ../plugins/cookies (mode: 'all')
import nuxt_plugin_region_3f835c2c from 'nuxt_plugin_region_3f835c2c' // Source: ../plugins/region (mode: 'all')
import nuxt_plugin_regionredirect_691b7036 from 'nuxt_plugin_regionredirect_691b7036' // Source: ../plugins/region-redirect.js (mode: 'client')
import nuxt_plugin_filialsynch_72bd0b18 from 'nuxt_plugin_filialsynch_72bd0b18' // Source: ../plugins/filial-synch.js (mode: 'server')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'client')
import nuxt_plugin_axiosserver_545c56f0 from 'nuxt_plugin_axiosserver_545c56f0' // Source: ../plugins/axios-server (mode: 'server')
import nuxt_plugin_components_58c7edad from 'nuxt_plugin_components_58c7edad' // Source: ../plugins/components.js (mode: 'all')
import nuxt_plugin_ymapPlugin_4bb7856d from 'nuxt_plugin_ymapPlugin_4bb7856d' // Source: ../plugins/ymapPlugin.js (mode: 'client')
import nuxt_plugin_nuxtswiperplugin_82e70236 from 'nuxt_plugin_nuxtswiperplugin_82e70236' // Source: ../plugins/nuxt-swiper-plugin.js (mode: 'client')
import nuxt_plugin_perfectscrollbar_380a0fc5 from 'nuxt_plugin_perfectscrollbar_380a0fc5' // Source: ../plugins/perfect-scrollbar.js (mode: 'all')
import nuxt_plugin_vtooltips_e8248e36 from 'nuxt_plugin_vtooltips_e8248e36' // Source: ../plugins/vtooltips.js (mode: 'client')
import nuxt_plugin_vuedatepicker_20ca9b40 from 'nuxt_plugin_vuedatepicker_20ca9b40' // Source: ../plugins/vue-datepicker.js (mode: 'client')
import nuxt_plugin_vueinputmask_609d3f5c from 'nuxt_plugin_vueinputmask_609d3f5c' // Source: ../plugins/vue-inputmask.js (mode: 'client')
import nuxt_plugin_sourcebuster_7ec97af2 from 'nuxt_plugin_sourcebuster_7ec97af2' // Source: ../plugins/sourcebuster.js (mode: 'client')
import nuxt_plugin_vuesticky_1d008431 from 'nuxt_plugin_vuesticky_1d008431' // Source: ../plugins/vue-sticky.js (mode: 'client')
import nuxt_plugin_vuelazyload_d07c1d40 from 'nuxt_plugin_vuelazyload_d07c1d40' // Source: ../plugins/vue-lazyload.js (mode: 'client')
import nuxt_plugin_vueverification_6131e541 from 'nuxt_plugin_vueverification_6131e541' // Source: ../plugins/vue-verification.js (mode: 'client')
import nuxt_plugin_vueconfirmdialog_87192232 from 'nuxt_plugin_vueconfirmdialog_87192232' // Source: ../plugins/vue-confirm-dialog.js (mode: 'client')
import nuxt_plugin_VueSuggestion_1ce5a3a1 from 'nuxt_plugin_VueSuggestion_1ce5a3a1' // Source: ../plugins/VueSuggestion.js (mode: 'client')
import nuxt_plugin_libnuxtclientinitpluginclient20f640ec_12f46eb0 from 'nuxt_plugin_libnuxtclientinitpluginclient20f640ec_12f46eb0' // Source: ./lib.nuxt-client-init.plugin.client.20f640ec.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"setsushi","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Интернет магазин доставки еды в Санкт-Петербурге"},{"name":"theme-color","color":"#ffffff"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","sizes":"16x16","href":"\u002Ffavicon\u002Ffavicon.ico"},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap"},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400&display=swap"}],"script":[{"hid":"gtm","innerHTML":"window.dataLayer = window.dataLayer || [];","type":"text\u002Fjavascript"},{"src":"https:\u002F\u002Fvk.com\u002Fjs\u002Fapi\u002Fopenapi.js?169","async":true}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.client && typeof nuxt_plugin_nuxtvkretargetingplugin56ba9d40_72e03111 === 'function') {
    await nuxt_plugin_nuxtvkretargetingplugin56ba9d40_72e03111(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_d33cfe8e === 'function') {
    await nuxt_plugin_moment_d33cfe8e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_0a5f756e === 'function') {
    await nuxt_plugin_toast_0a5f756e(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_a48ecea8 === 'function') {
    await nuxt_plugin_veevalidate_a48ecea8(app.context, inject)
  }

  if (typeof nuxt_plugin_templatespluginbe7d6cb8_a5df2d48 === 'function') {
    await nuxt_plugin_templatespluginbe7d6cb8_a5df2d48(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3e2f28bf === 'function') {
    await nuxt_plugin_axios_3e2f28bf(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_2e956a67 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_2e956a67(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_042d914a === 'function') {
    await nuxt_plugin_deviceplugin_042d914a(app.context, inject)
  }

  if (typeof nuxt_plugin_cookies_1f907659 === 'function') {
    await nuxt_plugin_cookies_1f907659(app.context, inject)
  }

  if (typeof nuxt_plugin_region_3f835c2c === 'function') {
    await nuxt_plugin_region_3f835c2c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_regionredirect_691b7036 === 'function') {
    await nuxt_plugin_regionredirect_691b7036(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_filialsynch_72bd0b18 === 'function') {
    await nuxt_plugin_filialsynch_72bd0b18(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_axiosserver_545c56f0 === 'function') {
    await nuxt_plugin_axiosserver_545c56f0(app.context, inject)
  }

  if (typeof nuxt_plugin_components_58c7edad === 'function') {
    await nuxt_plugin_components_58c7edad(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ymapPlugin_4bb7856d === 'function') {
    await nuxt_plugin_ymapPlugin_4bb7856d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtswiperplugin_82e70236 === 'function') {
    await nuxt_plugin_nuxtswiperplugin_82e70236(app.context, inject)
  }

  if (typeof nuxt_plugin_perfectscrollbar_380a0fc5 === 'function') {
    await nuxt_plugin_perfectscrollbar_380a0fc5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vtooltips_e8248e36 === 'function') {
    await nuxt_plugin_vtooltips_e8248e36(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuedatepicker_20ca9b40 === 'function') {
    await nuxt_plugin_vuedatepicker_20ca9b40(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueinputmask_609d3f5c === 'function') {
    await nuxt_plugin_vueinputmask_609d3f5c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sourcebuster_7ec97af2 === 'function') {
    await nuxt_plugin_sourcebuster_7ec97af2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuesticky_1d008431 === 'function') {
    await nuxt_plugin_vuesticky_1d008431(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuelazyload_d07c1d40 === 'function') {
    await nuxt_plugin_vuelazyload_d07c1d40(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueverification_6131e541 === 'function') {
    await nuxt_plugin_vueverification_6131e541(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueconfirmdialog_87192232 === 'function') {
    await nuxt_plugin_vueconfirmdialog_87192232(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_VueSuggestion_1ce5a3a1 === 'function') {
    await nuxt_plugin_VueSuggestion_1ce5a3a1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_libnuxtclientinitpluginclient20f640ec_12f46eb0 === 'function') {
    await nuxt_plugin_libnuxtclientinitpluginclient20f640ec_12f46eb0(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }

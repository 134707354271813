const state = () => ({
  isLoading: false,
})

const mutations = {
  setIsLoading: (state, newVal) => {
    state.isLoading = newVal
  },
}

const getters = {
  getIsLoading: (state) => {
    return state.isLoading
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}

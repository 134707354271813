<template>
  <div class="street-input-component">
    <vue-suggest
      ref="suggestComponent"
      :destyled="true"
      v-model="model"
      :list="getList"
      :max-suggestions="30"
      :min-length="3"
      :debounce="200"
      :filter-by-query="false"
      :controls="{
        selectionUp: [38, 33],
        selectionDown: [40, 34],
        select: [13, 36],
        showList: [40],
        hideList: [27, 35]
      }"
      :mode="mode"
      :nullable-select="true"
      @select="onSuggestSelect"
      @input="onInput"
      class="asdad"
      pattern="\w+"
      placeholder="Search information..."
      value-attribute="value"
      display-attribute="value"
    >
      <!-- <input type="text"> -->

      <div class="query">
        <input :placeholder="placeholder" type="text" class="input-text_input">
      </div>

      <!-- <test-input placeholder="Search information..." /> -->

      <template slot="misc-item-above" slot-scope="{ suggestions, query }">
        <div v-if="suggestions.length > 0 && !loading" class="misc-item items-found">
          <span>Выберите одну из улиц в списке</span>
        </div>
        <div v-else-if="!loading" class="misc-item">
          <span>Ничего не найдено по запросу "{{ query }}"</span>
        </div>
      </template>

      <div slot="suggestion-item" slot-scope="scope">
        <div class="suggestion-item__text">
          <div class="area">
            {{ getCity(scope) }}
          </div>
          <div v-html="boldenSuggestion(scope)" />
        </div>
      </div>

      <div slot="misc-item-below" slot-scope="{ suggestions }" v-if="loading" class="misc-item">
        <span>Поиск...</span>
      </div>
    </vue-suggest>
  </div>
</template>

<script>
// import VueSuggest from 'vue-simple-suggest/lib'
import VueSuggest from 'vue-simple-suggest'

export default {
  components: {
    VueSuggest,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      selected: null,
      model: null,
      mode: 'input',
      loading: false,
      log: [],
    }
  },
  methods: {
    boldenSuggestion (scope) {
      if (!scope) {
        return scope
      }
      const { suggestion, query } = scope

      const result = this.$refs.suggestComponent.displayProperty(suggestion)

      if (!query) {
        return result
      }

      if (!suggestion.street) {
        return suggestion.street
      }

      const texts = query.split(/[\s-_/\\|\.]/gm).filter(t => !!t) || ['']
      return suggestion.street.replace(new RegExp('(.*?)(' + texts.join('|') + ')(.*?)', 'gi'), '$1<b>$2</b>$3')
    },

    getCity (scope) {
      if (!scope) {
        return scope
      }
      const { suggestion, query } = scope

      return suggestion.city
    },

    getList (inputValue) {
      this.loading = true
      return this.$store.dispatch('common/getSuggestStreet', inputValue)
        .catch(err => console.error('street input - common/getSuggestStreet - error', err))
        .finally(() => {
          this.loading = false
        })
    },
    onSuggestSelect (suggest) {
      this.$emit('selected', suggest)
      this.selected = suggest
    },
    onInput () {
      this.$emit('input', this.model)
    },
  },
}
</script>

<style lang="less">
.street-input-component {

	b {
		font-weight: bold;
	}

	.vue-simple-suggest {
		&.focus .input-wrapper input {
			border: 1px solid #e1e1e1;

		}

		.suggestions {
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			//top: calc(100% + 5px);
			border: 1px solid #e1e1e1;
			background-color: #fff;
			opacity: 1;
			z-index: 1000;
			max-height: 240px;
			overflow-y: auto;
			box-shadow: 0 3px 14px rgb(128 128 128 / 16%);
			border-radius: 15px;
			font-size: 14px;

			.misc-item {
        span {
          margin-bottom: 0;
        }
				&.items-found {
					color: #fe7e00;
          //font-weight: 500;
				}
	  	}
		}

		.suggestions .suggest-item {
			cursor: pointer;
			user-select: none;
			font-size: 14px;
			padding-top: 10px;
			padding-bottom: 10px;
			border-top: 1px solid #f2f2f2;

			&:nth-child(2n) {
				//background: #939393;
			}

			&:not(:last-child) {
				//border-bottom: 1px solid #f2f2f2;
			}

			.area {
				font-weight: bold;
				font-size: 12px;
				margin-bottom: 4px;
			}
		}

		.suggestions .suggest-item,
		.suggestions .misc-item {
			padding: 10px 10px;
		}

		.suggestions .suggest-item.hover {
			background-color: #f2f2f2 !important;
			// color: #fff !important;
		}

		.suggestions .suggest-item.selected {
			//background-color: #2832D5;
			//background-color: #939393;
			//color: #fff;
		}
	}

}

</style>

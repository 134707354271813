<template>
  <div class="container header_auth_main">
    <div class="row">
      <div class="col-3">
        <HeaderMainLogo />
      </div>
      <div class="col-3">
        <div class="header_auth_main_back">
          <span @click="$router.go(-1)">Вернуться назад</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMainLogo from './HeaderMainLogo'

export default {
  components: { HeaderMainLogo },
}
</script>

<style lang="less">
.header_auth_main {
	& > .row {
		align-items: center;
		justify-content: space-between;
		padding: 50px 0;
	}

	&_back {
		width: 100%;
		display: flex;
		align-items: center;
		font-family: 'Roboto', Arial, sans-serif;
		font-weight: 300;
		font-size: 14px;
		text-align: center;

		span {
			color: #E31E24;
			border-bottom: 1px dashed #E31E24;
			cursor: pointer;
		}
	}
}
</style>

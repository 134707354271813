<template>
  <div class="error-page container text-center">
    <h1 v-if="error.statusCode === 404">
      Страница не найдена
    </h1>
    <template v-else>
      <h1>Error...</h1>
      <p>{{ error.message }}</p>
    </template>
    <a :href="homeUrl">
      на главную
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  head () {
    return {
      title: this.$store.state.seo.metaTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.seo.metaDescription,
        },
      ],
    }
  },
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  layout: ({ store }) => {
    if (store.getters['region/isRegionNotFoundError']) {
      return 'frame'
    }
    return 'default'
  },
  computed: {
    ...mapGetters({
      isRegionNotFoundError: 'region/isRegionNotFoundError',
    }),
    homeUrl () {
      return this.isRegionNotFoundError ? this.$region.getDefaultRegionBaseUrl() : '/'
    },
  },
  methods: {

  },
}
</script>
<style lang="less">
  .error-page {
    min-height: 400px;
    padding-top: 70px;
    padding-bottom: 50px;

    h1 {
      font-family: 'Roboto Slab', Arial, sans-serif;
      font-weight: 500;
      font-size: 40px;
      color: black;
      margin-bottom: 30px;
    }
  }
</style>

import sha256 from 'crypto-js/sha256'

export function getValidationToken (axiosConfig) {
  const requestData = {
    phone: axiosConfig.data?.phone,
    uuid: axiosConfig.params?.uuid,
    s_valid: 'c0d9460bce4c2954332a913b4d7ef53e8d857f1665f475d582819dc14533efa7',
  }

  const token = sha256(JSON.stringify(requestData)).toString()

  return token
}

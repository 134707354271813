<template>
  <div class="pickup-option">
    <div class="pickup-option_list">
      <SelfItem
        v-for="(point, i) in pickupAddresses"
        :key="point.id"
        :item="point"
        :isActive="getActiveAddress === i"
        :index="i"
        @changeAddressIndex="changeAddressIndex"
      />
    </div>
    <div class="pickup-option_footer">
      <Button
        :disabled="getActiveAddress < 0"
        @click="changeAddress(getActiveAddress, 'self')"
        class="button_small"
      >
        Выбрать
      </Button>
    </div>
  </div>
</template>

<script>
import SelfItem from '~/components/layout/OrderTypeSelect/SelfItem.vue'
import Button from '~/components/UI/Button.vue'

export default {
  name: 'SelfOption',
  components: { Button, SelfItem },
  props: ['pickupAddresses', 'changeAddress', 'addressIndex', 'changeAddressIndex'],
  computed: {
    getActiveAddress () {
      return this.addressIndex[1] === 'self' ? this.addressIndex[0] : -1
    },
  },
}
</script>

<style lang="less" scoped>
.pickup-option {
  margin-right: 8px;
  margin-top: 15px;
  padding-top: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;

  /* width */

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* button */

  &::-webkit-scrollbar-button {
    display: none;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #dfdfdf;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &_footer {
    height: 80px;
    background-image: linear-gradient(
      169deg,
      rgba(255, 255, 255, 0) -14.9%,
      #fff 34.58%
    );
    position: sticky;
    bottom: -1px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    button {
      width: 295px;
      height: 46px;
      border-radius: 14px ;
    }
  }
}
</style>

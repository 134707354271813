<template>
  <div class="mobile-region-label">
    <p>Ваш город</p>
    <region-label />
  </div>
</template>

<script>
import RegionLabel from '~/components/page/RegionLabel'
export default {
  name: 'MobileRegionLabel',
  components: { RegionLabel },
}
</script>

<style lang="less" scoped>
.mobile-region-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  p {
    margin-bottom: 5px;
    font-size: 15px;
  }

  ::v-deep {
    .region-label_city {
      font-size: 18px;
    }
  }
}
</style>

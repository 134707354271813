<template>
  <div class="header_top_socials">
    <template v-for="(item, i) in socials">
      <a
        :href="item.path"
        :class="[ item.icon ]"
        :key="`soc-${ i }`"
        class="header_top_socials_item"
        target="_blank"
        rel="nofollow"
      >
        <img v-if="item.icon === 'telegram'" src="/icons/telegram.png" alt="">
        <font-awesome-icon :icon="['fab', item.icon]" v-else />

      </a>
    </template>
  </div>
</template>

<script>

export default {
  computed: {
    socials () {
      return this.$region.getSocials()
    },
  },
}
</script>

<style lang="less">
.header_top_socials {
  display: flex;
  align-items: center;
  margin-right: 20px;

  @media screen and (max-width: 1200px) {
    margin-right: 10px;
  }

  &_item {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 9px;
    color: white;
    text-decoration: none;
    overflow: hidden;

    &:not(:last-child) {
      margin-right: 7px;
    }

    &.vk {
      background-color: #000;
    }

    &.telegram {
      background-color: #37aee2;
      font-size: 11px;
    }

    img {
      height: 15px;
    }
  }
}
</style>

<template>
  <div class="region-label">
    <div @click="showRegionSelect" class="region-label_city">
      {{ curRegionName }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RegionLabel',
  computed: {
    ...mapGetters({
      curRegionName: 'region/getCurRegionName',
    }),
    multiRegionMode () {
      return this.$region.isMultiRegionMode()
    },
  },
  methods: {
    ...mapActions({
      showRegionSelect: 'region/showRegionSelect',
    }),
  },
}
</script>

<style lang="less" scoped>
.region-label {
  &_city {
    font-weight: 500;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    border-bottom: 1px dashed #000;
    cursor: pointer;
  }
}
</style>

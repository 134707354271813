<template>
  <div v-if="showDialog" class="region-confirm-dialog">
    <div class="region-confirm-dialog__inner">
      <div class="region-confirm-dialog__text">
        Ваш город <b>{{ curRegionName }}?</b>
      </div>
      <div class="region-confirm-dialog__actions">
        <div
          @click="confirmRegion"
          class="region-confirm-dialog__btn region-confirm-dialog__confirm"
        >
          Да
        </div>
        <div
          @click="openRegionSelect"
          class="region-confirm-dialog__btn region-confirm-dialog__choose"
        >
          Нет
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ConfirmRegionPopup',
  data () {
    return {
      showDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      curRegionName: 'region/getCurRegionName',
      curRegion: 'region/getCurRegion',
    }),
    multiRegionMode () {
      return this.$region.isMultiRegionMode()
    },

    notSavedRegion () {
      // console.log(this.$cookies.get('region_id'), this.$region.getCurrentRegionId())
      return !this.$cookies.get('region_id') || this.$cookies.get('region_id') !== this.$region.getCurrentRegionId()
    },
  },
  created () {
    if (this.curRegion && this.multiRegionMode && this.notSavedRegion) {
      this.showDialog = true
    }
  },
  methods: {
    confirmRegion () {
      this.showDialog = false
      this.$region.changeCurrentRegion(this.curRegion)
    },

    openRegionSelect () {
      this.showDialog = false
      this.$store.dispatch('region/showRegionSelect')
    },
  },
}
</script>

<style lang="less" scoped>
.d-relative {
  position: relative;
}

.region-confirm-dialog {
  position: absolute;
  top: calc(100% + 10px);
  right: 50%;
  transform: translate(50%, 0);
  background: #fff;
  border-radius: 20px;

  // border: 1px solid rgba(27, 40, 40, 0.12);
  box-shadow: 0px 11px 20px rgba(0, 0, 0, 0.25);
  text-align: center;
  z-index: 1000;

  &__inner {
    padding: 17px 20px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -6px;
      left: calc(50% - 5px);
      //border-left: 1px solid rgba(27, 40, 40, 0.12);
      //border-top: 1px solid rgba(27, 40, 40, 0.12);
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background: #fff;
    }
  }

  &__text {
    margin-bottom: 15px;
    white-space: nowrap;

    b {
      font-weight: bold;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -5px;
    margin-left: -5px;
  }

  &__btn {
    cursor:pointer;
    flex-grow: 1;
    flex-basis: 50%;
    outline: none;
    border: 1px solid #E31E24;
    border-radius: 10px;
    background: #fff;
    transition: all 0.2s ease;
    margin: 0 5px;
    text-transform: uppercase;
    padding: 10px 11px 10px 11px;
    font-size: 13px;
    font-weight: 500;

    &:hover {
      background-color: #c41b20;
      color: #fff;
    }
  }

  &__confirm {
    color: #fff;
    background: #E31E24;

    &:hover {
      background-color: #c41b20;
    }
  }

  @media (max-width: 1200px) {
    //left: 145px;
  }

  @media (max-width: 768px) {
    left: 12px;
    right: 12px;
    width: auto;
    top: calc(100% - 18px);
    transform: none;
    //box-shadow: 0px 21px 47px rgba(0, 0, 0, 0.53);

    &__inner {
      padding: 25px 20px;

      &:before {
        left: 7px;

      }
    }

    &__text {
      margin-bottom: 20px;
    }

    &__actions {
      width: 80%;
      margin: 0 auto;
    }

    &__btn {
      padding-top: 10px;
      padding-bottom: 7px;
    }
  }
}

</style>

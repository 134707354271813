<template>
  <div class="header_auth">
    <HeaderAuthMain />
    <HeaderMobile />
  </div>
</template>

<script>
import HeaderAuthMain from './HeaderAuthMain'
import HeaderMobile from './HeaderMobile'

export default {
  components: { HeaderAuthMain, HeaderMobile },
}
</script>

<style lang="less">
.header_auth {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;

	&_main {
		@media screen and (max-width: 768px) { display: none; }
	}
}
</style>

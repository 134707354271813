<template>
  <div class="stepper">
    <div v-for="(val, i) in items" :key="`stepper-${ i }`" class="stepper_item">
      <div :class="{ 'active': val.active }" class="stepper_ball">
        {{ i + 1 }}
      </div>
      <div class="stepper_text">
        {{ val.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default () {
        return []
      },
    },
  },
}
</script>

<style lang="less">
.stepper {
	display: flex;
	align-items: center;

	@media screen and (max-width: 540px) { align-items: flex-start; }

	&_item {
		display: flex;
		align-items: center;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			align-items: center;
		}

		&:not(:last-child) {
			--dashWidth: 88px;

			padding-right: 14px;
			margin-right: var(--dashWidth);
			border-right: 14px solid transparent;
			position: relative;

			@media screen and (max-width: 992px) {
				--dashWidth: 30px;
				padding-right: 10px;
			}

			@media screen and (max-width: 768px) {
				padding-right: 20px;
				margin-right: 0;
				border-right: 0;
			}

			&:after {
				content: '';
				width: var(--dashWidth);
				height: 100%;
				background-image: url('/dash.svg');
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				position: absolute;
				top: 0;
				right: calc(0px - var(--dashWidth));

				@media screen and (max-width: 992px) { background-size: unset; }
				@media screen and (max-width: 768px) {
					height: 35px;
					width: 40px;
					background-size: 65px 4px;
					right: -10px;
				}
			}
		}
	}

	&_ball {
		width: 38px;
		height: 38px;
		display: flex;
		align-items: center;
		flex-shrink: 0;
		justify-content: center;
		background-color: #E3E3E3;
		font-family: 'Roboto Slab', Arial, sans-serif;
		font-weight: bold;
		font-size: 18px;
		color: white;
		margin-right: 17px;
		border-radius: 50%;

		@media screen and (max-width: 992px) {
			width: 35px;
			height: 35px;
			font-size: 16px;
			margin-right: 10px;
		}

		@media screen and (max-width: 768px) {
			margin-right: 0;
			margin-bottom: 11px;
		}

		&.active { background-color: #E31E24; }
	}

	&_text {
		font-family: 'Roboto', Arial, sans-serif;
		font-weight: 300;
		font-size: 14px;

		@media screen and (max-width: 768px) { text-align: center; }
	}
}
</style>

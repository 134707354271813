<template>
  <footer class="footer">
    <div class="footer_auth">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <FooterLogo />
          </div>
          <div class="col-6">
            <div class="footer_auth_contacts">
              <FooterSocials />
              <div class="footer_auth_contacts_phone d-none d-md-flex">
                <region-delivery-label />
                <a :href="'tel:' + $region.getPhone()" class="phone">{{ $region.getPhone() }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterBottom />
  </footer>
</template>

<script>
import FooterLogo from './FooterLogo'
import FooterSocials from './FooterSocials'
import FooterBottom from './FooterBottom'
import RegionDeliveryLabel from '~/components/page/RegionDeliveryLabel'

export default {
  components: { RegionDeliveryLabel, FooterLogo, FooterSocials, FooterBottom },
}
</script>

<style lang="less">
.footer {
  width: 100%;
  background-color: #191919;

  &_auth {
    padding-top: 40px;
    padding-bottom: 27px;

    @media screen and (max-width: 768px) {
      padding-top: 22px;
      padding-bottom: 24px;
    }

    &_contacts {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .region-delivery-label {
        color: white;
      }

      &_phone {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-family: 'Roboto Slab', Arial, sans-serif;
        font-weight: 500;
        font-size: 14px;
        margin-left: 50px;

        a.phone {
          color: white;
          text-decoration: none;
          cursor: pointer;
        }

       /* strong {
          color: white;
          margin-bottom: 10px;

          span {
            color: #E31E24;
          }
        }*/
      }
    }
  }
}
</style>

<template>
  <div class="header_collapse_navigation">
    <div class="header_collapse_navigation_wrap">
      <strong>Покупателям</strong>
      <div class="header_collapse_navigation_list">
        <a
          v-for="(val, i) in forClients"
          :key="`fcl-${ i }`"
          :class="{ 'soon': val.coming }"
          @click.prevent="
						$nuxt.$emit('close-mobile-menu')
						$router.push(val.path)
					"
        >{{ val.name }}</a>
      </div>
    </div>
    <div class="header_collapse_navigation_wrap">
      <strong>Информация</strong>
      <div class="header_collapse_navigation_list">
        <template
          v-for="(val, i) in info"
        >
          <a
            v-if="!val.external"
            :key="`infocl-${ i }`"
            :class="{ 'soon': val.coming }"
            @click.prevent="
						$nuxt.$emit('close-mobile-menu')
						$router.push(val.path)
					"
          >{{ val.name }}</a>
          <a
            v-else
            :key="`infocl-${ i }`"
            :class="{ 'soon': val.coming }"
            @click="$nuxt.$emit('close-mobile-menu')"
            :href="val.path"
            target="_blank"
            rel="nofollow"
          >{{ val.name }}</a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      forClients: [
        { name: 'Полное меню', path: '/menu' },
        { name: 'Акции', path: '/promos' },
        { name: 'Доставка и оплата', path: '/delivery' },
        { name: 'Контакты', path: '/contacts' },
        // { name: 'О Сушифокс', path: '/about' }
      ],
      info: [
        { name: 'Новости', path: '/news' },
        { name: 'Вакансии', path: '/vacancies' },
        { name: 'Поставщикам', path: '/postavshikam' },
        { name: 'Франшиза', path: 'https://fr.setsushi.ru/', external: true },
      ],
    }
  },
}
</script>

<style lang="less">
.header_collapse_navigation {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 26px;

	&_wrap {
		display: flex;
		flex-direction: column;
		align-items: center;

		&:not(:last-child) { margin-bottom: 34px; }

		strong {
			font-family: 'Roboto Slab', Arial, sans-serif;
			font-weight: 500;
			font-size: 18px;
			margin-bottom: 28px;
		}
	}

	&_list {
		display: flex;
		flex-direction: column;
		align-items: center;

		a {
			font-family: 'Roboto', Arial, sans-serif;
			font-size: 16px;
			color: black;
			text-decoration: none;

			&:not(:last-child) { margin-bottom: 16px; }
		}
	}
}
</style>

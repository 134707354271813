<template>
  <div
    :class="{ 'header_wrap--float': isFloatHeader, 'header_wrap__padding': isFloatActive }"
    class="header_wrap"
  >
    <div ref="header" :class="{ 'header--menu': isFloatActive }" class="header">
      <HeaderTop
        :hidden="isFloatActive"
      />
      <HeaderMain />
      <HeaderCategories
        :float="isFloatHeader"
        :isFloatActive="isFloatActive"
        :activeId="activeId"
      />
      <HeaderMobile />
    </div>
  </div>
</template>

<script>
import HeaderTop from './HeaderTop'
import HeaderMain from './HeaderMain'
import HeaderCategories from './HeaderCategories'
import HeaderFloat from './HeaderFloat'
import HeaderMobile from './HeaderMobile'

export default {
  components: { HeaderTop, HeaderMain, HeaderCategories, HeaderMobile, HeaderFloat },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      screenHeight: 0,
      activeId: '',
      isFloatHeader: true,
    }
  },
  computed: {
    isFloatActive () {
      return this.isFloatHeader && (this.screenHeight > 40) || this.$store.getters.getLockHeaderFloating
    },
  },
  mounted () {
    window.addEventListener('scroll', this.observeScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.observeScroll)
  },
  methods: {
    observeScroll () {
      const posTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop
      this.screenHeight = posTop
    },
  },
}
</script>

<style lang="less">
.header {
	@media screen and (max-width: 768px) {
		display: flex;
	}

	&_wrap {

		&--float {
			padding-bottom: 255px;

			@media screen and (max-width: 1200px) {
				padding-bottom: 250px;
			}
			@media screen and (max-width: 992px) {
				padding-bottom: 215px;
			}
			@media screen and (max-width: 768px) {
				padding-bottom: 120px;
			}

			@media screen and (max-width: 768px) { padding-bottom: 105px; }

			.header {
				width: 100%;
				background-color: white;
				position: fixed;
				top: 0;
				left: 0;
				z-index: 20;
			}
		}
		&--padding {
			padding-bottom: 250px;
		}
	}

	&--menu {
		box-shadow: 0 3px 14px rgba(128, 128, 128, .16);
	}
}
</style>

<template>
  <div>
    <transition name="fade">
      <PageLoader v-if="showLoader" />
    </transition>
    <HeaderAuth />
    <nuxt class="auth" />
    <FooterAuth />
    <ModalRestrictOrders
      :open="showTemporaryModal"
      @close="showTemporaryModal = false"
    />
    <!--    <new-year-modal/>-->
  </div>
</template>

<script>
import PageLoader from '~/components/UI/PageLoader'
import HeaderAuth from '~/components/layout/Header/HeaderAuth'
import FooterAuth from '~/components/layout/Footer/FooterAuth'
import ModalRestrictOrders from '~/components/page/Modal/ModalRestrictOrders'
import NewYearModal from '~/components/page/Modal/InfoModals/NewYearModal'

export default {
  components: { NewYearModal, PageLoader, HeaderAuth, FooterAuth, ModalRestrictOrders },
  data () {
	  return {
	    showLoader: true,
      showTemporaryModal: true,
	  }
  },
  mounted () {
    setTimeout(() => {
      this.showLoader = false
    }, 500)
  },
}
</script>

<style lang="less">
.auth {
	padding: 100px 0;
}
</style>

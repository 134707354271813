<template>
  <div class="header_clearance">
    <HeaderMobile />
    <HeaderClearanceMain />
  </div>
</template>

<script>
import HeaderClearanceMain from './HeaderClearanceMain'
import HeaderMobile from './HeaderMobile'

export default {
  components: { HeaderClearanceMain, HeaderMobile },
}
</script>

<style lang="less">
.header_clearance {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;

	.header {
		&_mobile {
			@media screen and (max-width: 768px) { height: auto; }

			&_inner { padding-bottom: 0; }
		}
	}
}
</style>

<template>
  <div class="modal-working-hours-component">
    <client-only>
      <Modal :open="showNoWorkingModal || showNoWorkingModalGet" @close="close" initial-width="1139px">
        <template v-if="showMorningModal || showMorningModalDebug">
          <div class="modal-content">
            <div class="description">
              <span class="title">Доброе утро!</span>
              <span class="sub-title">Наши повара скоро проснутся!</span>
              <span class="working-hours">Вы можете оставить заказ на сайте и мы начнём готовить его в первую очередь.</span>
              <Button @click="close" class="button-close">
                Смотреть меню
              </Button>
            </div>
            <div class="image">
              <img src="/work-time-popup/nori-sleep-2.jpg" alt="">
            </div>
          </div>
        </template>

        <template v-else>
          <div class="modal-content">
            <div class="description">
              <span class="title">Доброй ночи!</span>
              <span class="sub-title">Наши повара набираются сил для завтрашнего дня...</span>
              <span class="working-hours">Сейчас Вы можете оформить предзаказ к точному времени</span>
              <Button @click="close" class="button-close">
                OK, понятно
              </Button>
            </div>
            <div class="image">
              <img src="/work-time-popup/nori-sleep.jpg" alt="">
            </div>
          </div>
        </template>
      </Modal>
    </client-only>
  </div>
</template>

<script>
import Button from '@/components/UI/Button'
import { mapGetters } from 'vuex'
import Modal from '~/components/page/Modal/Modal'

export default {
  name: 'ModalWorkingHours',
  components: {
    Modal,
    Button,
  },
  computed: {
    ...mapGetters({
      showNoWorkingModal: 'workTime/showNoWorkingModal',
      showMorningModal: 'workTime/showMorningModal',
    }),
    showNoWorkingModalGet () {
      if (process.client && this.$route.query.night) {
        return true
      }
      return false
    },
    showMorningModalDebug () {
      if (process.client && this.$route.query.night && this.$route.query.night === '2') {
        return true
      }
      return false
    },
  },
  created () {
    /* console.log({
      showNoWorkingModal: this.showNoWorkingModal,
      showMorningModal: this.showMorningModal
    }) */
  },
  methods: {
    close () {
      this.$store.commit('workTime/hideWorkingHoursModal')
    },
  },
}
</script>

<style lang="less" scoped>
.modal-working-hours-component {
  ::v-deep {
    .modal_body {
      padding: 0;
    }
  }

  .modal-content {
    display: flex;

    .description {
      padding-left: 29px;
      padding-right: 29px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      font-family: 'Roboto Slab', Arial, sans-serif;

      .title {
        font-size: 35px;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 38px;
      }

      .sub-title {
        font-size: 25px;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 64px;
      }

      .working-hours {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 98px;
        /*max-width: 400px;*/

        .hours {
          font-weight: 500;
        }
      }

      .button-close {
        max-width: 197px;
      }
    }

    .image {
      width: 50%;
      max-height: 521px;
      /*30px 35px*/

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .modal-content {
      flex-direction: column;

      .description {
        align-items: center;
        padding: 41px 16px 23px;
        width: 100%;
        order: 2;
        margin-bottom: 44px;

        .sub-title {
          text-align: center;
          margin-bottom: 25px;
          font-size: 20px;
        }

        .working-hours {
          text-align: center;
          padding: 0 16px;
          margin-bottom: 68px;
          font-size: 14px;
          max-width: 100%;
        }

        .button-close {
          max-width: 100%;
          width: 100%;
        }
      }

      .image {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .modal-content {
      .description {
        padding: 41px 16px 23px;
        margin-bottom: 16px;

        .sub-title {
          margin-bottom: 44px;
          font-size: 20px;
        }

        .working-hours {
          margin-bottom: 39px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>

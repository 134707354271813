<template>
  <div class="header_main_logo">
    <a
      @click.prevent="$nuxt.$emit('close-mobile-menu');$router.push('/')"
      class="header_main_logo_href"
      href="/"
    >
      <img src="@/assets/s-images/logo-sushiset-svg.svg" alt="">
    </a>
    <sup v-if="isDev">DEV</sup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isDev: 'config/isDev',
    }),
  },
}
</script>

<style lang="less">
.header_main_logo {
  display: flex;

  &_href {
    display: flex;
    cursor: pointer;

    img {
      width: 200px;
      height: auto;

      @media screen and (max-width: 992px) {
        width: 180px;
      }
    }
  }

  sup {
    padding-left: 3px;
    font-size: 14px;
    font-weight: bold;
    //text-transform: lowercase;
    color: #C2C2C2;
  }
}
</style>

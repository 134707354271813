import { DateTime } from 'luxon'

export function formatIsoDateTime (dateTimeString, format, emptyValue = '-', timeZone = null) {
  const params = timeZone ? { zone: timeZone } : {}

  if (dateTimeString) {
    try {
      const dateTime = DateTime.fromISO(dateTimeString, params)

      if (!dateTime.isValid) {
        return '-Invalid Datetime-'
      }

      return dateTime.toFormat(format)
    } catch (e) {
      console.error('== formatIsoDateTime ==>', e)
      return 'Error parse DateTime'
    }
  }

  return emptyValue
}

export function formatIsoDateTimeAsTime (dateTimeString, emptyValue = '--:--', timeZone = null) {
  return formatIsoDateTime(dateTimeString, 'HH:mm', emptyValue, timeZone)
}

export function formatIsoDateTimeAsShortYearDate (dateTimeString, emptyValue = '--.--.--', timeZone = null) {
  return formatIsoDateTime(dateTimeString, 'dd.LL.yy', emptyValue, timeZone)
}

export function formatIsoDateTimeAsDate (dateTimeString, emptyValue = '--.--.--', timeZone = null) {
  return formatIsoDateTime(dateTimeString, 'dd.LL.yyyy', emptyValue, timeZone)
}

// const createdAt = DateTime.fromFormat(this.order.createdAt, 'yyyy-MM-dd HH:mm:ss').toMillis()
// const deadLine = DateTime.fromFormat(this.order.deadLineString, 'yyyy-MM-dd HH:mm:ss').toMillis()
// return Duration.fromMillis(deadLine - createdAt).toFormat('mm') + ' мин'

export function isInInterval (dateTimeInstance, start, end) {
  if (start > end) {
    if (dateTimeInstance > end) {
      end = end.plus({ days: 1 })
    } else {
      start = start.minus({ days: 1 })
    }
  }
  // console.log(start.toString(), '--->', end.toString())

  return dateTimeInstance >= start && dateTimeInstance < end
}

<template>
  <div id="header-main-cart" class="header_main_cart">
    <div class="header_main_cart_contacts">
      <!--      <region-delivery-label />-->
      <a
        :href="'tel:' + $region.getPhone()"
        @click="$store.dispatch('events/phoneClick')"
      >
        {{ $region.getPhone() }}
      </a>
    </div>
    <button @click="goToCart" class="header_main_cart_button">
      <div class="header_main_cart_button_icon">
        <font-awesome-icon :icon="['fas', 'shopping-cart']" />
      </div>
      <div class="header_main_cart_button_total">
        <span>{{ getTotal }}₽</span>
        <span>x{{ getCartLength }}</span>
      </div>
    </button>
  </div>
</template>

<script>
import { getPhoneByUtm } from '~/utils/getPhoneByUtm'
import RegionDeliveryLabel from '~/components/page/RegionDeliveryLabel'
import ConfirmRegionPopup from '~/components/regions/ConfirmRegionPopup'

export default {
  components: { ConfirmRegionPopup, RegionDeliveryLabel },
  data () {
    return {
      total: 0,
      phoneNumber: '+7 (495) 150-55-22',
    }
  },
  computed: {
    getTotal () {
      return this.$store.getters['cart/getCartSummary']
        ? this.$store.getters['cart/getCartSummary']
        : 0
    },
    getCartLength () {
      return this.$store.getters['cart/getCartLength']
    },
  },
  mounted () {
    this.phoneNumber = getPhoneByUtm()
  },
  created () {
    if (process.client) {
      /**
       * Инициализация корзины
       */
      this.$store.dispatch('cart/fetchCart').catch(() => false)
    }
  },
  methods: {
    goToCart () {
      this.$router.push('/cart')
    },
  },
}
</script>

<style lang="less">
.header_main_cart {
  position: relative;
  display: flex;
  align-items: center;

  &_contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: 'Roboto Slab', 'Roboto', Arial, sans-serif;
    margin-right: 15px;

    p, span {
      margin: 0;
      font-size: 10px;
      font-weight: 600;
      color: #c2c2c2;

      strong {
        font-weight: 600;
        color: #E31E24;
      }
    }

    a {
      font-weight: bold;
      font-size: 16px;
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &_button {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: white;
    padding-left: 7px;
    padding-right: 14px;
    border: none;
    border-radius: 40px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.09);
    transition: transform 0.25s;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }

    &_icon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #E31E24;
      background: linear-gradient(180deg, #fc5964 0%, #e30615 100%);
      font-size: 15px;
      color: white;
      margin-right: 13px;
      border-radius: 50%;
    }

    &_total {
      display: flex;
      align-items: center;

      span {
        display: block;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Roboto', Arial, sans-serif;

        &:first-child {
          padding-right: 20px;
          border-right: 1px solid #dfdfdf;
          margin-right: 10px;
        }

        &:last-child {
          font-weight: 700;
        }
      }
    }
  }
}
</style>

import axios from 'axios'

const state = () => ({
  isLoading: false,
  menuItems: [],
})

const getters = {
  getList: state => state.menuItems,
}

const mutations = {
  setFavorites (state, data) {
    state.menuItems = data
  },
}

const actions = {
  fetchList ({ commit, rootGetters }) {
    if (!rootGetters['auth/loggedIn']) {
      return
    }
    axios.get(rootGetters['api/getFavoritesList'])
      .then((res) => {
        commit('setFavorites', res.data.items)
      })
      .catch(err => console.error('store action - api/getFavoritesList - error', err))
  },
  addItem ({ commit, rootGetters, dispatch }, id) {
    return axios.post(rootGetters['api/addFavoritesItem'](id))
      .then(() => {
        dispatch('fetchList')
      })
      .catch(err => console.error('store action - api/addFavoritesItem - error', err))
  },
  deleteItem ({ commit, rootGetters, dispatch }, id) {
    return this.$xios.post(rootGetters['api/deleteFavoritesItem'](id))
      .then(() => {
        dispatch('fetchList')
      })
      .catch(err => console.error('store action - api/deleteFavoritesItem - error', err))
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

import _ from 'lodash'

export default {
  methods: {
    checkFilialId () {
      const filialId = this.$cookies.get('filial_id')
      const lastFilialId = this.$store.getters['cart/getLastFilialId']
      if (filialId !== lastFilialId) {
        window.location.reload()
      }
    },
    changeFilialId () {
      const lastFilialId = this.$cookies.get('filial_id')
      const cart = this.$store.getters['cart/getCart']
      const filialId = cart?.filial?.id
      if (filialId) {
        this.$cookies.set('filial_id', filialId)
      }
      if (lastFilialId) {
        this.$store.commit('cart/setLastFilialId', lastFilialId)
      }
    },
    sendProduct (product) {
      this.$store
        .dispatch('cart/addToCart', product)
        .then(() => {
          this.$toast.success('Добавлен в корзину', {
            theme: 'bubble',
            singleton: true,
            duration: 1500,
          })
          this.checkFilialId()
        })
        .catch((err) => {
          const exception = err?.response?.data?.exception
          console.log({ exception })
          if (exception && _.endsWith(exception, 'MenuItemNotAvailableInFilial')) {
            console.log('--- show MenuItemNotAvailableInFilial error ---')
            // this.$store.commit('cart/setTempItem', product)
            this.$store.commit('cart/setErrorDelivery', product)
          } else {
            this.$toast.error(err?.response?.data?.message || err?.message, { duration: 2000 })
          }
        })
    },
    addToCart (item, modification_status) {
      item.modificationId = modification_status
        ? item.modifications[0].id
        : null

      const productInfo = setProductInfo(item, modification_status)

      const isFirstGoods = this.$store.getters['cart/getIsFirstGoods']
      isFirstGoods
        ? this.$store.commit('cart/setTempItem', productInfo)
        : this.sendProduct(productInfo)
    },
    animateSale (id) {
      if ($(window).width() > 768) {
        const e = $(id)
          .find('.card_photo')
          .children('img')
        const t = $('#header-main-cart')

        if (e.length) {
          const a = new Image()
          a.src = e.attr('src')
          $(a).css({
            width: e.width(),
            position: 'absolute',
            zIndex: 200,
            top: e.offset().top,
            left: e.offset().left,
          })
          $('body').append(a)
          $(a).animate(
            {
              top: t.offset().top,
              left: t.offset().left,
              width: '30px',
              opacity: 0,
            },
            600,
            function () {
              $(a).remove()
            },
          )
        }
      } else {
        return false
      }
    },
  },
}

function setProductInfo (item, modificationStatus) {
  let productInfo
  if (item.type && item.type === 'construct') {
    productInfo = {
      menuItemId: item.menuItemId,
      type: 'construct',
      subMenuItemId: item.subMenuItemId,
      modificationId: item.modificationId,
      name: item.name,
      image: item.image,
    }
  } else {
    productInfo = {
      menuItemId: item.id,
      type: 'usual',
      modificationId: modificationStatus ? item.modifications[0].id : null,
      name: item.name,
      image: item.image,
    }
  }
  return productInfo
}

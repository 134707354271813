<template>
  <div>
    <transition name="fade">
      <PageLoader v-if="showLoader" />
    </transition>
    <HeaderClearance />
    <nuxt class="clearance" />
    <FooterAuth />
    <ModalRestrictOrders
      :open="showTemporaryModal"
      @close="showTemporaryModal = false"
    />

    <!--    <new-year-modal/>-->
    <ModalWorkingHours />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PageLoader from '~/components/UI/PageLoader'
import HeaderClearance from '~/components/layout/Header/HeaderClearance'
import FooterAuth from '~/components/layout/Footer/FooterAuth'
import ModalRestrictOrders from '~/components/page/Modal/ModalRestrictOrders'
import ModalWorkingHours from '~/components/page/Modal/ModalWorkingHours'
import NewYearModal from '~/components/page/Modal/InfoModals/NewYearModal'

export default {
  components: {
    NewYearModal,
    PageLoader,
    HeaderClearance,
    FooterAuth,
    ModalRestrictOrders,
    ModalWorkingHours,
  },
  head () {
    return {
      title: this.$store.state.seo.metaTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.seo.metaDescription,
        },
      ],
    }
  },
  data () {
    return {
      showLoader: true,
      showTemporaryModal: true,
    }
  },
  mounted () {
    setTimeout(() => {
      this.showLoader = false
    }, 500)
  },
}
</script>

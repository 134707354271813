export const actions = {
  fetchSliders ({ rootGetters }) {
    return this.$axios
      .get(rootGetters['api/getSliders'])
      .then(res => res.data)
  },

  fetchPolygons ({ rootGetters }) {
    return this.$axios
      .get(rootGetters['api/getPolygons'], {
        params: {
          regionId: this.$region.getCurrentRegionId(),
        },
      })
      .then(res => res.data)
  },

  fetchPromotions ({ rootGetters }) {
    return this.$axios
      .get(rootGetters['api/getPromotions'])
      .then(res => res.data)
  },

  fetchNews ({ rootGetters }) {
    return this.$axios.get(rootGetters['api/getNews']).then(res => res.data)
  },

  fetchNewsSingle ({ rootGetters }) {
    return this.$axios
      .get(rootGetters['api/getNewsSingle'])
      .then(res => res.data)
  },

  fetchPickupPoints ({ rootGetters }) {
    return this.$axios
      .get(rootGetters['api/getPickupPoints'])
      .then(res => res.data.items)
  },

  fetchPickupPointBySlug ({ rootGetters }, slug) {
    return this.$axios
      .get(rootGetters['api/getSinglePickupPointBySlug'](slug))
      .then(res => res.data.data)
  },

  fetchDeliveryOptions ({ rootGetters }) {
    return this.$axios
      .get(rootGetters['api/getDeliveryOptions'])
      .then(res => res.data.data)
  },
}

<template>
  <div class="footer_contacts">
    <!--		<p>Доставка <strong>в Санкт-Петербурге</strong></p>-->
    <region-delivery-label />
    <a :href="'tel:' + $region.getPhone()" class="footer_contacts_phone">{{ $region.getPhone() }}</a>
  </div>
</template>

<script>
import { getPhoneByUtm } from '~/utils/getPhoneByUtm'
import RegionDeliveryLabel from '~/components/page/RegionDeliveryLabel'

export default {
  components: { RegionDeliveryLabel },
  data () {
    return {
      phoneNumber: '+7 (495) 150-55-22',
    }
  },
  mounted () {
    this.phoneNumber = getPhoneByUtm()
  },
}
</script>

<style lang="less">
.footer_contacts {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	@media screen and (max-width: 768px) { align-items: center; }

	p {
		font-family: 'Roboto Slab', Arial, sans-serif;
		font-weight: 500;
		font-size: 14px;
		color: white;

		@media screen and (max-width: 1200px) { font-size: 13px; }
		@media screen and (max-width: 992px) { font-size: 10px; }
		@media screen and (max-width: 768px) { font-size: 14px; }

		strong { color: #e30615; }
	}

	&_phone {
		font-family: 'Roboto Slab', Arial, sans-serif;
		font-weight: bold;
		font-size: 28px;
		color: white;
		text-decoration: none;
		cursor: pointer;

		@media screen and (max-width: 1200px) { font-size: 26px; }
		@media screen and (max-width: 992px) { font-size: 19px; }
		@media screen and (max-width: 768px) { font-size: 28px; }
	}
}
</style>
